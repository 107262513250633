import React, { useState, useEffect } from "react";
import { t } from "../common/atoms/Constants";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import ContractCalendar from "../common/molecules/ContractCalendar";
import OverFlowWrapper from "../common/OverFlowWrapper";
import InvoicesEnquiry from "../Invoies/InvoicesEnquiry";
import InvoiceSettings from "../Invoies/InvoiceSettings";
import ContractTable from "./ContractTable";
import DateAndTimeContract from "../common/atoms/DataWithTimeContract";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";
import { GET_EMPLOYEE_CONTRACTS, GET_EMPLOYER_CONTRACTS } from "../../routes/ApiEndpoints";
import PdfViewer from "../common/molecules/PdfViewer";
import CommonServices from "../../services/common/CommonServices";
import { translate } from "../CentralDataMangement/translation/Translation";

// Helper function to format Date to 'yyyy-mm-dd'
const formatDate = (date: Date | null): string => {
  if (!date) return "";

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

const EmployeeContractsTemplate = () => {
  const [state, setState] = useState({
    selectedDate: new Date() as Date | [Date, Date],
    contracts: [] as any[],
    showPdf: null as string | null,
    contractNumber: null as number | null,
  });

  const auth = useSelector(selectAuth);
  const companyId = auth?.currentCompany?.id;
  const userId = auth?.userId;
  const isEmployee = auth?.role?.includes("employee");

  // Derive startDate and endDate from selectedDate
  const startDate = Array.isArray(state?.selectedDate) ? state?.selectedDate?.[0] : state?.selectedDate;
  const endDate = Array.isArray(state?.selectedDate) && state?.selectedDate?.[1] ? state?.selectedDate?.[1] : startDate;

  // Handle changes in selected date(s) - either a single date or a date range
  const handleSelectedDateChange = (dates: Date | [Date, Date]) => {
    setState((prevState) => ({ ...prevState, selectedDate: dates }));
  };

  // Function to call the API with formatted startDate and endDate
  const fetchContracts = async () => {
    setState((prevState) => ({ ...prevState, contracts: [] }));
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    const postdata = {
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      ...(isEmployee
        ? { user_id: userId }
        : { company_id: companyId }
      ),
    };


    try {
      const response = await ApiCall.service(
        isEmployee ? GET_EMPLOYEE_CONTRACTS : GET_EMPLOYER_CONTRACTS,
        "POST",
        postdata,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      setState((prevState) => ({ ...prevState, contracts: response?.data ?? [] }));
    } catch (error) {
      console.error("Error calling API: ", error);
    }
  };
  const viewContract = async (contractId: number) => {
    setState((prevState) => ({ ...prevState, contractNumber: contractId }));
    const contract = state?.contracts?.find((contract) => contract?.contract_id === contractId);
    if (contract) {
      const fileUrl = isEmployee
        ? contract?.employee_contract_file_url
        : contract?.employer_contract_file_url;
      if (fileUrl) {
        setState((prevState) => ({ ...prevState, showPdf: fileUrl }));
      } else {
        console.error("No contract file URL found.");
      }
    } else {
      console.error("Contract not found with the given ID.");
    }
  };

  const downloadContract = async (contractId: number) => {
    try {
      const contract = state?.contracts?.find((contract) => contract?.contract_id === contractId);

      if (contract) {
        const fileUrl = isEmployee
          ? contract?.employee_contract_file_url
          : contract?.employer_contract_file_url;

        if (fileUrl) {
          await CommonServices.downloadFile(fileUrl, contract?.contract_id, "Contract");
        } else {
          console.error("Contract file URL not found.");
        }
      } else {
        console.error("Contract not found with the given ID.");
      }
    } catch (error) {
      console.error("Error downloading the contract:", error);
    }
  };

  // Call the API whenever startDate or endDate changes
  useEffect(() => {
    if (startDate) {
      fetchContracts();
    }
  }, [startDate, endDate]);

  return (
    <>
      <Header headerName={t("Contracts")} headerClassName="myAccountCardTitle" />
      <div className="row equal-cols">
        <div className="col-lg-12 mb-4 mb-lg-0">
          {state.showPdf ? (
            <div className="contentBg">
              <OverFlowWrapper className="invoiceLeftWrapper">
                <PdfViewer
                  fileUrl={state.showPdf}
                  setShowPdf={(showPdf: string | null) => setState((prevState) => ({ ...prevState, showPdf }))}
                  fileNumber={state.contractNumber}
                  fileType="Contract"
                />
              </OverFlowWrapper>
            </div>
          ) : (
            <div className="contentBg">
              <div className="row">
                <div className="col-12 mb-3">
                  <LabelField title={t("Consult your employees' contracts") + ":"} />
                </div>
                <div className="col-lg-3 col-md-8 mb-3 mb-lg-0">
                  <ContractCalendar
                    selectedDate={state.selectedDate}
                    setSelectedDate={handleSelectedDateChange}
                    isReactCalendar={true}
                    iscalendarSeperator={true}
                  />
                </div>
                <div className="col-lg-9 ps-lg-5">
                  <DateAndTimeContract
                    isDate={true}
                    isDay={Array.isArray(state.selectedDate) ? false : true}
                    dayClassName="me-2 currentDay"
                    isIcon={true}
                    dataTimeWrapperClassName="d-flex align-items-center justify-content-between dayDateWithIcon text-white"
                    iconFillColor="#fff"
                    weekFormat="long"
                    selectedDate={state.selectedDate}
                    setSelectedDate={(dates: Date | [Date, Date]) => setState((prevState) => ({ ...prevState, selectedDate: dates }))}
                    dataTimeClassName="mobileFont"
                  />
                  <OverFlowWrapper className="ContractsLeftWrapper mt-3">
                    <table className="table contractTable table-borderless mb-0 pwa">
                      <tbody>
                        {state.contracts?.map((contract) => (
                          <ContractTable
                            key={contract?.contract_id}
                            contract_id={contract?.contract_id}
                            user_first_name={contract?.user_first_name}
                            user_last_name={contract?.user_last_name}
                            user_profile_picture_url={contract?.user_profile_picture_url}
                            from_date={contract?.from_date}
                            to_date={contract?.to_date}
                            viewContract={viewContract}
                            downloadContract={downloadContract}
                          />
                        ))}
                      </tbody>
                    </table>
                  </OverFlowWrapper>
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Settings Section */}
        {/* <div className="col-lg-4">
          <div className="contentBg">
            <LabelField
              title={t("Settings")}
              className="inVoiceHeader colorPrimary poppins-semibold mb-3"
            />
            <InvoiceSettings invoiceTitle={t("Manage contract settings")} />
            <InvoicesEnquiry
              documentsTitle={t("Do you have questions about your invoice") + "?"}
              handleClick={() => { }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default translate(EmployeeContractsTemplate);

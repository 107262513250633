import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import OverFlowWrapper from "../common/OverFlowWrapper";
import SearchEmail from "../Employee/invite/organisms/SearchEmail";
import InviteForm from "../Employee/invite/organisms/InviteForm";
import { EmployeeDetails, EmployeeInviteState, InviteForm as InviteFormType } from "../Employee/invite/Interfaces";
import { initialAdminState } from "../Employee/invite/State";
import { useNavigate } from "react-router-dom";
import { selectAuth } from "../../features/auth/AuthSlice";
import { useSelector } from "react-redux";
import CompanyList from "../Employee/registration/organisms/CompanyList";
import Button from "../common/atoms/ButtonIcon";
import { EMPLOYEE_ADD_ADMIN } from "../../routes/ApiEndpoints";
import CustomNotify from "../common/atoms/CustomNotify";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { FormErrors } from "../Dashboard/services/CompanyInterface";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { validateEmail, validateNames } from "../../services/ValidationService";

export interface AddAdministratorInterface {
  employeeInviteState: EmployeeInviteState;
  searched: boolean;
  checkedItems: Set<number>;
  newCheckedItems: Set<number>;
  companySelectedError: string;

}

export interface locations {
  id: number;
  name: string;
  location_image_id: number;
  address_id: number;
}

interface projects {
  id: number;
  company_id: number;
  location_id: number;
  name: string;
  address_id: number;
  is_active: boolean;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  is_favourite: boolean;
}

export interface Company {
  id: number;
  company_user_id: number;
  locations: Array<locations>;
  name: string;
  official_name: string;
  profile_picture_url: string;
  projects: Array<projects>;
}


const AddAdministrator: React.FC = () => {
  const user = useSelector(selectAuth);
  const [state, setState] = useState<AddAdministratorInterface>(initialAdminState);

  const navigate = useNavigate();

  // Set the pre-checked items based on user data
  useEffect(() => {
    if (state?.employeeInviteState?.fetched_companies && state?.employeeInviteState?.formData?.user_id !== 0) {
      const initialCheckedItems = new Set(state?.employeeInviteState?.fetched_companies);
      setState(prevState => ({
        ...prevState,
        checkedItems: initialCheckedItems,
      }))
    }
  }, [state?.employeeInviteState?.fetched_companies, user?.employer_companies, state?.employeeInviteState?.formData?.user_id]);


  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.target;
    setState(prevState => ({
      ...prevState,
      employeeInviteState: {
        ...prevState?.employeeInviteState,
        formData: {
          ...prevState?.employeeInviteState?.formData,
          [name]: value,
        }
      },
      errors: {
        ...prevState?.employeeInviteState?.errors,
        [name]: "",
      },
    }));
  };


  const handleDateChange = (dateValue: Date | null, field: string) => {
    // Format the date to "YYYY-MM-DD"
    const formattedDate = dateValue
      ? new Date(
        new Date(dateValue)?.getTime() -
        new Date(dateValue)?.getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      : null;

    setState((prevState) => ({
      ...prevState,
      employeeInviteState: {
        ...prevState?.employeeInviteState,
        formData: {
          ...prevState?.employeeInviteState?.formData,
          [field]: formattedDate
        }
      },
    }));
  };

  const updateErrors = (errors: FormErrors) => {
    setState(prevState => ({
      ...prevState,
      employeeInviteState: {
        ...prevState?.employeeInviteState,
        errors: {
          ...prevState?.employeeInviteState?.errors,
          verify_email: errors['verify_email']
        }
      },
    }));
  };

  const updateValues = (data: EmployeeDetails, userNotFound: boolean) => {
    const newIsDisabledState: Record<string, boolean> | undefined = {};

    Object?.keys(data)?.forEach((key) => {
      const typedKey = key as keyof EmployeeDetails;
      newIsDisabledState[typedKey] = data[typedKey] && data[typedKey] !== "" ? true : false;
    });

    setState((prevState) => ({
      ...prevState,
      employeeInviteState: {
        ...prevState?.employeeInviteState,
        formData: {
          ...prevState?.employeeInviteState?.formData,
          user_id: data?.user_id ?? 0, // Ensure user_id defaults to 0
          first_name: data?.first_name ?? "",
          last_name: data?.last_name ?? "",
          email: data?.email ?? "",
          verify_email: data?.email ?? "",
          phone_number: data?.phone_number ?? "",
          companies: data?.companies ?? [],
          default_emp_legal: data?.default_emp_legal ?? "",
          date_of_birth: data?.date_of_birth ?? "",
          registry_number: data?.registry_number ?? "",
          function: data?.function ?? "",
        },
        errors: {},
        not_found: userNotFound,
        fetched_companies: data?.employer_companies ?? [],
        isDisableState: newIsDisabledState,
      },
      searched: true, // Ensure form and company list are shown
      checkedItems: data?.companies ? new Set(data?.companies) : new Set(),
    }));
  };

  const toggleCompanySelection = (company: Company, isChecked: boolean) => {
    setState(prevState => {
      const updatedCheckedItems = new Set(prevState?.checkedItems);
      const newClickedCheckedItems = new Set(prevState?.newCheckedItems);
      if (isChecked) {
        updatedCheckedItems?.add(company?.id);
        newClickedCheckedItems?.add(company?.id);
      } else {
        updatedCheckedItems?.delete(company?.id);
        newClickedCheckedItems?.delete(company?.id);
      }
      return {
        ...prevState,
        checkedItems: updatedCheckedItems,
        newCheckedItems: newClickedCheckedItems
      };
    });
  };
  const renderInviteForm = (isUserFound: boolean) => {
    return (
      <InviteForm
        formValues={state?.employeeInviteState?.formData}
        formErrors={state?.employeeInviteState?.errors}
        handleChange={handleChange}
        handleDateChange={handleDateChange}
        isFunction={true}
        isDisabled={state?.employeeInviteState?.isDisableState}
      />
    );
  };
  const renderCompanyList = () => {
    return (
      <div className="mt-5">
        <CompanyList
          companies={user?.employer_companies ?? []}
          checkedItems={state?.checkedItems}
          handleCheckBoxClick={toggleCompanySelection}
          fetchedCompanies={state?.employeeInviteState?.fetched_companies ?? []}
          missingCompanies={state?.employeeInviteState?.missing_companies ?? []}
        />
        {state?.companySelectedError && <div className='text-danger'>{state?.companySelectedError}</div>}
      </div>
    );
  };
  const companyIdsArray = Array?.isArray(state?.checkedItems) ? Array?.from(state?.checkedItems)?.map((item) => item?.id) : Array?.from(state?.checkedItems);
  const newCompanyIdsArray = Array?.isArray(state?.newCheckedItems) ? Array?.from(state?.newCheckedItems)?.map((item) => item?.id) : Array?.from(state?.newCheckedItems);

  const validatePhNumber = (phone_number: string): string | null => {
    const phoneRegex = /^[+]*[0-9]{1,4}[ -]?[0-9]{1,4}[ -]?[0-9]{1,4}[ -]?[0-9]{1,4}$/;
    if (phone_number === "" || typeof phone_number !== "string") {
      return t("This field is required");
    }
    if (!phoneRegex.test(phone_number)) {
      return "This field is invalid.";
    }
    return null;
  };
  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    if (state?.employeeInviteState?.formData?.user_id === 0) {
      const { first_name, last_name, phone_number, email } = state?.employeeInviteState?.formData;
      const errors: { [key: string]: string | null } = {};
      errors.first_name = validateNames(first_name);
      errors.last_name = validateNames(last_name);
      errors.phone_number = validatePhNumber(phone_number);
      errors.email = validateEmail(email);

      // If there are errors, update the state and stop submission
      if (errors.first_name || errors.last_name || errors.phone_number || errors.email) {
        setState(prevState => ({
          ...prevState,
          employeeInviteState: {
            ...prevState?.employeeInviteState,
            errors: errors,
          },
        }));
        return;
      }
    }

    if (companyIdsArray?.length > 0) {

      const url = `${EMPLOYEE_ADD_ADMIN}`;
      // const companyIds = companyIdsArray;
      const dataObj = state?.employeeInviteState?.formData?.user_id !== 0
        ? {
          user_id: state?.employeeInviteState?.formData?.user_id,
          companies: companyIdsArray,
          function: state?.employeeInviteState?.formData?.function,
          date_of_birth: state?.employeeInviteState?.formData?.date_of_birth,
          new_companies: newCompanyIdsArray,
        }
        : {
          first_name: state?.employeeInviteState?.formData?.first_name,
          last_name: state?.employeeInviteState?.formData?.last_name,
          phone_number: state?.employeeInviteState?.formData?.phone_number,
          function: state?.employeeInviteState?.formData?.function,
          date_of_birth: state?.employeeInviteState?.formData?.date_of_birth,
          email: state?.employeeInviteState?.formData?.email,
          user_id: state?.employeeInviteState?.formData?.user_id,
          companies: companyIdsArray,
          new_companies: newCompanyIdsArray,
        };
      try {
        const res = await ApiCall.service(url, "POST", dataObj, true, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
        if (res?.status === "success") {
          CustomNotify({
            message: t("Admin added successfully"),
            type: "success",
            autoClose: 3500,
          });
          navigate("/manage-administrators");
        } else {
          throw new Error("Failed to add administrators");
        }
      } catch (error) {
        CustomNotify({
          message: t("Failed to add administrator"),
          type: "error",
          autoClose: 3000
        });
      }
    } else {
      setState(prevState => ({
        ...prevState,
        companySelectedError: t('This field is required'),
      }));
    }
  };
  return (
    <>
      <AccessControl
        requiredPermissions={[
          {
            permission: "administrators",
            create: true,
          },
        ]}
        renderNoAccess={true}
      >
        <Header headerName={t("Add administrator")} headerClassName="myAccountCardTitle" />
        <div className="search-bar pb-2">
          <div className="row">
            <div className="row justify-content-between">
            </div>
            <div className="col-8 align-self-center text-center d-block d-lg-none">
              <LabelField title={t("Add employee")} className="myAccountCardTitle mb-0" />
            </div>
          </div>
        </div>
        <div className="createpagesBgInvite mb-4 createpagesBg">
          <OverFlowWrapper className="createpagesBgInviteHeight container-fluid">
            <SearchEmail
              email={state?.employeeInviteState?.formData?.verify_email}
              handleChange={handleChange}
              updateValues={updateValues}
              verify_email_error={state?.employeeInviteState?.errors?.verify_email}
              updateErrors={updateErrors}
              notifyType={("warning")}
            />

            {/* Show invite form and company list depending on search result */}
            {state?.searched && (
              <>
                {renderInviteForm(true)}
                {renderCompanyList()}
              </>
            )}

            <div className="text-end py-3">
              {state?.searched && <Button title="Submit" className="modalSaveBtn" handleClick={handleSubmit} />}
            </div>
          </OverFlowWrapper>

        </div>
      </AccessControl>
    </>
  );
};

export default translate(AddAdministrator);

import React, { useState, useEffect } from "react";
import { ApiCall } from "../../../services/ApiServices";
import { MANAGE_SALARY_BENEFITS } from "../../../routes/ApiEndpoints";
import Header from "../../common/layout/Header";
import Pagination from "../../common/atoms/Pagination";
import { useNavigate } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import { t, translate } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Table from "../../common/atoms/Table";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import "./salaryBenefits.css";
import LabelField from "../../common/atoms/LabelField";
interface SalaryBenefit {
  paritaircommittee_id: string;
  bbright_id: number;
  name: string;
  code: string;
  sb_main_id: number;
  variable_type: number;
  salary_benifit_value: string;
  start_date: string;
  end_date: string;
  is_mandatory: boolean;
  is_sales_can_change: boolean;
  status: boolean;
  pc_salary_benifit_id: string;
  auto_code_type: string;
  is_skip_absence: boolean;
  amount: number;
  occurence: number;
}

const ManageSalaryBenefits: React.FC = () => {
  const [data, setData] = useState<SalaryBenefit[]>([]);
  const [filteredData, setFilteredData] = useState<SalaryBenefit[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(0);
  const PER_PAGE = 10;
  const navigate = useNavigate();

  // Fetch Salary Benefits data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall?.getService(
          MANAGE_SALARY_BENEFITS,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
          true
        );
        if (response.success) {
          setData(response?.data);
          setFilteredData(response?.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Search functionality
  const handleSearch = () => {
    if (searchTerm?.trim() !== "") {
      const filtered = data?.filter((benefit) =>
        benefit?.name?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase()) ||
        benefit?.code?.toLowerCase()?.includes(searchTerm?.trim()?.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset pagination
    }
  };

  const handleReset = () => {
    setSearchTerm("");
    setFilteredData(data);
    setCurrentPage(0);
  };

  // Pagination logic
  const pageCount = Math?.ceil(filteredData?.length / PER_PAGE);
  const handlePageClick = (pageNumber: number) => {
    setCurrentPage(pageNumber - 1);
  };

  const paginatedData = filteredData?.slice(currentPage * PER_PAGE, (currentPage + 1) * PER_PAGE);
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  const TableHeaderProps = [
    // { label: 'S.no', width: 10 },
    { label: 'Name', width: 30 },
    { label: 'Code', width: 15 },
    { label: 'Benefit Value', width: 15 },
    { label: 'Start Date', width: 15 },
    { label: 'Status', width: 12 },
  ];

  const TableActionProps = [
    {
      label: t("Edit"),
      icon: <Icon name="EditIcon" {...IconProps} />,
      handleClick: (value: any) => navigate(`/manage-salary-benefits/edit/${value?.sb_main_id}`),
      iconClassName: 'secondaryColorHoverEffect me-2',
    }
  ];

  return (
    <div className="container-fluid p-0">
      <>
        <Header headerName={t("Manage salary benefits")} headerClassName="myAccountCardTitle" />
        {/* Search bar */}
        <div className="search-bar pb-md-2">
          <div className="row mb-2">
            <BackLink backLink="/config/settings" />
            <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage salary benefits")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          </div>
          <div className="row">
            <div className="col-lg-8 col-md-7">
              <Search
                value={searchTerm}
                placeholder={t("Name/Code")}
                handleChange={(e) => setSearchTerm(e?.target?.value)}
              />
            </div>
            <div className="col-lg-4 col-md-5 mt-3 mt-md-0">
              <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
                <ResetBtn handleResetClick={() => handleReset()} />
                <SearchBtn handleSearchClick={() => handleSearch()} />
              </div>
            </div>
          </div>
        </div>

        {/* Salary Benefits Table */}
        <div className="managepagesBg">
          <OverFlowWrapper className="table-responsive manageSBTable customTableProperties">
            <Table
              headers={TableHeaderProps}
              content={paginatedData} // Updated to use paginated data
              actionlabel={t('Actions')}
              isSNo={true}
              variables={['name', 'code', 'salary_benifit_value', 'start_date', 'status']}
              action={TableActionProps}
            />
          </OverFlowWrapper>

          {/* Pagination */}
          {pageCount > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1}
                totalPages={pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default translate(ManageSalaryBenefits);

import React, { ChangeEvent, ReactNode, useRef, useEffect } from "react";
import Icon from "../../../Icon";

const style = {};

interface Props {
  id?: string;
  type?: string;
  className?: string;
  value?: string | number;
  checked?: boolean;
  isDisabled?: boolean;
  placeholder?: string;
  customStyle?: React.CSSProperties;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  children?: ReactNode;
  inputRef?: React.RefObject<HTMLInputElement> | null;
  handleFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  inputClassWrapper?: string;
  icon?: string; // This appears unused in the current code
  iconName?: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  isIcon?: boolean;
  handleIcon?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void; // Change this line
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void; // Added this line
  disableCopyPaste?: boolean; // Optional prop to disable copy-paste
  mainWrapperClass?:string;
}

const InputTextfield: React.FC<Props> = ({
  id = "",
  type = "text",
  min,
  max,
  step,
  className,
  value,
  isDisabled = false,
  placeholder = "",
  customStyle = {},
  handleChange,
  handleBlur,
  name = "",
  error,
  checked,
  readOnly,
  autoComplete,
  children,
  inputRef = null,
  handleFocus,
  inputClassWrapper,
  iconName,
  icon,
  color,
  width,
  height,
  isIcon = false,
  handleIcon,
  handleKeyDown,
  disableCopyPaste = false, // Default is false,
  mainWrapperClass
}) => {
  const defaultRef = useRef<HTMLInputElement>(null);
  const resolvedRef = inputRef || defaultRef;

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    const handleInspect = () => {
      if (isDisabled || readOnly) {
        resolvedRef.current?.blur();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleInspect);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleInspect);
    };
  }, [isDisabled, readOnly, resolvedRef]);

  return (
    <div className={`${mainWrapperClass}`}>
      <div className={`${inputClassWrapper}`}>
        <input
          style={{ ...style, ...customStyle }}
          id={id}
          type={type}
          className={`${className} form-control inputFieldColor`}
          disabled={isDisabled}
          placeholder={placeholder}
          name={name}
          value={value}
          checked={checked}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          min={min}
          max={max}
          step={step}
          readOnly={readOnly}
          onKeyDown={handleKeyDown}
          autoComplete={autoComplete}
          onCopy={disableCopyPaste ? (e) => e.preventDefault() : undefined} // Disable copy
          onPaste={disableCopyPaste ? (e) => e.preventDefault() : undefined} // Disable paste
        />
        {isIcon &&
          <span
            onClick={handleIcon}  // Directly pass the function, no need to wrap in an arrow function without invoking
            className="eyeIconPassword secondaryColorHoverEffect"
          >
            <Icon
              name={iconName}
              color={color}
              width={width}
              height={height}
              isIcon={true}
            />
          </span>
        }
        {children}
      </div>
      <div>
        {error && <div className="text-danger">{error}</div>}
      </div>
    </div>
  );
};

export default InputTextfield;

import React, { useRef } from "react";
import Icon from "../../../../Icon";
import Image from "../../../common/atoms/Image";
import LabelField from "../../../common/atoms/LabelField";
import CheckBoxField from "../../../common/atoms/CheckBoxField";
import defaultCompanyIcon from "../../../../static/images/EmptyCompany.png";
import { CompanyListProps } from "../Interfaces";
import { useResponsiveValue } from "../../../common/atoms/ResponsivWidth";

const CompanyList: React.FC<CompanyListProps> = ({
  companies, // For Companies
  checkedItems, // For Checkbox's selected items
  handleCheckBoxClick, // For handle Checkbox's selected items
  fetchedCompanies = [],
  missingCompanies = [], // For Errors - selected companies not have Function & Hourly Wage details
  currentPage = "",
}) => {
  const iconStyle = useResponsiveValue("5vw", "3vw", "1.7vw");
  const companyIconHeight = useResponsiveValue("13vw", "8vw", "3vw");
  const lineHeightStyle = useResponsiveValue("5vw", "3vw", "1.5vw");
  const contentWidth = useResponsiveValue("10%", "5%", "3%");
  const middleContentWidth = useResponsiveValue("80%", "90%", "94%");

  // Use ref to access the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  return (
    <div className="d-flex align-items-center">
      {companies.length > 7 && (
        <div
          style={{ width: contentWidth }}
          className="nextIconWrapper"
          onClick={() => handleScroll("left")}
        >
          <Icon
            name="backIcon"
            isIcon={true}
            width={iconStyle}
            height={iconStyle}
            fillColor="#8571b9"
          />
        </div>
      )}
      <div style={{ width: middleContentWidth }}>
        <div
          ref={scrollRef}
          className="mx-auto d-grid p-2 pb-3 scrollBar align-items-start"
          style={{
            overflowX: "auto",
            scrollbarWidth: "none", // For Firefox
            gap: "0.5vw",
            gridAutoFlow: "column", // Ensures horizontal scrolling
          }}
        >
          {companies?.map((company) => {
            const isDisabled = fetchedCompanies?.includes(company?.id) && currentPage !== "employee-registration";

            return (
              <div
                key={company?.id}
                className={`checkBoxContainer align-self-stretch text-break text-center ${checkedItems.has(company.id)
                  ? missingCompanies?.includes(company?.id)
                    ? "missedCompanies"
                    : "selectedCompanies"
                  : ""
                  } cursor-pointer me-2`}
                onClick={() => {
                  if (isDisabled) return; // No action if the checkbox is disabled
                  handleCheckBoxClick(company, !checkedItems.has(company.id));
                }}
                style={{ pointerEvents: isDisabled ? "none" : "auto" }}
              >
                <CheckBoxField
                  name="addemployee"
                  value="0"
                  id={`company-${company.id}`}
                  lineHeight="1.5vw"
                  className="checkBoxLabel"
                  checkBoxWrapper="addEmployeeToCompany text-center p-2"
                  wrapperClass="d-inline-flex"
                  isChildren
                  isChecked={checkedItems.has(company.id)}
                  disable={isDisabled}
                  onChangeHandler={(event) => {
                    if (isDisabled) return;
                    handleCheckBoxClick(company, event.target.checked);
                  }}
                >
                  <div
                    title={company?.name}
                    onClick={(e) => {
                      if (isDisabled) {
                        e.stopPropagation();
                        return;
                      }
                    }}
                  >
                    <div style={{ height: companyIconHeight }}
                      onClick={(e) => {
                        if (isDisabled) {
                          e.stopPropagation();
                          return;
                        }
                      }}
                    >
                      <Image
                        src={company?.profile_picture_url ?? defaultCompanyIcon}
                        imageWidth={companyIconHeight} imageHeight={companyIconHeight} imageBorderRadius="50%"
                        onClick={(e) => {
                          if (isDisabled) e.stopPropagation();
                        }}
                      />
                    </div>
                    <div style={{ lineHeight: lineHeightStyle }}
                      className="mt-2"
                      onClick={(e) => {
                        if (isDisabled) e.stopPropagation();
                      }}
                    >
                      <LabelField
                        title={company?.name}
                        className="cursor-pointer"
                        onClick={(e) => {
                          if (isDisabled) e.stopPropagation();
                        }}
                      />
                    </div>
                  </div>
                </CheckBoxField>
              </div>
            );
          })}
        </div>
      </div>
      {companies.length > 7 && (
        <div
          style={{ width: contentWidth }}
          className="text-end nextIconWrapper"
          onClick={() => handleScroll("right")}
        >
          <Icon
            name="nextIcon"
            isIcon={true}
            width={iconStyle}
            height={iconStyle}
            fillColor="#8571b9"
          />
        </div>
      )}
    </div>
  );
};

export default CompanyList;

import React from "react";
import Icon from "../../Icon";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { t } from "../common/atoms/translation";
import { translate } from "../CentralDataMangement/translation/Translation";

interface Document {
  id: number;
  name: string;
  viewLink: string;
  downloadLink: string;
}

interface DocumentsTableProps {
  documents: Document[];
  viewDocument: (documentId: number) => void;
  downloadDocument: (documentId: number) => void;
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({ documents, viewDocument, downloadDocument }) => {
  return (
    <table className="table contractTable table-borderless mb-0">
      <tbody>
        {documents?.map?.((document) => (
          <React.Fragment key={document?.id}>
            <tr className="spaced-row">
              <td className="align-middle text-break p-4" style={{ width: "70%" }}>
                {document.name}
              </td>
              <td className="align-middle p-2">
                <div className="d-flex justify-content-end pe-4">
                  <Icon
                    name="viewIcon"
                    isIcon={true}
                    width="16px"
                    height="16px"
                    className="me-2 cursor-pointer secondaryColorHoverEffect"
                    onClick={() => viewDocument(document?.id)}
                    data-bs-toggle="tooltip"
                  />
                  <Icon
                    name="downloadIcon"
                    isIcon={true}
                    width="14px"
                    height="14px"
                    className="cursor-pointer secondaryColorHoverEffect"
                    onClick={() => downloadDocument(document?.id)}
                    data-bs-toggle="tooltip"
                  />
                </div>
              </td>
            </tr>
            <TableHeaderSpace headerLength={3} />
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default translate(DocumentsTable);

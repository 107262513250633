import React, { useState, useEffect } from "react";
import Button from "../common/atoms/Button";
import { t } from "../common/atoms/Constants";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import PCTable from "./PCTable";
import { Formdata, Option, PCtype } from "./Interfaces";

interface SectionProps {
  optionsList: Option[];
  selectedValue: Option | null;
  selectedEmpType: Option | null | any;
  state: any;
  addParitiarComitee?: (section: keyof Formdata, partiarcommittee: Option | null, employeetypes: Option[] | null, index?: number) => void;
  pcDeleteHandler?: (partiarcommittee: Option | null) => void;
  isBlueCollar: boolean;
  isWhiteCollar: boolean;
}

const ParitiarCommitteeSection: React.FC<SectionProps> = ({
  optionsList,
  selectedValue,
  selectedEmpType,
  state,
  addParitiarComitee,
  pcDeleteHandler,
  isBlueCollar,
  isWhiteCollar,
}) => {
  const [formState, setFormState] = useState({
    selectedPC: selectedValue,
    selectedEmployeeType: selectedEmpType,
    isEditing: false,
    editIndex: null as number | null,
    errorPC: "",
    errorEmployeeType: "",
  });

  // Get all selected PC IDs from company_pc_and_employee_types
  const selectedPCs = state?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types?.map?.(
    (item: PCtype) => item?.PC?.id
  ) || [];

  // Filter options list to exclude selected PCs
  const filteredOptionsList = optionsList?.filter(
    (option: Option) => !selectedPCs.includes(option?.value)
  );

  // Handle the change event for both select dropdowns
  const handleChange = (e: any, name: string) => {
    setFormState((prev) => ({
      ...prev,
      [name]: e,
      errorPC: "",
      errorEmployeeType: "",
    }));
  };

  // Handle edit action
  const editPCHandler = (index: number, type: string, data?: PCtype) => {
    const companyPCList = state?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types || [];
    const clickedPCId = data?.PC?.id;

    // Find the matched item based on type and PC.id
    const pcsList = type === "blueCollar" ? state?.pcs?.workers : state?.pcs?.servants;
    const matchedPC = pcsList?.find((pc: Option) => pc?.value === clickedPCId);

    if (matchedPC) {
      const matchedIndex = companyPCList?.findIndex((item: PCtype) => item?.PC?.id === clickedPCId);

      if (matchedIndex !== -1) {
        const item = companyPCList?.[matchedIndex];

        // Filter employee types to get only the matched ones
        const selectedEmployeeTypes = matchedPC?.employee_types?.filter((empType: Option) =>
          item?.employeeType?.some((itemEmpType: Option) => itemEmpType?.id === empType?.value)
        ) || [];

        // Update state with matched item
        setFormState({
          selectedPC: matchedPC,
          selectedEmployeeType: selectedEmployeeTypes,
          isEditing: true,
          editIndex: matchedIndex,
          errorPC: "",
          errorEmployeeType: "",
        });
      }
    }
  };

  // Handle submit action (add or update)
  const handleSubmit = () => {
    const { selectedPC, selectedEmployeeType, isEditing, editIndex } = formState;

    // Check if PC is selected and employee type is selected
    let errorPC = "";
    let errorEmployeeType = "";

    if (!selectedPC) {
      errorPC = t("This field is Required.");
    }
    if (!selectedEmployeeType || selectedEmployeeType.length === 0) {
      errorEmployeeType = t("This field is Required.");
    }

    if (errorPC || errorEmployeeType) {
      setFormState((prev) => ({
        ...prev,
        errorPC,
        errorEmployeeType,
      }));
      return;
    }

    if (isEditing && editIndex !== null) {
      // Update existing item at editIndex
      const updatedData = state?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types?.map?.(
        (item: PCtype, index: number) => {
          if (index === editIndex) {
            return {
              ...item,
              PC: selectedPC,
              employeeType: selectedEmployeeType,
            };
          }
          return item;
        }
      );
      addParitiarComitee?.("MainDetail", updatedData?.[editIndex]?.PC, updatedData?.[editIndex]?.employeeType, editIndex);
    } else {
      addParitiarComitee?.("MainDetail", selectedPC, selectedEmployeeType);
    }

    // Reset form state
    setFormState({
      selectedPC: null,
      selectedEmployeeType: null,
      isEditing: false,
      editIndex: null,
      errorPC: "",
      errorEmployeeType: "",
    });
  };

  // Reset values when switching between blue collar and white collar
  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      selectedPC: null,
      selectedEmployeeType: null,
      errorPC: "",
      errorEmployeeType: "",
    }));
  }, [isBlueCollar, isWhiteCollar]);

  return (
    <div>
      <div className="row">
        {isBlueCollar && (
          <>
            <div className="mb-3">
              <h5 className="mb-1">
                <LabelField title={t("Paritair committee (PC) 1")} isMandatory />
              </h5>
              <SelectWithSearch
                isMulti={false}
                isMandatory={true}
                search={true}
                options={filteredOptionsList}
                value={formState.selectedPC}
                onChange={(e: any) => handleChange(e, "selectedPC")}
                name="selectedPC"
                title={t("Paritair committee (PC) 2")}
                error={formState?.errorPC}
                isTranslate={true}
              />
            </div>

            <div className="mb-3">
              <h5 className="mb-1">
                <LabelField title={t("Selection of employee types (statuut) that can be used")} isMandatory />
              </h5>
              <SelectWithSearch
                isMulti={true}
                isMandatory={true}
                search={true}
                options={formState.selectedPC?.employee_types ?? []}
                value={formState.selectedEmployeeType}
                onChange={(e: any) => handleChange(e, "selectedEmployeeType")}
                name="selectedEmployeeType"
                title={t("Selection of employee types (statuut) that can be used")}
                error={formState?.errorEmployeeType}
                isTranslate={true}
              />
            </div>
          </>
        )}

        {isWhiteCollar && (
          <>
            <div className="mb-3">
              <h5 className="mb-1">
                <LabelField title={t("Paritair committee (PC) 2")} isMandatory />
              </h5>
              <SelectWithSearch
                isMulti={false}
                isMandatory={true}
                search={true}
                options={filteredOptionsList}
                value={formState.selectedPC}
                onChange={(e: any) => handleChange(e, "selectedPC")}
                name="selectedPC"
                title={t("Paritair committee (PC) 1")}
                error={formState.errorPC}
                isTranslate={true}
              />
            </div>

            <div className="mb-3">
              <h5 className="mb-1">
                <LabelField title={t("Selection of employee types (statuut) that can be used")} isMandatory />
              </h5>
              <SelectWithSearch
                isMulti={true}
                isMandatory={true}
                search={true}
                options={formState.selectedPC?.employee_types ?? []}
                value={formState.selectedEmployeeType}
                onChange={(e: any) => handleChange(e, "selectedEmployeeType")}
                name="selectedEmployeeType"
                title={t("Selection of employee types (statuut) that can be used")}
                error={formState.errorEmployeeType}
                isTranslate={true}
              />
            </div>
          </>
        )}

        <div className="col-md-12 text-end">
          <Button
            title={t(
              isBlueCollar
                ? formState.isEditing
                  ? "Update Paritair committee for blue collar"
                  : "Add Paritair committee for blue collar"
                : formState.isEditing
                  ? "Update Paritair committee for white collar"
                  : "Add Paritair committee for white collar"
            )}
            handleClick={() => handleSubmit()}
            className="searchBtn px-3"
          />
        </div>
      </div>

      {isBlueCollar && (
        <>
          <h5 className="mb-1">
            <LabelField title={t("Paritair committee for blue collar")} />
          </h5>
          <PCTable
            data={state?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types?.filter?.((item: PCtype) =>
              state?.pcs?.workers?.some?.((worker: Option) => worker?.value === item?.PC?.id)
            )}
            pcChangeHandler={editPCHandler}
            type="blueCollar"
            pcDeleteHandler={pcDeleteHandler}
          />
        </>
      )}

      {isWhiteCollar && (
        <>
          <h5 className="mb-1">
            <LabelField title={t("Paritair committee for white collar")} />
          </h5>
          <PCTable
            data={state?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types.filter((item: PCtype) =>
              state?.pcs?.servants?.some?.((servant: Option) => servant?.value === item?.PC?.id)
            )}
            pcChangeHandler={editPCHandler}
            type="whiteCollar"
            pcDeleteHandler={pcDeleteHandler}
          />
        </>
      )}
    </div>
  );
};

export default ParitiarCommitteeSection;

import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import IconWithTitle from "../molecules/IconWithTitle";
import { t } from "./translation";
import { Overlay, Popover } from "react-bootstrap";
import LabelField from "./LabelField";

interface SidebarItem {
  name: string;
  title: string;
  path?: string;
  submenu?: { name: string; title: string; path: string }[];
}

const sidebarItems: SidebarItem[] = [
  { name: "dashboard", title: "Dashboard", path: "/location-dashboard" },
  { name: "Planning", title: "Planning", path: "/day-planning-overview" },
  {
    name: "timeRegistration",
    title: "Time registration",
    path: "/time-registration",
    submenu: [
      { name: "open", title: "Open", path: "/time-registration/home" },
      { name: "closed", title: "Closed", path: "/time-registration/menu1" },
    ],
  },
  {
    name: "documentation",
    title: "Documents",
    path: "/none",
    submenu: [
      { name: "invoices", title: "Invoices", path: "/invoice-overview" },
      { name: "contracts", title: "Contracts", path: "/employee-contracts" },
      { name: "documents", title: "Documents", path: "/documents-overview" },
    ],
  },
  { name: "employeeGroup", title: "Your pool", path: "/employee-overview" },
  // { name: "ManageCompanies", title: "Manage companies", path: "/welcome-dashboard" },
  { name: "accountIcon", title: "Account", path: "/my-account" },
];

const adminSidebarItems: SidebarItem[] = [
  { name: "Users", title: "User", path: "/manage-users" },
  { name: "Roles", title: "Roles", path: "/manage-roles" },
  { name: "companies", title: "Companies", path: "/welcome-dashboard" },
  { name: "location", title: "Location", path: "/manage-location" },
  { name: "ManageProject", title: "Project", path: "/projects-manage" },
  { name: "costCenter", title: "Cost center", path: "/costcenters-manage" },
  { name: "emailTemplate", title: "Email Template", path: "/manage-email-templates" },
  { name: "translation", title: "Translation", path: "/translation/manage" },
  { name: "configSettings", title: "Configuration and settings", path: "/config/settings" },
  { name: "category", title: "Manage Category", path: "/manage-categories" },
  { name: "function", title: "Manage function", path: "/manage-functions" },
  { name: "pc", title: "Manage PC", path: "/manage-pcs" },
];

const Sidebar: React.FC = () => {
  const [activeItem, setActiveItem] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleBackdropClick = () => setShow(false);

    if (show) {
      document.body.style.overflow = "hidden";
      const backdrop = document.createElement("div");
      backdrop.className = "overlay-backdrop";
      backdrop.addEventListener("click", handleBackdropClick);
      document.body.appendChild(backdrop);

      return () => {
        document.body.style.overflow = "";
        backdrop.removeEventListener("click", handleBackdropClick);
        document.body.removeChild(backdrop);
      };
    }
  }, [show]);

  useEffect(() => {
    const currentItem = sidebarItems.find(
      (item) =>
        item.path === location.pathname ||
        item.submenu?.some((subitem) => subitem.path === location.pathname)
    );

    if (currentItem) {
      setActiveItem(currentItem.name);
    }
  }, [location.pathname]);

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: SidebarItem
  ) => {
    setActiveItem(item.name);
    if (item.submenu) {
      setTarget(event.currentTarget);
      setShow((prev) => !prev);
    } else {
      setShow(false);
      navigate(item.path || "/default-path");
    }
  };

  const renderAdminSideBarItem = (item: SidebarItem, index: number) => {
    return (
      <div key={index} className="sidebarItemWrapper">
        <span onClick={() => navigate(item.path || "/default-path")} className="cursor-pointer">
          <div className="col-12 sidebarContent text-center sidebarBorder">
            <IconWithTitle
              name={item.name}
              isIcon={true}
              isTitle={true}
              width="10vw"
              height="2vw"
              className="img-fluid sideBarIcon mx-auto"
              title={t(item.title)}
              labelClassName="sidebarTitle cursor-pointer"
            />
          </div>
        </span>
      </div>
    );
  };

  const renderSidebarItem = (item: SidebarItem, index: number) => {
    const isActive =
      location.pathname === item.path ||
      (item.submenu &&
        item.submenu.some((subitem) => location.pathname === subitem.path));
    const itemClassName = isActive
      ? "sidebarItemWrapper active"
      : "sidebarItemWrapper";

    return (
      <div
        key={index}
        className={itemClassName}
        ref={item.name === "documentation" ? ref : null}
      >
        <span
          onClick={(event) => handleItemClick(event, item)}
          className="cursor-pointer"
        >
          <div
            className={`col-12 sidebarContent text-center sidebarBorder ${isActive ? "active" : ""
              }`}
          >
            <IconWithTitle
              name={item.name}
              isIcon={true}
              isTitle={true}
              className="img-fluid sideBarIcon mx-auto"
              title={t(item.title)}
              labelClassName={`sidebarTitle cursor-pointer ${isActive ? "activeTitle" : ""
                }`}
            />
          </div>
        </span>
      </div>
    );
  };


  const handleSubmenuClick = (path: string, subitemName?: string) => {
    setShow(false);

    const state =
      subitemName === "closed"
        ? { activeTab: "menu1" }
        : subitemName === "open"
          ? { activeTab: "home" }
          : undefined;

    navigate(path, { state });
  };

  const renderSubmenu = () => {
    const activeItemObj = sidebarItems.find(
      (item) => item.name === activeItem && item.submenu
    );

    return activeItemObj?.submenu?.map((subitem, subindex) => {
      const isActive = subitem.path === location.pathname;

      return (
        <span
          key={subindex}
          className={`submenuItem d-block px-5 py-1 text-center cursor-pointer ${isActive ? "active" : ""
            }`}
          onClick={() => handleSubmenuClick(subitem.path, subitem.name)}
        >
          <LabelField
            title={subitem.title}
            className={`cursor-pointer poppins-medium colorPrimary ${isActive ? "activeTitle" : ""
              }`}
          />
        </span>
      );
    });
  };


  return (
    <div className="sideBar d-none d-lg-block">
      <div className="scrollBar h-100 overflow-auto">
        {/* Conditionally render this part if current path is not "/" */}
        <>
          <div
            className="col-12 sidebarContent sidebarBorder p-0"
            style={{ height: "1.5vw" }}
          ></div>
          {location.pathname !== "/" ? (
            <>
              {sidebarItems.map(renderSidebarItem)}
              <Overlay
                show={show}
                target={target}
                placement="right"
                container={ref.current}
                containerPadding={20}
              >
                <Popover id="popover-basic">
                  <Popover.Body>{renderSubmenu()}</Popover.Body>
                </Popover>
              </Overlay>
            </>
          ) : (
            <>{adminSidebarItems.map(renderAdminSideBarItem)}</>
          )}
        </>
      </div>
    </div>
  );
};

export default Sidebar;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalPopup from "../common/atoms/ModalPopup";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { RootState } from "../../store";
import { selectPCData } from "../../features/planning/employeeSlice";
import { fetchPcInfo } from "./Services/PlanningServices";
import { fetchSalary, updatesalaryFormat } from "./Services/PlanningSalaryHelper";
import { validateSalary } from "./Validations/PlanningScheduleValidation";
import CustomAlert from "../common/atoms/CustomAlert";

// Type Definitions (unchanged from previous code)
interface EmployeeData {
    employee_id: number;
    employee_name: string;
    employee_type_id: number | null;
    employee_type: string | null;
    location_id: number;
    location_name: string;
    age: number | null;
    pc_id: number | null;
    pc: string | null;
    new_salary?: string | null;
    function_id: number | null;
    function: string | null;
    salary: string;
    start_date: string;
    end_date: string;
    salaryError?: string;
    salaryWarning?: string;
}

interface FunctionOptions {
    label: string;
    value: number;
    salary?: string;
}

interface PcAges {
    id: number;
    pc_id: number;
    age: number;
    min_sal_percent: string;
    status: boolean;
    max_hour: string;
}

interface CategoryOptions {
    category_name: string;
    category_id: number;
    min_salary: string;
    label: string;
    value: number;
    functions: FunctionOptions[];
}

interface PcOptions {
    id: number;
    pc_number: number;
    pc_name: string;
    pc_max_age: number;
    student_cat: string;
    flex_salary: string;
    label: string;
    value: number;
    employee_types: FunctionOptions[];
    pc_ages: PcAges[];
    categories: CategoryOptions[];
    functions: FunctionOptions[];
}

interface LayoutEditPopupProps {
    employeeData: EmployeeData;
    saveEmployeeData: (empData: EmployeeData) => void;
    clearPopup: () => void;
}

const LayoutEditPopup: React.FC<LayoutEditPopupProps> = ({
    employeeData: initialEmployeeData,
    saveEmployeeData,
    clearPopup,
}) => {
    const [employeeData, setEmployeeData] = useState<EmployeeData>(initialEmployeeData);
    const [loading, setLoading] = useState(false);
    const state = useSelector((state: RootState) => state);
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    const dispatch = useDispatch();
    const companyId = useSelector((state: RootState) => state.auth.currentCompany?.id);
    const pcData = useSelector(selectPCData);
    const [selectedPc, setSelectedPc] = useState<PcOptions | null>(null);

    // Fetch PC data
    useEffect(() => {
        if (companyId) {
            fetchPcInfo(companyId, dispatch, state);
        }
    }, [companyId]);

    // Sync selected PC with employeeData
    useEffect(() => {
        if (pcData) {
            const defaultPc = pcData?.find((pc: PcOptions) => pc.id === employeeData.pc_id);
            setSelectedPc(defaultPc || null);
        }
    }, [pcData, employeeData.pc_id]);

    const handleChange = async (field: string, value: any) => {
        // Clear specific validation error when field is updated
        if (validationErrors[field]) {
            setValidationErrors(prev => {
                const newErrors = { ...prev };
                delete newErrors[field];
                return newErrors;
            });
        }

        if (field === "pc") {
            setEmployeeData((prev: any) => ({
                ...prev,
                pc_id: value?.value || null,
                pc: value?.label || null,
                employee_type_id: null, // Reset employee type and function when PC changes
                employee_type: null,
                function_id: null,
                function: null,
            }));
            const Pc = pcData.find((pc: PcOptions) => pc.id === value?.value);
            setSelectedPc(Pc);

        } else if (field === "employee_type") {
            setEmployeeData((prev: any) => ({
                ...prev,
                employee_type_id: value?.value,
                employee_type: value?.label,
            }));
            if (selectedPc != null && employeeData.function_id && value?.value) {
                const newFetchedSalary = await fetchSalary(
                    selectedPc,
                    employeeData.function_id,
                    value?.value,
                    employeeData
                );
                setEmployeeData((prev: any) => ({
                    ...prev,
                    salary: newFetchedSalary
                }));
            }
        } else if (field === "function") {
            setEmployeeData((prev: any) => ({
                ...prev,
                function_id: value?.value,
                function: value?.label,
            }));
            if (selectedPc != null && employeeData.employee_type_id && value?.value) {
                const newFetchedSalary = await fetchSalary(
                    selectedPc,
                    value?.value,
                    employeeData.employee_type_id,
                    employeeData
                );
                setEmployeeData((prev: any) => ({
                    ...prev,
                    salary: newFetchedSalary
                }));
            }
        } else if (field === "salary") {
            setEmployeeData((prev: any) => ({
                ...prev,
                salary: value
            }));
        } else if (field === "new_salary" && selectedPc && employeeData.employee_type_id) {
            const { error, warning } = validateSalary(value, employeeData.salary, employeeData.employee_type_id, selectedPc.flex_salary);
            setEmployeeData((prev: any) => ({
                ...prev,
                new_salary: value,
                salaryError: error,
                salaryWarning: warning
            }));
        }
        else if (field === "new_salary") {
            setEmployeeData((prev: any) => ({
                ...prev,
                new_salary: value
            }));
        }
    };

    const validateForm = () => {
        const errors: { [key: string]: string } = {};

        // PC Validation
        if (!employeeData.pc_id) {
            errors.pc = t("PC is required");
        }

        // Employee Type Validation
        if (!employeeData.employee_type_id) {
            errors.employee_type = t("Employee Type is required");
        }

        // Function Validation
        if (!employeeData.function_id) {
            errors.function = t("Function is required");
        }

        // Salary Validation
        if (!employeeData?.salary || employeeData?.salary.trim() === '') {
            errors.salary = t("Salary is required");
        } else {
            // Optional: Add numeric validation if salary should be a number
            const salaryNum = parseFloat(employeeData.salary);
            if (isNaN(salaryNum) || salaryNum <= 0) {
                errors.salary = t("Salary must be a positive number");
            }
        }
        // Location Validation
        if (!employeeData.location_id) {
            errors.location = t("Location is required");
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSave = async () => {
        if (!validateForm()) return;

        try {
            console.log(employeeData.salaryError);
            if (employeeData.salaryError == undefined || employeeData.salaryError == "") {
                setLoading(true);
                saveEmployeeData(employeeData);
                setLoading(false);
                clearPopup();
            }
        } catch (error) {
            console.error("Error saving planning data:", error);
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        clearPopup();
    };

    return (
        <ModalPopup
            show={true}
            handleClose={handleCloseModal}
            modalTitle={`${t("Edit details")} - ${employeeData.start_date}`}
            className="newPlanning"
            buttonText={t("Save")}
            onNextClick={handleSave}
            disableNext={loading}
        >
            <div className="row">
                {/* Employee Name */}
                <div className="col-12 mb-2">
                    <InputTextFieldWithLabel
                        value={employeeData.employee_name}
                        readOnly
                        name={t("Employee Name")}
                        className="inputFieldColor"
                        isDisabled
                    />
                </div>

                {/* Dropdowns */}
                <div className="multiSelectWrapper row">
                    {/* PC Selection */}
                    <div className="col-6">
                        <SelectWithSearch
                            search
                            options={pcData?.map((pc: PcOptions) => ({
                                label: `(${pc.pc_number}) ${pc.pc_name}`,
                                value: pc.id,
                            }))}
                            value={
                                selectedPc
                                    ? {
                                        label: `(${selectedPc.pc_number}) ${selectedPc.pc_name}`,
                                        value: selectedPc.id,
                                    }
                                    : null
                            }
                            onChange={(value) => handleChange("pc", value)}
                            placeHolder={t("Select PC")}
                            name="pc"
                            selectWrapperClassName="mb-2"
                            error={validationErrors?.pc}
                        />
                    </div>

                    {/* Employee Type */}
                    <div className="col-6">
                        {selectedPc && (
                            <SelectWithSearch
                                search
                                options={selectedPc?.employee_types}
                                value={selectedPc?.employee_types?.find(
                                    (type) => type?.value === employeeData?.employee_type_id
                                )}
                                onChange={(value) => handleChange("employee_type", value)}
                                placeHolder={t("Select Employee Type")}
                                name="employee_type"
                                selectWrapperClassName="mb-2"
                                error={validationErrors?.employee_type}
                            />
                        )}
                    </div>

                    {/* Functions */}
                    <div className="col-8">
                        {selectedPc && employeeData.employee_type_id && (
                            <SelectWithSearch
                                search
                                options={[
                                    ...(selectedPc?.categories?.flatMap(
                                        (category) => category?.functions
                                    ) || []),
                                    ...(selectedPc?.functions || [])
                                ]}
                                value={selectedPc?.categories
                                    ?.flatMap((category) => category?.functions)
                                    ?.find((func) => func?.value === employeeData?.function_id)}
                                onChange={(value) => handleChange("function", value)}
                                placeHolder={t("Select Function")}
                                name="function"
                                selectWrapperClassName="mb-2"
                                error={validationErrors.function}
                            />
                        )}
                    </div>

                    {/* Salary */}
                    <div className="col-4">
                        {selectedPc && employeeData.function_id && (
                            <InputTextFieldWithLabel
                                value={updatesalaryFormat(employeeData.salary)}
                                handleChange={(e) => handleChange("salary", e.target.value)}
                                name={t("Salary")}
                                placeholder={t("Enter Salary")}
                                error={validationErrors.salary}
                                isDisabled
                            />
                        )}
                    </div>

                    {/* New salary */}
                    <div className="col-4">
                        {selectedPc && employeeData.function_id && (
                            <InputTextFieldWithLabel
                                value={employeeData.new_salary != undefined ? employeeData.new_salary.replace('.', ',') : ""}
                                handleChange={(e) => handleChange("new_salary", e.target.value)}
                                name={t("New salary")}
                                placeholder={t("Enter new Salary")}
                            />
                        )}
                    </div>
                </div>
                {employeeData.salaryError != undefined &&
                    employeeData.salaryError != "" && (
                        <CustomAlert
                            variant="danger"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {employeeData.salaryError}
                        </CustomAlert>
                    )}
                {employeeData.salaryWarning != undefined &&
                    employeeData.salaryWarning != "" && (
                        <CustomAlert
                            variant="warning"
                            iconName="warningIcon"
                            className="warningAndErrorAlert"
                        >
                            {employeeData.salaryWarning}
                        </CustomAlert>
                    )}
            </div>
        </ModalPopup>
    );
};

export default translate(LayoutEditPopup);
import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import data from "./companies-tiles.json";
import { Link, useParams } from "react-router-dom";
import CardTitle from "../common/atoms/CardTitle";
import DashboardCard from "../common/atoms/DashboardCard";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { CompanyDataInterface } from "../Dashboard/WelcomeDashboard";
import { Company, DropdownOptions, ReduxState } from "../Dashboard/services/CompanyInterface";
import { useSelector } from "react-redux";
import { PC_LIST, REGISTER_COUNTRY_LIST } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { selectIbanCountries, selectTimezones } from "../../features/settings/settingSlice";
import AddCompanyModal from "../Dashboard/AddCompany";
import BackLink from "../common/atoms/BackLink";

type OptionType = { label: string; value: string };
const ManageCompanies = () => {
    const { companyId } = useParams();
    const [showModal, setShowModal] = useState(false);
    const user = useSelector((state: ReduxState) => state?.auth);
    const company = user?.employer_companies?.find((item) => item?.id === Number(companyId)) ?? user?.employer_companies?.[0];

    const iban_countries = useSelector(selectIbanCountries);
    const timezones = useSelector(selectTimezones);
    const [pcValue, setPcValue] = useState<OptionType[] | null>(null);
    const [companyData, setCompanyData] = useState<CompanyDataInterface>({
        companies: [],
        countries: [],
        iban: [],
        pc: [],
        companyid: null,
        timezones: [],
    });

    useEffect(() => {
        setCompanyData((prevState) => ({
            ...prevState,
            companies: user?.employer_companies,
        }));
    }, [user?.companies]);


    const handleShowModal = async (company: Company | null) => {
        setShowModal(true);
        setCompanyData((prev) => ({
            ...prev,
            companyid: company ? company?.id : null,
        }));
        await Promise.all([
            fetchCountryOptions(),
            fetchPcOptions(),
            fetchIbanCountries(),
        ]);
    };

    const handleCloseModal = () => setShowModal(false);

    const handlePcChange = (selectedOption: OptionType[] | null) => {
        setPcValue(selectedOption);
    };

    const fetchCountryOptions = async () => {
        const url = REGISTER_COUNTRY_LIST;
        try {
            const result = await ApiCall?.getService(url, "GET");
            setCompanyData((prev) => ({
                ...prev,
                countries: result?.data || [],
            }));
        } catch (error) {
            console.error("Error fetching country data:", error);
        }
    };

    const fetchPcOptions = async () => {
        try {
            const result = await ApiCall?.getService(PC_LIST, "GET");
            const pcData = result?.data?.map((pc: DropdownOptions) => ({
                value: pc?.value,
                label: pc?.label,
            }));
            setCompanyData((prev) => ({
                ...prev,
                pc: pcData,
            }));
        } catch (error) {
            console.error("Error fetching PC data:", error);
        }
    };

    const fetchIbanCountries = async () => {
        try {
            const ibanData = iban_countries?.map((iban: DropdownOptions) => ({
                value: iban?.value,
                label: iban?.label,
            }));
            const timezoneData = timezones?.map((timezone: DropdownOptions) => ({
                value: timezone?.value,
                label: timezone?.label,
            }));
            setCompanyData((prev) => ({
                ...prev,
                iban: ibanData,
                timezones: timezoneData,
            }));
        } catch (error) {
            console.error("Error fetching IBAN countries:", error);
        }
    };

    return (
        <>
            <Header />
            <div className="mt-2">
                <BackLink backLink={"/welcome-dashboard"} />
            </div>
            <div className="row equal-cols manageCompanies">
                {data &&
                    data?.map((item) => (
                        <div className="col-lg-4 col-md-6 mb-4" key={item?.id}>
                            {item?.path !== '' &&
                                <Link to={item?.path + "/" + companyId} className="text-decoration-none">
                                    <DashboardCard className="card rounded-3 p-4 border-0 card-shadow justify-content-center h-100">
                                        <div className="row">
                                            <div className="col-12">
                                                <CardTitle
                                                    title={t(item?.title)}
                                                    className="cardTitle"
                                                />
                                            </div>
                                        </div>
                                    </DashboardCard>
                                </Link>
                            }
                            {item?.path === '' &&
                                <div onClick={() => handleShowModal(company)} className="cursor-pointer">
                                    <DashboardCard className="card rounded-3 p-4 border-0 card-shadow justify-content-center h-100">
                                        <div className="row">
                                            <div className="col-12">
                                                <CardTitle
                                                    title={t(item?.title)}
                                                    className="cardTitle"
                                                />
                                            </div>
                                        </div>
                                    </DashboardCard>
                                </div>
                            }
                        </div>
                    ))}
            </div>
            <AddCompanyModal
                show={showModal}
                handleClose={handleCloseModal}
                pcValue={pcValue}
                handlePcChange={handlePcChange}
                companyid={companyData?.companyid}
                countries={companyData?.countries}
                pc={companyData?.pc}
                iban={companyData?.iban}
                timezones={companyData?.timezones}
            />
        </>
    );
};
export default translate(ManageCompanies);

import React, { ReactNode, forwardRef } from "react";
import Modal from "react-bootstrap/Modal";
import Icon from "../../../Icon";
import Button from "./Button";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import { useResponsiveValue } from "./ResponsivWidth";

interface ModalPopupProps {
  show?: boolean;
  handleClose?: () => void;
  modalTitle?: string;
  children?: ReactNode;
  buttonText?: string;
  modalBackgroundColor?: string;
  className?: string;
  showNextIcon?: boolean;
  showBackIcon?: boolean;
  onHide?: () => void;
  title?: string;
  onNextClick?: () => void;
  handleBack?: () => void;
  iconFillColor?: string;
  body?: ReactNode | string;
  onCloseButtonClick?: () => void;
  onConfirmButtonClick?: (event?: any) => void;
  closeTitle?: string;
  confirmTitle?: string;
  isInnerHTML?: boolean;
  modalBodyClassName?: string;
  modalContentRef?: React.RefObject<HTMLDivElement>;
  disableNext?: boolean;
  disableBack?: boolean;
}

const ModalPopup = forwardRef<HTMLDivElement, ModalPopupProps>(({
  show,
  body,
  handleClose,
  modalTitle,
  children,
  buttonText,
  modalBackgroundColor,
  className,
  showNextIcon = false,
  showBackIcon = false,
  onNextClick,
  handleBack,
  iconFillColor,
  onCloseButtonClick,
  onConfirmButtonClick,
  closeTitle,
  confirmTitle,
  isInnerHTML = false,
  modalBodyClassName,
  modalContentRef,
  disableNext = false,
  disableBack = false
}) => {
  const iconStyle = useResponsiveValue("5vw", "3.5vw", "1vw");
  const headerStyle: React.CSSProperties = {
    backgroundColor: 'rgb(133, 113, 185)',
  };


  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop='static'
      animation={false}
      centered
      className={`modalWithoutTabs ${className} customModal`}
    >
      <Modal.Header closeButton className="py-1 text-white" style={headerStyle}>
        <Modal.Title className="text-center w-100 py-1">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body ref={modalContentRef} className={` ${modalBodyClassName} border-0`}>
        {!isInnerHTML && <div>{body}</div>}
        {isInnerHTML && typeof body === 'string' && (
          <div dangerouslySetInnerHTML={{ __html: body }} />
        )}
        {children}
      </Modal.Body>
      <Modal.Footer className="border-0">
        {confirmTitle ? (
          <div className="row w-100">
            <div className="col-12 text-end">
              <Button
                handleClick={onConfirmButtonClick}
                className="me-2 searchBtn px-3"
              >
                {confirmTitle}
              </Button>
              <Button
                handleClick={onCloseButtonClick || handleClose}
                className="resetBtn px-3"
              >
                {closeTitle}
              </Button>
            </div>
          </div>
        ) : (
          <div className="row w-100">
            <div className="col-4 text-start backIconWrapper" aria-disabled={disableBack}>
              {showBackIcon && (
                <Icon
                  name="backIcon"
                  width={iconStyle}
                  height={iconStyle}
                  isIcon={true}
                  fillColor={iconFillColor}
                  onClick={handleBack}
                />
              )}
            </div>
            <div className="col-4 text-center">
              {buttonText &&
                <AccessControl
                  requiredPermissions={[
                    {
                      permission: "company",
                      update: (buttonText === 'Update') ? true : false,
                      create: (buttonText === 'Register') ? true : false,
                    },
                  ]}
                  renderNoAccess={true}
                >
                  <Button className="modalSaveBtn py-0" handleClick={onNextClick} disabled={disableNext} >
                    {buttonText}
                  </Button>
                </AccessControl>
              }
            </div>
            <div className="col-4 text-end nextIconWrapper" aria-disabled={disableNext}>
              {showNextIcon && (
                <Icon
                  name="nextIcon"
                  width={iconStyle}
                  height={iconStyle}
                  isIcon={true}
                  onClick={onNextClick}
                  fillColor={iconFillColor}
                />
              )}
            </div>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
});

export default ModalPopup;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalPopup from "../common/atoms/ModalPopup";
import { ApiCall } from "../../services/ApiServices";
import { EDIT_PLANNING } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE, SUCCESS } from "../../Constants";
import CustomNotify from "../common/atoms/CustomNotify";
import { selectPCData } from "../../features/planning/employeeSlice";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { fetchPcInfo } from "./Services/PlanningServices";
import { RootState } from "../../store";
import Icon from "../../Icon";
import { formatTimeInput } from "./Services/PlanningTimingHelper";
import { fetchSalary, formatFloatSalary, } from "./Services/PlanningSalaryHelper";
import { validateSalary } from "./Validations/PlanningScheduleValidation";
import CustomAlert from "../common/atoms/CustomAlert";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import { formatDateDMY } from "../common/utlis/dateHelper";
import { validatePlanningFieldsForDate } from "./Validations/PlanningValidations";
import { ageLevelWarnings } from "./Validations/PlanningTimingValidations";

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface TimeRegistration {
  end_time: string;
  sequence: string;
  start_time: string;
  time_registration_id: number;
  total_break: string;
  total_count: string;
}

interface Schedule {
  shifts: {
    start_time: string;
    end_time: string;
    sequence: number;
  }[];
}

interface EventData {
  length: number;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  planning_id: number;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  new_salary?: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_break: number;
  total_hrs: number;
  age: number;
  schedule: Timing[];
  time_registration: TimeRegistration[];
}

interface ValidationErrors {
  timings: {
    [key: number]: {
      shift_starttime?: string;
      shift_endtime?: string;
    };
    total?: string;
  };
  functionError?: string;
  salaryError?: string;
  salaryWarning?: string;
  warnings?: string[];
  pcError?: string;
  employeeTypeError?: string;
  generalErrors?: string[];
}

interface EditPlanningPopupProps extends EventData {
  employeeData: EventData;
  clearPopup: () => void;
}

interface PcOptions {
  id: number;
  pc_number: number;
  pc_name: string;
  student_cat: string;
  flex_salary: string;
  pc_max_age: number;
  label: string;
  value: number;
  employee_types: FunctionOptions[];
  pc_ages: PcAges[];
  categories: CategoryOptions[];
  functions: FunctionOptions[];
}

interface FunctionOptions {
  label: string;
  value: number;
  salary?: string;
}

interface PcAges {
  id: number;
  pc_id: number;
  age: number;
  min_sal_percent: string;
  status: boolean;
  max_hour: string;
}

interface CategoryOptions {
  category_name: string;
  category_id: number;
  min_salary: string;
  label: string;
  value: number;
  functions: FunctionOptions[];
}

const EditPlanningPopup: React.FC<EditPlanningPopupProps> = ({
  employeeData: initialEmployeeData,
  clearPopup,
}) => {
  const [employeeData, setEmployeeData] = useState<EventData>(initialEmployeeData);
  const state = useSelector((state: RootState) => state);
  const companyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const pcData = useSelector(selectPCData);
  const dispatch = useDispatch();
  const iconStyle = useResponsiveValue("5vw", "3vw", "1vw");

  const [showModal, setShowModal] = useState(true);
  const [selectedPc, setSelectedPc] = useState<PcOptions | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [new_salary, setNewSalary] = useState<string>(employeeData.new_salary ?? "");
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    timings: {},
    salaryError: "",
    functionError: "",
    salaryWarning: "",
    warnings: [],
    pcError: "",
    employeeTypeError: "",
    generalErrors: []
  });



  useEffect(() => {
    fetchPcInfo(companyId, dispatch, state);
    validateBeforeSave(); // Run initial validation
  }, [companyId]);

  useEffect(() => {
    if (pcData) {
      const defaultPc = pcData.find((pc: PcOptions) => pc.id === employeeData.pc_id);
      setSelectedPc(defaultPc || null);
    }
  }, [pcData, employeeData]);

  const ValidationMessages = () => {
    return (
      <div className="validation-messages mt-3">
        {validationErrors.generalErrors?.map((error, index) => (
          <CustomAlert
            key={`general-${index}`}
            variant="danger"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {error}
          </CustomAlert>
        ))}

        {validationErrors.pcError && (
          <CustomAlert
            variant="danger"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {validationErrors.pcError}
          </CustomAlert>
        )}

        {validationErrors.employeeTypeError && (
          <CustomAlert
            variant="danger"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {validationErrors.employeeTypeError}
          </CustomAlert>
        )}

        {validationErrors.functionError && (
          <CustomAlert
            variant="danger"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {validationErrors.functionError}
          </CustomAlert>
        )}

        {validationErrors.salaryError && (
          <CustomAlert
            variant="danger"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {validationErrors.salaryError}
          </CustomAlert>
        )}

        {validationErrors.salaryWarning && (
          <CustomAlert
            variant="warning"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {validationErrors.salaryWarning}
          </CustomAlert>
        )}

        {Object.entries(validationErrors.timings).map(([index, errors]) => {
          if (typeof errors === 'string' && errors !== "") {
            return (
              <CustomAlert
                key={`timing-${index}`}
                variant="danger"
                iconName="warningIcon"
                className="warningAndErrorAlert mb-2"
              >
                {errors}
              </CustomAlert>
            );
          }
          // return Object.entries(errors).map(([field, error]) => (
          //   error && (
          //     <CustomAlert
          //       key={`timing-${index}-${field}`}
          //       variant="danger"
          //       iconName="warningIcon"
          //       className="warningAndErrorAlert mb-2"
          //     >
          //       {`Shift ${parseInt(index) + 1} ${field}: ${error}`}
          //     </CustomAlert>
          //   )
          // ));
        })}

        {validationErrors.warnings?.map((warning, index) => (
          <CustomAlert
            key={`warning-${index}`}
            variant="warning"
            iconName="warningIcon"
            className="warningAndErrorAlert mb-2"
          >
            {warning}
          </CustomAlert>
        ))}
      </div>
    );
  };

  const timeToMinutes = (timeString: string): number => {
    if (!timeString) return 0;
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const calculateDuration = (startTime: string, endTime: string): number => {
    const startMinutes = timeToMinutes(startTime);
    const endMinutes = timeToMinutes(endTime);
    let duration = endMinutes - startMinutes;
    if (duration < 0) duration += 24 * 60;
    return parseFloat((duration / 60).toFixed(2));
  };


  const validateBeforeSave = (): boolean => {
    const errors: ValidationErrors = {
      timings: {},
      functionError: "",
      salaryError: "",
      warnings: [],
      generalErrors: []
    };

    let isValid = true;

    // 1. Basic field validations
    if (!employeeData.function_id) {
      errors.functionError = t("Please select a function");
      isValid = false;
    }

    if (!employeeData.pc_id) {
      errors.pcError = t("Please select a PC");
      isValid = false;
    }

    if (!employeeData.employee_type_id) {
      errors.employeeTypeError = t("Please select an employee type");
      isValid = false;
    }

    // 2. Salary validation
    if (employeeData.new_salary !== undefined && employeeData.new_salary) {
      const salary = formatFloatSalary(employeeData.new_salary);
      if (salary > 100) {
        errors.salaryError = t("Salary cannot exceed 100 euros");
        isValid = false;
      }
    }

    // 3. Schedule validation for each shift
    let totalHours = 0;

    employeeData.schedule.forEach((timing, index) => {
      errors.timings[index] = {};

      // Required field validation
      if (!timing.shift_starttime || !timing.shift_endtime) {
        errors.timings[index].shift_starttime = !timing.shift_starttime ? t("Start time is required") : undefined;
        errors.timings[index].shift_endtime = !timing.shift_endtime ? t("End time is required") : undefined;
        isValid = false;
      }

      // Time format validation
      const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
      if (timing.shift_starttime && !timeRegex.test(timing.shift_starttime)) {
        errors.timings[index].shift_starttime = t("Invalid time format (HH:MM)");
        isValid = false;
      }
      if (timing.shift_endtime && !timeRegex.test(timing.shift_endtime)) {
        errors.timings[index].shift_endtime = t("Invalid time format (HH:MM)");
        isValid = false;
      }

      // Calculate total hours if times are valid
      if (timing.shift_starttime && timing.shift_endtime) {
        totalHours += calculateDuration(timing.shift_starttime, timing.shift_endtime);
      }
    });

    // 4. Validate total hours
    if (totalHours > 16) {
      errors.timings.total = t("Total shift duration cannot exceed 16 hours (Current: ") +
        totalHours.toFixed(2) + t(" hours)");
      isValid = false;
    } else if (totalHours < 1) {
      errors.timings.total = t("Total shift duration must be at least 1 hour");
      isValid = false;
    }

    // 5. Planning fields validation for each date
    const scheduleForValidation: Schedule = {
      shifts: employeeData.schedule.map(timing => ({
        start_time: timing.shift_starttime,
        end_time: timing.shift_endtime,
        sequence: timing.shift_sequence
      }))
    };

    const planningError = validatePlanningFieldsForDate(
      state,
      scheduleForValidation,
      employeeData.pc_id!,
      employeeData.pdate
    );

    if (planningError) {
      errors.generalErrors?.push(planningError);
      isValid = false;
    }

    // 6. Age level warnings
    const ageWarnings = ageLevelWarnings(
      employeeData.pc_id!,
      employeeData.age,
      state,
      scheduleForValidation.shifts
    );

    if (ageWarnings && ageWarnings.length > 0) {
      errors.warnings = ageWarnings;
    }

    setValidationErrors(errors);
    return isValid;
  };    

  const handleCloseModal = () => {
    clearPopup();
    setShowModal(false);
  };

  const handleAddShift = (date: string) => {
    const newTiming: Timing = {
      shift_id: -999,
      shift_starttime: "",
      shift_endtime: "",
      shift_sequence: employeeData.schedule.length + 1,
    };

    setEmployeeData(prev => ({
      ...prev,
      schedule: [...prev.schedule, newTiming],
    }));
  };

  const handleRemoveShift = (date: string) => {
    setEmployeeData(prev => ({
      ...prev,
      schedule: prev.schedule.slice(0, -1),
    }));
  };

  const handleShiftChange = (
    date: string,
    index: number,
    field: string,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);
    setEmployeeData(prev => {
      const updatedTimings = [...prev.schedule];

      if (field === "start_time") {
        updatedTimings[index].shift_starttime = formattedValue;
      } else if (field === "end_time") {
        updatedTimings[index].shift_endtime = formattedValue;
      }

      const scheduleForValidation: Schedule = {
        shifts: updatedTimings.map(timing => ({
          start_time: timing.shift_starttime,
          end_time: timing.shift_endtime,
          sequence: timing.shift_sequence
        }))
      };

      const errorMessage = validatePlanningFieldsForDate(
        state,
        scheduleForValidation,
        employeeData.pc_id!,
        date
      );

      const warningMessages = ageLevelWarnings(
        employeeData.pc_id!,
        employeeData.age,
        state,
        scheduleForValidation.shifts
      );

      setValidationErrors(prevErrors => ({
        ...prevErrors,
        timings: {
          ...prevErrors.timings,
          [index]: {
            ...(prevErrors.timings[index] || {}),
            shift_starttime: field === "start_time" ? errorMessage : prevErrors.timings[index]?.shift_starttime,
            shift_endtime: field === "end_time" ? errorMessage : prevErrors.timings[index]?.shift_endtime
          },
          total: errorMessage
        },
        warnings: warningMessages || []
      }));

      return {
        ...prev,
        schedule: updatedTimings,
      };
    });
  };

  const handleChange = async (field: string, value: any) => {
    setEmployeeData(prev => ({
      ...prev,
      employee_type_id: field === "employee_type" ? value.value : prev.employee_type_id,
      function_id: field === "function" ? value.value : prev.function_id,
    }));

    if (field === "employee_type" || field === "function") {
      const functionID = field === "function" ? value.value : employeeData.function_id;
      const employeeType = field === "employee_type" ? value.value : employeeData.employee_type_id;

      if (selectedPc != null) {
        const newFetchedSalary = await fetchSalary(
          selectedPc,
          functionID,
          employeeType,
          employeeData
        );

        setEmployeeData(prev => ({
          ...prev,
          salary: newFetchedSalary || prev.salary,
        }));
      }
      setNewSalary("");
    }
  };

  const handleSave = async () => {
    if (!validateBeforeSave()) {
      CustomNotify({
        type: "error",
        message: t("Please correct the errors before saving"),
        autoClose: 3000,
      });
      return false;
    }

    try {
      setLoading(true);
      const dataToSend = {
        ...employeeData,
        salary: parseFloat(employeeData.salary.replace(",", ".").replace(/\s/g, "")),
      };

      if (employeeData.new_salary && employeeData.new_salary.trim() !== "") {
        dataToSend.new_salary = employeeData.new_salary.replace(",", ".").replace(/\s/g, "");
      }

      const result = await ApiCall.service(
        EDIT_PLANNING,
        "POST",
        dataToSend,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (result.status === SUCCESS) {
        setLoading(false);
        setShowModal(false);
        clearPopup();
        CustomNotify({
          type: "success",
          message: t("Planning updated successfully"),
          autoClose: 2500,
        });
        return true;
      }
    } catch (error) {
      setLoading(false);
      console.error("Error updating planning:", error);
      CustomNotify({
        type: "error",
        message: t("Failed to update planning"),
        autoClose: 3000,
      });
      return false;
    }
  };

  const handlePcChange = (value: any) => {
    const pc = pcData.find((pc: PcOptions) => pc.id === value.value);
    setSelectedPc(pc || null);
  };

  const handleSalaryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const salaryInput = e.target.value;

    const { error, warning } = validateSalary(
      salaryInput,
      employeeData.salary,
      employeeData.employee_type_id,
      selectedPc?.flex_salary ?? null
    );

    setValidationErrors(prev => ({
      ...prev,
      salaryError: error,
      salaryWarning: warning,
    }));

    setEmployeeData(prev => ({
      ...prev,
      new_salary: salaryInput,
    }));
    setNewSalary(salaryInput);
  };

  const shiftCells = (daySchedule: Timing[], date: string, timeRegistration: TimeRegistration[]) => {
    const hasTimeRegistration = (sequence: number, key: string) => {
      if (!timeRegistration || timeRegistration.length === 0) return false;

      return timeRegistration.some(tr => {
        const sequenceMatch = parseInt(tr.sequence) === sequence;
        const timeField = key === 'start_time' ? tr.start_time : tr.end_time;
        const hasTime = timeField && timeField !== "" && timeField !== null;
        return sequenceMatch && hasTime;
      });
    };

    const shouldDisableControls = (sequence: number) => {
      if (!timeRegistration || timeRegistration.length === 0) return false;

      return timeRegistration.some(tr =>
        parseInt(tr.sequence) === sequence &&
        ((tr.start_time && tr.start_time !== "") ||
          (tr.end_time && tr.end_time !== ""))
      );
    };

    return (
      <div className="col-12 mt-1 mt-lg-0">
        <div className="shift-cells-container row">
          {daySchedule?.map((shift, index) => (
            <React.Fragment key={index}>
              <div className="col-lg-4 col-md-5 shift-cell d-flex">
                <span className="align-middle me-auto" style={{ width: "48%" }}>
                  <InputTextFieldWithLabel
                    value={shift.shift_starttime}
                    handleChange={(e) =>
                      handleShiftChange(
                        date,
                        index,
                        "start_time",
                        e.target.value
                      )
                    }
                    name={"start time"}
                    // error={validationErrors.timings[index]?.shift_starttime}
                    isDisabled={hasTimeRegistration(shift.shift_sequence, 'start_time')}
                  />
                </span>

                <span className="align-middle ms-auto" style={{ width: "48%" }}>
                  <InputTextFieldWithLabel
                    value={shift.shift_endtime}
                    handleChange={(e) =>
                      handleShiftChange(date, index, "end_time", e.target.value)
                    }
                    name={"end time"}
                    // error={validationErrors.timings[index]?.shift_endtime}
                    isDisabled={hasTimeRegistration(shift.shift_sequence, 'end_time')}
                  />
                </span>
              </div>
            </React.Fragment>
          ))}

          {/* {validationErrors.timings?.total && (
            <div className="text-danger mt-1 small">
              {validationErrors.timings.total}
            </div>
          )} */}

          <div className="col-lg-1 col-md-2 align-self-center">
            {daySchedule.length < 2 && !shouldDisableControls(daySchedule[0].shift_sequence) && (
              <div className="shift-cell d-flex align-items-center">
                <span
                  data-bs-toggle="tooltip"
                  title={t("Add")}
                  onClick={() => handleAddShift(date)}
                  className="cursor-pointer secondaryColorHoverEffect"
                  style={{ width: "100%" }}
                >
                  <Icon name="add" width={iconStyle} height={iconStyle} isIcon={true} />
                </span>
              </div>
            )}
            {daySchedule.length >= 2 && !shouldDisableControls(daySchedule[1].shift_sequence) && (
              <div className="shift-cell d-flex align-items-center">
                <span
                  data-bs-toggle="tooltip"
                  title={t("Remove")}
                  onClick={() => handleRemoveShift(date)}
                  className="cursor-pointer secondaryColorHoverEffect removeUploadedFile"
                  style={{ width: "100%" }}
                >
                  <Icon name="RemoveIcon" width={iconStyle} height={iconStyle} isIcon={true} />
                </span>
              </div>
            )}
          </div>

          <div className="col-lg-3 col-md-12 mt-3 mt-lg-0 text-end text-lg-start align-self-center">
            <span className="small">{t("Total Hrs: ")}</span>
            <span className="totalHours text-white poppins-medium ms-2">
              {daySchedule.reduce((total, shift) => {
                return total + (
                  shift.shift_starttime && shift.shift_endtime
                    ? calculateDuration(shift.shift_starttime, shift.shift_endtime)
                    : 0
                );
              }, 0).toFixed(2)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <ModalPopup
      show={showModal}
      handleClose={handleCloseModal}
      modalTitle={t("Edit planning") + " - " + formatDateDMY(employeeData.pdate)}
      className="editPlanning"
      modalBackgroundColor="#8571b9"
      buttonText={t("Update")}
      onNextClick={handleSave}
      iconFillColor="#8571b9"
    // disableNext={loadin}
    >
      <div className="row">
        <div className="col-12 mb-2">
          <InputTextFieldWithLabel
            value={employeeData.name}
            readOnly={true}
            name={t("Employee Name")}
            className="inputFieldColor"
            isDisabled
          />
        </div>
      </div>

      <div className="multiSelectWrapper">
        <div className="row">
          <div className="col-5">
            <SelectWithSearch
              search={true}
              options={pcData.map((pc: PcOptions) => ({
                label: `(${pc.pc_number}) ${pc.pc_name}`,
                value: pc.id,
              }))}
              value={
                selectedPc
                  ? {
                    label: `(${selectedPc.pc_number}) ${selectedPc.pc_name}`,
                    value: selectedPc.id,
                  }
                  : null
              }
              onChange={handlePcChange}
              placeHolder={t("Select PC")}
              name="pc"
              selectWrapperClassName="mb-2"
              isDisabled={true}
            />
          </div>

          <div className="col-4">
            {selectedPc && (
              <SelectWithSearch
                search={true}
                options={selectedPc.employee_types || []}
                value={
                  selectedPc.employee_types.find(
                    (type) => type.value === employeeData.employee_type_id
                  ) || {}
                }
                onChange={(value: any) => handleChange("employee_type", value)}
                placeHolder={t("Select Employee Type")}
                name="employee_type"
                selectWrapperClassName="mb-2"
                isDisabled={true}
              />
            )}
          </div>

          <div className="col-3">
            {selectedPc && (
              <input
                type="text"
                className="form-control border mb-2 selectPcHeight"
                value={employeeData?.salary?.replace(".", ",").replace(/\s/g, "")}
                disabled
              />
            )}
          </div>

          <div className="col-9">
            {selectedPc && (
              <SelectWithSearch
                search={true}
                options={[
                  ...(selectedPc?.categories?.flatMap(
                    (category) => category?.functions
                  ) || []),
                  ...(selectedPc?.functions || [])
                ]}
                value={
                  selectedPc.categories
                    ?.flatMap((category) => category.functions)
                    .find((func) => func.value === employeeData.function_id) ||
                  {}
                }
                onChange={(value: any) => handleChange("function", value)}
                placeHolder={t("Select Function")}
                name="function"
                selectWrapperClassName="mb-2"
              />
            )}
          </div>

          <div className="col-3">
            <input
              type="text"
              className="form-control inputFieldColor"
              value={new_salary.replace(".", ",").replace(/\s/g, "")}
              onChange={handleSalaryChange}
              placeholder={t("New Salary")}
            />
          </div>

          <div className="row">
            <div className="col-12">
              {shiftCells(employeeData.schedule, employeeData.pdate, employeeData.time_registration)}
            </div>
          </div>
        </div>
      </div>

      <ValidationMessages />
    </ModalPopup>
  );
};

export default translate(EditPlanningPopup);
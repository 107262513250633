import exp from "constants";

export const SERVICE_URL = process.env.REACT_APP_SERVICE_REGISTRY;
export const getRefreshToken = SERVICE_URL + 'get-refresh-token';
export const forgotPassword = "forgot-password";
export const FILE_UPLOAD = "v1/file/upload";
export const FILE_DELETE = "v1/delete/file";
export const FILE_DOWNLOAD = "v1/download/file";

// ***File url based on server ***
const app_env = process.env.REACT_APP_NODE_ENV;
let FILE_URL: string;
if (app_env === "production") {
  FILE_URL = "https://absolutecore-development-bucket.s3.eu-west-1.amazonaws.com";
} else {
  FILE_URL = "https://absolutecore-development-bucket.s3.eu-west-1.amazonaws.com";
}
export { FILE_URL };
//***  */

export const RESUME_PARSER = "api/v1/resume/parser";
export const GET_FORM_DATA = "getForm";
export const PHONE_CALL_URL = "https://absolutejobs-11617.3cx.be/webclient/#/call?phone="


// Identity Manager
export const GET_ROLES = "getRoles";
export const REGISTER = "registration";
export const GETLOCATION = "getLocation";
export const GET_REGISTER_FORM_DETAILS = "getRegisterFormDetails";
export const GET_USER = "getUserDetails";
export const GET_USERS = "getUsers";
export const UPDATE_USER = "updateUser";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
export const EMPLOYEE_INVITATION = "employee-invitation";
export const INVITATION_EXPIRY = "invitation-expiry";
export const LOGIN = "login";
export const LOGOUT = "logout";

export const CANDIDATE_STATUS = "updateCandidateStatus";

export const GET_PERMISSION = "getPermission";
export const UPDATE_PERMISSION = "updatePermission";
export const STORE_PERMISSION = "storePermission";
export const GET_PERMISSIONS = "getPermissions";
export const DELETE_PERMISSION = "deletePermission";

export const EDIT_ROLE = "editRole";
export const CLASS_BY_PERMISSIONS = "classificationsByPermissions";
export const CREATE_ROLE = "createRole";
export const DELETE_ROLE = "deleteRole";
export const DELETE_USER = "deleteUser";


// Central Data Management
export const MODULES = "getModules";
export const STORE_TEMPLATE = "storeTemplate";
export const GET_TEMPLATE = "getTemplate";
export const UPDATE_TEMPLATE = "updateTemplate";
export const GET_TEMPLATES = "getTemplates";
export const GET_APP_LANG = "get-app-lang";
export const GET_EMAIL_LOGS = "manageEmailLogs";
export const SEND_EMAIL = "sendEmail";

export const GET_ALL_APP_LANG = "get-all-app-langs-and-languages";
export const LINK_APP_LANG = "link-app-languages";
export const LOCALE_TARGETS = "locale-targets";
export const LOCALE_TARGETS_SEARCH = "locale-targets/search";
export const SAVE_TRANSLATION = "save-translation";
export const UPDATE_TRANSLATIONS = "update-translations";
export const LOCALE_SOURCES = "locale-sources";

export const GET_QUESTION_DETAILS = "getQuestionDetails";
export const STORE_QUESTIONS = "storeQuestions";
export const UPDATE_QUESTIONS = "updateQuestions";
export const MANAGE_QUESTIONS = "ManageQuestions";
export const GET_DOMAIN_FOR_FUNCTION = "getDomainForFunctionDetails";
export const GET_FUNCTION_FOR_DOMAIN = "getFunctionForDomainDetails";
export const DELETE_QUESTIONS = "deleteQuestions";
export const GET_SCREENING_DETAILS = "getScreeningDetails";
export const GET_QUESTIONS_BY_FUNCTIONS = "getQuestionsByFunctions";
export const STORE_SCREENING_INFO = "storeScreeningInfo";
export const GET_SCREENING_ASSESSMENT = "getScreeningAssesment";
export const MANAGE_SCREENING = "ManageScreening";

// Candidate-creation
export const GENERAL_DETAILS = "getGeneralDetails";
export const OTHER_DETAILS = "getOtherTabDetails";
export const JOBPROFILE_DETAILS = "getJobProfileDetails";
export const CANDIDATE_COMPETENCE = "getCandidateCompetence";
export const DOCUMENT_DETAILS = "getDocumentDetails";
export const REFERENCE_DETAILS = "getReferenceDetails";
export const STUDY_DETAILS = "getStudyDetails";
export const WORK_DETAILS = "getWorkDetails";
export const RESGISTER_CANDIDATE = "registerCandidate";
export const CANDIDATES = "getCandidates";
export const ARCHIVE_CANDIDATE = "archiveCandidate";
export const DELETE_USER_CC = "deleteUser";
export const REGISTER_CC = "registration";
export const UPDATE_USER_CC = "updateUser";
export const GET_OFFICE_FOR_CONSULTANT = "getOfficeForConsultant";
export const VALIDATION_FOR_CANDIDATE_FIELDS = "validationForCandidate";

//Comapny-creation
export const RESGISTER_COMPANY = "registerCompany";
export const COMPANY_DETAILS = "getCompanyDetails";
export const USERS_TO_IDM = "userToIdm";
export const MANAGE_COMPANY = "manageCompany";
export const COMPANY_CONTACTS = "companyContacts";
export const ARCHIVE_COMPANY = "archiveCompany";
export const LOCATION_DETAILS = "getLocationDetails";
export const ADD_LOCATION = "addLocation";
export const MANAGE_LOCATION = "manageLocation";
export const ARCHIVE_LOCATION = "archiveLocation";
export const VAT_DETAILS = "vat-details";
export const GET_USER_COMPANIES = "get-companies-by-user-id";

export const ADD_CHECKLIST = "addChecklist";
export const GET_CHECKLIST = "getChecklistDetails";
export const MANAGE_CHECKLIST = "manageChecklist";
export const CHECK_FOR_EMAIL_EXIST = "CheckForEmailExist";

// configuration and settings
export const FETCH_FUNCTION_DETAILS = "fetchFunctionDetails";
export const STORE_FUNCTIONS_TO_COUNTRY = "store-functions";
export const MANAGE_FUNCTIONS = "manageFunctions";
export const GET_WORK_TYPE_COEFFICIENT = "getWorkTypeCoefficient";
export const STORE_WORK_TYPE_COEFFICIENT = "storeWorkTypeCoefficient";
export const GET_NESTED_LOW_COFFICIENTS = "getNestedLowCoefficients";
export const MANAGE_LOW_COEFFICIENTS = "manageLowCoefficients";
export const UPDATE_LOW_COEFFICIENTS = "updateLowCoefficients";
export const STORE_CITY = "storeCity";
export const UPDATE_CITY = "updateCity";
export const GET_CITY_DETAILS = "getCityDetails";
export const GET_CITIES_DETAILS = "getCitiesDetails";
export const GET_REGION_FOR_COUNTRIES = "getRegionForCountryDetails";
export const GET_COUNTRIES_FOR_REGION = "getCountryForRegionDetails";
export const GET_NESTED_INFLATIONS = "getNestedInflations";
export const SAVE_INFLATIONS = "saveInflations";
export const GET_INFLATIONS = "getInflations";
export const GET_SSN = "getSSN";
export const CONF_SETTINGS_ADD = "confSettingsAdd";
export const CONF_SETTINGS_EDIT = "confSettingsEdit";
export const CONF_SETTINGS_UPDATE = "confSettingsUpdate";
export const CONF_SETTINGS_GET = "confSettingsGet";
export const CONF_SETTINGS_DELETE = "confSettingsDelete";

// cooperation agreement
export const GET_AGREEMENT_DETAILS = "get-company-cooperation-details";
export const CREATE_AGREEMENT = "storeAgreementDetails";
export const GET_PC_FUNCTIONS = "getPcFunctions";
export const MANAGE_COOPERATION_AGREEMENT = "manageAgreement";
export const UPDATE_AGREEMENT_DETAILS = "update-company-cooperation-details";
export const GET_SALES_OPTIONS = "get-offices-and-sales";
export const GET_COEFFICIENTS_AND_DEVIATIONS = "fetch-coefficients-and-deviations";
export const GET_SALARY_BENEFITS = "fetch-salary-benefits";

// vacancies
export const MANAGE_VACANCY = "manageVacancies";
export const DELETE_VACANCY = "deleteVacancy";
export const VACANCY_DETAILS = "getVacancyDetails";
export const STORE_VACANCY = "storeVacancyDetails";
export const COMPANY_RELATED_DETAILS = "getCompanyRelatedDetails";
export const GET_COMPETENCES_BY_TEMPLATE_ID = "getCompetencesByTemplateId";

//offices
export const MANAGE_OFFICES = "manageOffices";
export const GET_OFFICE_DETAILS = "getOfficeDetails";
export const STORE_OFFICE_DETAILS = "storeOfficeDetails";

export const DELETE_PROFILE_PIC = "updateFileUploadDataInIdm";
export const CREATE_TODO = "createTodo";
export const CREATE_PRESENTATION_TODO = "createPresentationTodo";
export const MANUAL_MATCHING_SAVE = "saveManualMatching";

// paritair committee
export const MANAGE_PC = "getParitairCommittees";

//Matching list
export const MATCHING_LIST = 'matchingList';
export const GET_CANDIDATE_PROFILE_CARD_DETAILS = 'getCandidateProfileCardDetails';
export const GET_FUNCTIONS_LIST = 'fetchFunctionsList';
export const GET_MANUAL_MATCHING_SUGGESTIONS = 'getManualMatchingSuggestions';
export const GET_VACANCY_FOR_COMPANY = 'getVacancyForCompany';

//signature
export const ADD_SIGNATURE = "addSignature";
export const GET_SIGNATURE = "getSignature";
export const CHECK_EMPLOYER_SIGN = "checkEmployerSign";
export const AUTHENTICATE_EMPLOYER = "authenticateEmployer";
export const GET_CITY_AND_COUNTRY = "getCityAndCountryOptions";

//presentation
export const CREATE_PRESENTATION_DOCUMENT = "createPresentationDocument";

//budget
export const BUDGET_GET = "getBudgetByType";
export const BUDGET_ADD = "addBudgetByType";
export const BUDGET_EDIT = "editBudgetByType";
export const BUDGET_UPDATE = "updateBudgetByType";
export const BUDGET_DELETE = "deleteBudgetByType";

//target
export const TARGET_GET = "getTargetByType";
export const TARGET_ADD = "addTargetByType";
export const TARGET_EDIT = "editTargetByType";
export const TARGET_UPDATE = "updateTargetByType";
export const TARGET_DELETE = "deleteTargetByType";

//dashboard
export const GET_DASHBOARD = "getDashboardByType";
export const GET_USER_PERMISSIONS = "get-user-permission";

export const GET_COMPANY_RESPONSIBLE_PERSON = "getCompanyResponsiblePerson";
export const ASSIGN_TODO = "assignTodo";
export const GET_TODOS = "getTodos";
export const UPDATE_TODO = "updateTodo";
export const GET_PRE_INTERVIEW_DETAILS = "getPreInterviewDetails";
export const STORE_PRE_INTERVIEW_DETAILS = "storePreInterviewDetails";
export const CANDIDATE_CENTRAL = "getCandidateCentralDetails";

export const GET_JOB_APPLICATIONS_DETAILS = "getJobApplicationsDetails"; //for manage page all records
export const GET_JOB_APPLICATION_DETAILS = "getJobApplicationDetails"; //for view page single record
export const UPDATE_OR_CREATE_JOB_APPLICATION =
  "updateOrCreateJobApplicationDetails";

//task-determiner
export const GET_FOLLOW_UPS = "getFollowUps";
export const EDIT_FOLLOW_UP = "editFollowUp";
export const STORE_FOLLOW_UP = "storeFollowUp";
export const CLOSE_FOLLOW_UP = "closeFollowUp";
export const FETCH_FOLLOWUP_INFO = "fetchFollowUpInfo";

//auto-search
export const GET_SEARCH_VALUES = "autoSearch";

export const GET_COMPANY = "getCompany";
export const VERIFY_VAT_NUMBER = "verifyVatNumber";

//login-details
export const GET_LOGIN_DETAILS = "getLoginDetails";
export const GET_USER_LOGIN_DETAILS = "getUserLoginDetails";

//check for email or ssn existence
export const CHECK_FOR_SSN_OR_EMAIL_EXISTANCE = "checkForSSNorEmailExistance";

export const DELETE_COMPANY_CONTACT = 'deleteCompanyContact';
export const STORE_PAGE_CONTENT = "storePageContent";
export const GET_PAGE_CONTENT = "getPageContent";
export const MANAGE_PAGE_CONTENT = "managePageContents";
export const ACCEPT_TERMS_AND_CONDITIONS = 'accpetTermsAndConditions';
export const CREATE_CONTRACT_TODO = "createContractTodo";

//Employer
export const CREATE_EMPLOYER = "create-employer";
export const GET_EMPLOYER = "get-employer";
export const REGISTER_COUNTRY_LIST = "register-country-list";
export const REGISTER_SETTINGS_LIST = "register-settings-list";
export const REGISTER_VAT_DETAILS = "register-vat-details";
export const REGISTER_EMPLOYER = "register-employer";
export const UPDATE_CURRENT_COMPANY_ID = "update-current-company-id";



// for employee types
export const EMPLOYEE_TYPES = "employee-types";
// export const EMPLOYEE_TYPE_FETCH = "fetchEmployeeType";

// export const EMPLOYEE_TYPE_DELETE = "deleteEmployeeType";
// export const EMPLOYEE_TYPE_EDIT = "editEmployeeType";

//absoluteCore
export const EMAIL_TEMPLATES = "email-templates"
export const ROLES = "roles";
export const CLASS_BY_PERMISSION = "permissions"
export const CHANGE_PERMISSIONS = "change-permissions";

// category related
export const STORE_CATEGORY = "store-category";      // completed
export const UPDATE_CATEGORY = "update-category";   // completed
export const GET_CATEGORY = "fetch-category";     // completed

// function related
export const STORE_FUNCTION = "store-function";   // completed
export const UPDATE_FUNCTION = "update-function";  // completed
export const GET_FUNCTION = "fetch-function";   // completed


// pc related
export const ADD_PC = "addPc";
export const UPDATE_PC = "update-pc";
export const GET_PC_DETAILS_BY_UNIQUE_ID = "get-only-pc-details";       // edit icon   completed
export const GET_ALL_PC_DETAILS_BY_UNIQUE_ID = "manage-pc-edit-overview-details";  // completed --> for pc tree


export const FETCH_EMPLOYEE_TYPES_LIST = "get-employee-types-list";   // related to employees edit option
export const FETCH_PC_EMPLOYEES = "get-pc-employees";  // related to employee edit option
export const STORE_PC_EMPLOYEES = "store-pc-employees"; // related to employee edit

// for manage-categories page
export const MANAGE_CATEGORIES_GET = "manage-categories-get";    // completed
export const MANAGE_CATEGORIES_DELETE = "manage-categories-delete";  // completed

// for manage-functions page
export const MANAGE_FUNCTIONS_GET = "manage-functions-get";     // completed
export const MANAGE_FUNCTIONS_DELETE = "manage-functions-delete";  // completed

// for flex salary
export const FETCH_FLEX_SALARY = "fetch-flex-salary";
export const UPDATE_FLEX_SALARY = "update-flex-salary";


// for manage-pc page
export const MANAGE_PC_OVERVIEW_DETAILS = "manage-pc-get-all-pcs";   // completed  --> to fetch all pc's
// export const EDIT_GET_PC = "get-complete-pc";

// for add-pc
export const GET_AGE = "get-pc-ages";
export const UPDATE_PC_AGE = "update-pc-ages";
export const ADD_PC_AGE = "addPcAge";

//shifts
// for salary benifits

export const GET_SALARY_BENIFITS = 'get-salary-benifits';
export const GET_SALARY_BENEFITS_SINGLE_DATA = 'get-salary-benefits-single-data';
export const STORE_SALARY_BENIFITS = 'store-salary-benifits';
export const MANAGE_SALARY_BENEFITS = 'manage-salary-benefits';

export const GET_PC_END_HOURS = 'pc-ages-end-hours';
export const STORE_PC_END_HOURS = 'store-pc-ages-end-hours';

export const SHIFTS = "shifts";


export const DELETE_COEFFICIENT_TYPE = 'delete-coefficient-type';
export const FETCH_COEFFICIENT_TYPES = 'fetch-coefficient-types';
export const ADD_COEFFICIENT_TYPES = 'add-coefficient-types';
export const EDIT_COEFFICIENT_TYPES = 'update-coefficient-types';


export const FETCH_COEFFICIENT_PCS = 'get-all-coefficient-pcs';      // fetching all coefficient pcs
export const DELETE_COEFFICIENT_PCS = 'deleteCoefficientPc';      // delete coefficient pc


export const FETCH_ALL_EMP_COEFF_VALUES = 'get-coefficient-employee-values';
export const SAVE_PC_LINKING_DATA = 'save-all-pc-linking-data';

// holiday Occasions
export const FETCH_ALL_HOLIDAY_OCCASIONS = 'fetch-all-holiday-occasions';
export const UPDATE_HOLIDAY_OCCASION = 'update-holiday-occasion';
export const DELETE_HOLIDAY_OCCASION = 'delete-holiday-occasion';
export const ADD_HOLIDAY_OCCASION = 'add-holiday-occasions';

// holidays
export const FETCH_ALL_HOLIDAYS = 'fetch-all-holidays';
export const DELETE_HOLIDAY = 'delete-holiday';
export const GET_HOLIDAY_TYPES = 'get-holiday-types';
export const STORE_HOLIDAYS = 'store-holidays';
export const UPDATE_HOLIDAY = 'update-holiday';

//Countries
export const GET_COUNTRIES = 'get-countries';

//History locations


//companies by userid
export const GET_COMPANIES_BY_UID = "get-companies-by-user-id";

//Invoices
export const GET_ALL_INVOICES = 'get-invoices-by-enterpriseid';
export const GET_INVOICE = 'get-invoice';
export const FETCH_ALL_INVOICES = 'fetch-all-invoices';

//Company Documents
export const GET_COMPANY_DOCUMENTS="get-company-documents";
export const UPLOAD_COMPANY_DOCUMENTS="upload-company-documents";
export const FETCH_FILE="fetch-file";

//Country-list
export const COUNTRY_LIST = 'get-countries';
export const SETTINGS_LIST = 'settings-list';

//Get PC's list
export const PC_LIST = 'get-pcs';
export const PC_LIST_WITH_TYPE = 'get-pcs-with-employee-type';

//Get PC's list by Company Id
export const PC_LIST_BY_COMPANY_ID = 'get-pcs-by-company-id';

//Get Functions By PC
export const GET_FUNCTIONS_BY_PC = 'get-functions-by-pc';

//Get Employee Types By PC
export const GET_EMPLOYEE_TYPES_BY_PC = 'get-employeetypes-by-pc';

//ssnrDetails
export const SSNR_DETAILS = 'ssnr-details';
export const SSNR_DETAILS_WITHOUT_LOGIN = 'register-ssnr-details';


// Verify Email - Employee Registeration
export const VERIFY_EMAIL = 'verify-email';
export const VERIFY_EMAIL_WITHOUT_LOGIN = 'register-verify-email';

//Employee Registeration
export const EMPLOYEE_REGISTRATION_WITHOUT_LOGIN = 'person';
export const EMPLOYEE_REGISTRATION = 'employee';

//Update Employee
export const UPDATE_EMPLOYEE = 'employee';
export const OPTIONS_LIST_BY_GROUP_OF_COMPANIES = 'get-pcs-info-by-group-of-companies';
export const UPDATE_EMPLOYEE_STATUS = 'activate-deactivate';


// Get calculated group of Salaries
export const CALCULATED_GROUP_OF_MINIMUM_SALARIES = 'get-salaries';

// crew planner

export const CREATE_TRADE = 'tradeStore';
export const GET_HISTORYS = 'historyType';
export const GET_TRADES = 'getActiveTrades';
export const TRADES_FOLLOW_UP = "followupStore";
export const GET_VAT_DETAILS = "getVatDetails";
export const UPDATE_FARE_DATE = "updateTradeDetails";
export const GET_OFFICES = "getOffices";
export const GET_SALES_AGENTS = "getSalesAgents";
export const MANAGE_ALL_TRADES = 'getAllTrades';
export const MANAGE_FOLLOW_UPS = 'getFollowups'
export const UPDATE_OR_CREATE_FOLLOW_UP_TYPES = 'updatecreatefollowups'
export const UPDATE_OR_SALES_TYPES = 'updatecreatesales'
export const GET_ACTIVE_FOLLOW_UPS = 'followups';
export const GET_ACTIVE_SALES = 'getactivesales';
export const DEFAULT_FOLLOW_UP = 'defaultfollowup';
export const TRADE_DATA = 'getalltradedata';



//Locations
export const LOCATIONS = 'locations';
export const FAV_LOCATION = 'fav-location';
export const LOCATIONS_BY_COMPANY = 'locations-by-company';
export const GET_LOCATION_MANAGERS = 'get-location-managers';
export const REMOVE_LOCATION_MANAGER = 'remove-location-managers';
export const ADD_LOCATION_MANAGERS = 'add-location-managers';

//Projects
export const PROJECTS = 'project';
export const PROJECTS_BY_COMPANY = 'projects-by-company';
export const FAV_PROJECT = 'fav-project';
//CostCenters
export const COST_CENTERS = 'cost-centers';
export const COST_CENTERS_BY_COMPANY = 'cost-centers-by-company'
export const FAV_COSTCENTER = 'fav-costcenter';


export const FETCH_COEFFICIENT_DEVIATIONS = 'get-all-coefficient-deviations';
export const FETCH_COEFFICIENT_DEVIATION = 'fetch-coefficient-deviation';
export const UPDATE_COEFFICIENT_DEVIATION = 'update-coefficient-deviation';

export const FETCH_COEFFICIENT_DEVIATION_PCS = 'get-all-coefficient-deviation-pcs';   // fetching all coefficient deviation pcs
export const DELETE_COEFFICIENT_DEVIATION_PCS = 'deleteCoefficientDeviationPc';   // delete  coefficient deviation pc

export const STORE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING = 'storeCoefficientDeviationEmployeeTypeLinking';
export const UPDATE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING = 'update-coefficient-deviation-employee-type-linking';
export const FETCH_COEFFICIENT_DEVIATION_EMPLOYEE_LINKING_PER_PC = 'fetch-coefficient-deviation-employee-type-linking';  // per pc



//Planning

export const PLANNING_OVERVIEW = 'planning-overview'
export const CREATE_PLANNING = 'create-planning'

export const COMPANY_EMPLOYEES = 'company-employees'
export const COMPANY_EMPLOYEES_OPTIONS = 'employee-by-company-options'
export const PLANNING_LAYOUT = 'planning-layout'
export const CANCEL_PLANNING = 'cancel-planning'
export const EDIT_PLANNING = 'edit-planning'
export const EMPLOYEE_SALARY = 'get-employee-salary'
export const GET_PC_BY_COMPANY = 'get-pcs-by-companyid';
export const GET_COST_CENTER_BY_COMPANY = 'cost-centers-by-company';
export const GET_PROJECTS_BY_COMPANY = 'projects-by-company';



// report issue
export const REPORT_ISSUE = 'report-issue';

//qr code
export const QR_CODE = 'qrcode-company'
export const QR_CODE_REFRESH = 'qrcode-refresh'

//time registration 
export const GET_TODAY_PLANNINGS = 'get-today-plannings';
export const GET_COOP_AGG_STATUS = 'coop-agg-status';
export const EMPLOYER_TIME_REGISTRATION = 'save-time-registration';
export const CREATE_QR_CODE = 'qrcode-create'

// manage administrators
export const ADMIN_DETAILS_BY_COMPANY = 'get-admin-details-by-company'
export const EMPLOYEE_ADD_ADMIN = 'employee-add-admin'
export const EMPLOYEE_REMOVE_ADMIN = 'employee-remove-admin'

// invite employee
export const GET_EMPLOYEE_BY_EMAIL = 'get-employee-by-email';
export const INVITE_EMPLOYEE = 'invite-employee';

//for encodage

export const ENCODAGE_OVERVIEW = 'encodage-overview';
export const ENCODAGE_CREATE = 'create-encodage';
export const ENCODAGE_PRINT = 'import-encodage';

export const TRANSLATION_BY_LANGUAGE = 'translations-by-language';

//Contracts
export const GET_EMPLOYER_CONTRACTS = 'get-employer-contracts';
export const GET_EMPLOYEE_CONTRACTS = 'get-employee-contracts';
// coop/coop-agg-status/
import React from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../../Dashboard/services/CompanyInterface";
import { t, translate } from "../translation/Translation";
import { REACT_APP_CDM_SERVICE } from "../../../Constants";

const ManageAllUsers = () => {
    const token = useSelector((state: ReduxState) => state?.auth.token);
    const backendUrl = `${REACT_APP_CDM_SERVICE}` + `/admin/users-lists?token=${token}`;
    window.location.href = backendUrl;
};

export default translate(ManageAllUsers);

import { MyAccount } from "./MyAccountInterface";

export const initialState: MyAccount = {
    formValues: {
        id: null,
        is_national: false,
        first_name: "",
        last_name: "",
        function: "",
        email: "",
        phone_number: "",
        security: {
            password: "",
            confirm_password: ""
        },
        gender: null,
        date_of_birth: "",
        language_id: null,
        address: {
            residence: {
                name: null,
                street: "",
                number: "",
                addition: null,
                postal_code: "",
                box: null,
                city: "",
                country: null,
                latitude: "",
                longitude: ""
            }
        },
        communication: {
            contracts_email: "",
            invoice_email: "",
            planning_contact_person: "",
            planning_contact_number: "",
        },
        privacy: {
            via_phone: false,
            via_email: false
        },
        profile_picture_url: "",
        profile_picture_id: null,
        role: "employer",
        source: "web",
        status: "active",
        signature: "",
        extra_ref: "",
        profile: true,
        start_date: undefined,
    },
    showPassword: false,
    showConfirmPassword: false,
    loading: false,
    editing: false,
    errors: {},
    ibancountries: [] as any,
};

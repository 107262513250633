import React, { useEffect, useState } from "react";
import LabelField from "../common/atoms/LabelField";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import OverFlowWrapper from "../common/OverFlowWrapper";
import DocumentsTable from "./DocumentsTable";
import PdfViewer from "../common/molecules/PdfViewer";
import "./css/Documents.css";
import { GET_COMPANY_DOCUMENTS, UPLOAD_COMPANY_DOCUMENTS } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { useSelector } from "react-redux";
import { selectCurrentCompany } from "../../features/auth/AuthSlice";
import CommonServices from "../../services/common/CommonServices";
import Button from "../common/atoms/Button";
import { FaSyncAlt } from "react-icons/fa";
import CustomNotify from "../common/atoms/CustomNotify";
import { translate } from "../CentralDataMangement/translation/Translation";

interface Document {
  id: number;
  name: string;
  viewLink: string;
  downloadLink: string;
}

const DocumentsOverview = () => {
  const companyid = useSelector(selectCurrentCompany)?.id;
  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [showPdf, setShowPdf] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);

  const fetchCompanyDocuments = async (companyid: number) => {
    if (!companyid) return;
    try {
      const url = `${GET_COMPANY_DOCUMENTS}/${companyid}`;
      const response = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      if (response?.status === "success" && Array?.isArray(response.data)) {
        const formattedDocuments = response?.data?.map?.((doc: any) => {
          const nameParts = doc?.file_name?.split?.("_")?.slice(2)?.join("_"); // Removing timestamp prefix
          return {
            id: doc?.file_id,
            name: nameParts,
            viewLink: doc?.file_url,
            downloadLink: doc?.file_url,
          };
        });

        setDocuments(formattedDocuments);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching company documents:", error);
    }
  };

  const syncDocuments = async () => {
    if (!companyid) return;
    try {
      const url = `${UPLOAD_COMPANY_DOCUMENTS}`;
      await ApiCall?.service(
        url,
        "POST",
        {
          "company_ids": [companyid]
        },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
      );
      CustomNotify({
        type: "success",
        message:
          t("Company documents synced successfully"),
      });
      fetchCompanyDocuments?.(companyid);
    } catch (error) {
      console.error("Error fetching company documents:", error);
    }
  };

  useEffect(() => {
    fetchCompanyDocuments?.(companyid);
  }, [companyid]);

  const viewDocument = (documentId: number) => {
    const document = documents?.find((doc) => doc?.id === documentId);
    if (document) {
      setShowPdf(document?.viewLink);
      setDocumentName(document?.name);
    } else {
      console.error("Document not found with the given ID.");
    }
  };

  const downloadDocument = async (documentId: number) => {
    try {
      const document = documents?.find?.((doc) => doc?.id === documentId);
      if (document) {
        await CommonServices?.downloadFile?.(document?.downloadLink, document?.name, "Document");
      } else {
        console.error("Document not found with the given ID.");
      }
    } catch (error) {
      console.error("Error downloading the document:", error);
    }
  };



  return (
    <>
      <Header headerName={t("Documents")} headerClassName="myAccountCardTitle" />
      <div className="row">
        <div className="col-12 mb-3 mb-lg-0">
          {showPdf ? (
            <div className="contentBg">
              <OverFlowWrapper className="invoiceLeftWrapper">
                <PdfViewer fileUrl={showPdf} setShowPdf={setShowPdf} fileNumber={documentName} fileType="Document" />
              </OverFlowWrapper>
            </div>
          ) : (
            <div className="contentBg">
              <div className="d-flex justify-content-between">
                <LabelField
                  title={t("Documents")}
                  className="inVoiceHeader colorPrimary poppins-semibold mb-3"
                  customStyle={{ paddingLeft: "0" }}
                />
                <Button
                  title={<FaSyncAlt />}
                  type="submit"
                  className="syncSearchBtn searchBtn px-0 mb-3"
                  handleClick={syncDocuments}
                  toolTipName="Sync Documents"
                />
              </div>
              <OverFlowWrapper className="DocumentsLeftWRapper scrollBar">
                {documents?.length > 0 ? (
                  <div className="mb-3">
                    <DocumentsTable documents={documents} viewDocument={viewDocument} downloadDocument={downloadDocument} />
                  </div>
                ) : (
                  <p>{t("No documents available")}</p>
                )}
              </OverFlowWrapper>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default translate(DocumentsOverview);

// TimeRegistrationOverviewHelper.jsx

import { calculateEncodageTotalTime } from "../Planning/Services/PlanningTimingHelper";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

export const useTimeRegistrationStyles = () => {
  const widthStylesColOne = useResponsiveValue("100%", "50%", "9%");
  const widthStylesColTwo = useResponsiveValue("100%", "100%", "89%");
  const iconStyle = useResponsiveValue("3vw", "2.5vw", "0.8vw");

  return {
    widthStylesColOne,
    widthStylesColTwo,
    iconStyle,
    iconProps: {
      width: iconStyle,
      height: iconStyle,
      isIcon: true,
    }
  };
};

const TimeRegistrationOverviewHelper = {
  // Get start and end date of the week
  getStartAndEndDate: (date) => {
    const dayOfWeek = date.getDay();
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - dayOfWeek);
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    return { startOfWeek, endOfWeek };
  },

  // Calculate total validated hours for multiple employees
  getTotalValidatedHours: (closedEmployees) => {
    let totalValidatedHours = 0;

    closedEmployees?.forEach((employee) => {
      employee.schedule &&
        Object.values(employee.schedule).forEach(({ encodage }) => {
          if (encodage?.length > 0) {
            totalValidatedHours += calculateEncodageTotalTime(encodage, 2);
          }
        });
    });

    const hours = Math.floor(totalValidatedHours);
    const minutes = Math.round((totalValidatedHours - hours) * 60);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  },

  // Calculate total validated hours for a single employee
  getTotalValidatedHoursPerEmployee: (employee) => {
    let totalHours = 0;

    employee.schedule &&
      Object.values(employee.schedule).forEach(({ encodage }) => {
        if (encodage?.length > 0) {
          totalHours += calculateEncodageTotalTime(encodage, 2);
        }
      });

    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
    return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
  },

  // Format employee name for display
  formatEmployeeName: (name) => {
    const [firstName, lastName = ""] = name.split(" ");
    return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
  },



  // filter open employees  and closed employees based on the data you sent 

  filterEmployees: (employees, name) => {
    const filteredEmployees = employees.filter((employee) =>
      employee.employee_name.toLowerCase().includes(name.toLowerCase())
    );

    return filteredEmployees;
  },


  headings: () => {
    const { widthStylesColOne, widthStylesColTwo, iconProps } = useTimeRegistrationStyles;


    return (
      <div style={{ paddingLeft: "0.2vw" }}>
        <div style={{ width: widthStylesColOne }}></div>
        <div style={{ width: widthStylesColTwo }} className="ms-auto">
          <table className="table table-borderless timeRegistrationTableHeader mb-0">
            <thead>
              <tr>
                <th></th>
                {["Planned hours", "Clocked-in hours", "Effective hours worked"].map(
                  (category) => (
                    <th key={category} colSpan={3} className="text-center categorySection">
                      <div className="d-flex align-items-center justify-content-center">
                        {category !== "Effective hours worked" && (
                          <Icon name="lockIcon" {...iconProps} fillColor="#87abb8" />
                        )}
                        <span className="ms-2">{t(category)}</span>
                      </div>
                    </th>
                  )
                )}
                <th></th>
              </tr>
            </thead>
          </table>
        </div>
      </div>
    );
  }

};

export default TimeRegistrationOverviewHelper;



// // for week selection
// const getStartAndEndDate = (date: Date) => {
//   const dayOfWeek = date.getDay();
//   const startOfWeek = new Date(
//     date.getTime() - dayOfWeek * 24 * 60 * 60 * 1000
//   );
//   const endOfWeek = new Date(startOfWeek.getTime() + 6 * 24 * 60 * 60 * 1000);

//   return { startOfWeek, endOfWeek };
// };
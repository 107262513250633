import React, { useEffect, useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import CheckBoxField from "../common/atoms/CheckBoxField";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import ParitiarCommitteeSection from "./ParitairCommiteeSection";
import Navigation from "./Navigation";
import { AbsoluteyouAgentProps, Coefficient, Data, Deviation, Option, PCtype } from "./Interfaces";
import RadioField from "../common/atoms/RadioField";
import { convertCompanyPcAndEmployeeTypesToRequest, validateSection } from "./Service";
import { AbsoluteyouAgentMandatory } from "./State";
import { getNestedValue } from "./CompanyInformation";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { GET_COEFFICIENTS_AND_DEVIATIONS } from "../../routes/ApiEndpoints";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";


interface CoefficientChange {
  [pcNumber: string]: {
    [employeeType: string]: {
      [coeffName: string]: string;
    };
  };
}

const AbsoluteyouAgent: React.FC<AbsoluteyouAgentProps> = ({
  agreementData,
  handleChange,
  formState,
  section,
  handleDateFormat,
  errors,
  updateErrors,
  handleSelectChange,
  handleNext,
  handleSaveAsDraftClick,
  handleCheckboxChange,
  addParitiarComitee,
  deleteParitiarComitee
}) => {
  const [isLastTab, setIsLastTab] = useState(false);
  const [data, setData] = useState<Data>({
    coefficients: {},
    coefficient_deviations: {}
  });
  const handlenext = () => {
    const error = validateSection?.(section, agreementData, AbsoluteyouAgentMandatory, []);
    updateErrors?.(section, error);
    if (Object?.keys(error)?.length > 0) {
      return;
    } else {
      handleNext();
    }
  };

  const handlePrevious = () => {
    console.log("Previous clicked");
  };

  const fetchCoefficients = async () => {
    const url = GET_COEFFICIENTS_AND_DEVIATIONS;
    const obj = convertCompanyPcAndEmployeeTypesToRequest?.(
      formState?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types ?? []
    );
    if (obj && Object.keys(obj).length > 0) {
      try {
        const result = await ApiCall?.service?.(url, "POST", obj, false, CENTRAL_DATA_MANAGEMENT_MICROSERVICE);
        setData(result?.data);
      } catch (error) {
        console.error("Error fetching agreement data:", error);
      }
    }
  };

  // console.log(coefficientChanges);

  const handleCoefficientChange = (
    pcNumber: string,
    employeeType: string,
    name: string,
    newValue: string
  ) => {
    setData((prevData) => {
      const updatedCoefficients = { ...prevData.coefficients };
      const updatedDeviations = { ...prevData.coefficient_deviations };

      // Update coefficients only if they exist
      if (updatedCoefficients[pcNumber]?.[employeeType]) {
        updatedCoefficients[pcNumber][employeeType] = updatedCoefficients[pcNumber][employeeType].map((coeff) =>
          coeff.coeff_name === name ? { ...coeff, coeff_value: newValue } : coeff
        );
      }

      // Update deviations only if they exist
      if (updatedDeviations[pcNumber]?.[employeeType]) {
        updatedDeviations[pcNumber][employeeType] = updatedDeviations[pcNumber][employeeType].map((deviation) =>
          deviation.deviation_name === name ? { ...deviation, coeff_value: newValue } : deviation
        );
      }

      return {
        ...prevData,
        coefficients: updatedCoefficients,
        coefficient_deviations: updatedDeviations
      };
    });

  };

  useEffect(() => {
    fetchCoefficients();
  }, [formState?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types]);

  const renderContent = (
    coefficientsData: Record<string, Record<string, Coefficient[]>>,
    deviationsData: Record<string, Record<string, Deviation[]>>
  ) => {
    // Combine both coefficient and deviation data into one single iteration
    return Object.keys(coefficientsData)?.map?.((pcNumber) => {
      const coefficientsDataRecord = coefficientsData?.[pcNumber] || {};
      const deviationsDataRecord = deviationsData?.[pcNumber] || {};

      // Render the Coefficients and Deviation sections together
      return (
        <div key={pcNumber}>
          <div className="mb-3 w-50">
            <h5 className="mb-1">
              <LabelField title={t("Select paritair commitee to link coefficients to employee types")} />
            </h5>
            <SelectWithSearch
              isMulti={false}
              isMandatory={true}
              search={true}
              options={[...(formState?.pcs?.workers ?? []), ...(formState?.pcs?.servants ?? [])]}
              value={formState?.pcs?.workers?.concat?.(formState?.pcs?.servants ?? [])
                .find((option: Option) => option?.value === Number(pcNumber)) || {}}
              onChange={() => { }}
              name="PC2"
              title={t("Paritair committee (PC) 2")}
              error=""
              isTranslate={true}
              isDisabled={true}
            />
          </div>

          {/* Render Coefficients */}
          <h5 className="mb-1">
            <LabelField title={t("Coefficients for paritair comite")} />
          </h5>
          <div className="row mb-5">
            <div className="col-4 pe-0">
              <LeftPart data={coefficientsDataRecord} />
            </div>
            <div className="col-8 ps-0">
              <RightPart data={coefficientsDataRecord} onValueChange={(employeeType: any, name: any, newValue: any) =>
                handleCoefficientChange(pcNumber, employeeType, name, newValue)
              } />
            </div>
          </div>

          {/* Render Deviations if any */}
          {Object.keys(deviationsDataRecord)?.length > 0 && (
            <>
              <h5 className="mb-1">
                <LabelField title={t("Link coefficients deviations to paritair comite")} />
              </h5>
              <div className="row mb-5">
                <div className="col-4 pe-0">
                  <LeftPart data={deviationsDataRecord} />
                </div>
                <div className="col-8 ps-0">
                  <RightPart data={deviationsDataRecord} onValueChange={(employeeType: any, name: any, newValue: any) =>
                    handleCoefficientChange(pcNumber, employeeType, name, newValue)
                  } />
                </div>
              </div>
            </>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-4">
          <div className="position-relative agreementStartDate">
            <Calender
              onChange={(date: Date | null) => {
                if (date) {
                  handleDateFormat?.(date, "start_date", section);
                }
              }}
              label={t("Start date of agreement")}
              selectedDate={agreementData?.start_date ? new Date(agreementData?.start_date) : null}
              name="start_date"
              error={getNestedValue(errors?.[section], "start_date") || ""}  // Fix error prop
              isDisabled={agreementData?.start_date ? true : false}
              isMandatory
            />
          </div>
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou consultant")}:`}
            title={t("AbsoluteYou consultant")}
            name="sales_agent_id"
            placeHolder={t("Select")}
            search
            options={formState?.sales || []}
            value={formState?.sales?.find(
              (option: Option | null) => option?.value === agreementData?.sales_agent_id
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange?.(selectedOption, "sales_agent_id", section);
            }}
            isMulti={false}
            isDisabled={agreementData?.sales_agent_id ? true : false}
            className="select-field"
            error={getNestedValue(errors?.[section], "sales_agent_id") || ""}
            isTranslate
            isMandatory
          />
        </div>
        <div className="col-4 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={`${t("AbsoluteYou office number")}:`}
            title={t("AbsoluteYou office number")}
            name="FirstStep.office_id"
            placeHolder={t("Select")}
            search
            options={formState?.offices?.[agreementData?.sales_agent_id] || []}
            value={formState?.offices?.[agreementData?.sales_agent_id]?.find(
              (option: Option) => option?.value === agreementData?.FirstStep?.office_id
            ) || null}
            onChange={(selectedOption: Option) => {
              handleSelectChange?.(selectedOption, "FirstStep.office_id", section);
            }}
            isMulti={false}
            isDisabled={agreementData?.FirstStep?.office_id ? true : false}
            className="select-field"
            error={getNestedValue(errors?.[section], "FirstStep.office_id") || ""}
            isTranslate
            isMandatory
          />
        </div>
        <div className="col-12 my-3">
          <CheckBoxField
            name="FirstStep.add_project"
            label={
              t("Activate 'Add project' for the employer in the planning") + "."
            }
            className="my-checkbox"
            isChecked={agreementData?.FirstStep?.add_project}
            onChangeHandler={(e) => handleCheckboxChange?.(e, section)}
            id="activateAddProject"
            lineHeight="1vw"
          />
        </div>
        <div className="col-6 mb-3">
          <div className="mb-2">
            <LabelField title={t("In which language Werkpostfiche should be present")} />
          </div>
          <RadioField
            name="FirstStep.wp_fische_language"
            label={t("Dutch")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.wp_fische_language) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="yesLabourRegulations"
            value={1}
            containerClassName="companyLanguage me-4 d-inline-flex"
          />

          <RadioField
            name="FirstStep.wp_fische_language"
            label={t("French")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.wp_fische_language) === 2}
            handleChange={(e) => handleChange(e, section)}
            id="noLabourRegulations"
            value={2}
            containerClassName="companyLanguage d-inline-flex"
          />
        </div>
        <div className="col-6 mb-3">
          <div className="mb-2">
            <LabelField title={t("Who will sign the Werkpostfiche")} />
          </div>
          <RadioField
            name="FirstStep.who_will_sign_the_wpf"
            label={t("Employer")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.who_will_sign_the_wpf) === 0}
            handleChange={(e) => handleChange(e, section)}
            id="yesLabourRegulations"
            value={0}
            containerClassName="companyLanguage me-4 d-inline-flex"
          />

          <RadioField
            name="FirstStep.who_will_sign_the_wpf"
            label={t("Co-employer")}
            className="radioBtn"
            ischecked={Number(agreementData?.FirstStep?.who_will_sign_the_wpf) === 1}
            handleChange={(e) => handleChange(e, section)}
            id="noLabourRegulations"
            value={1}
            containerClassName="companyLanguage d-inline-flex"
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={formState?.pcs?.workers ?? []}
            selectedValue={null}
            selectedEmpType={[]}
            state={formState}
            addParitiarComitee={addParitiarComitee}
            pcDeleteHandler={deleteParitiarComitee}
            isBlueCollar={true}
            isWhiteCollar={false}
          />
        </div>
        <div className="col-6 multiSelectWrapper">
          <ParitiarCommitteeSection
            optionsList={formState?.pcs?.servants ?? []}
            selectedValue={null}
            selectedEmpType={null}
            state={formState}
            addParitiarComitee={addParitiarComitee}
            pcDeleteHandler={deleteParitiarComitee}
            isBlueCollar={false}
            isWhiteCollar={true}
          />
        </div>
        <div className="col-12 absoluteAgentTable mt-4">
          {renderContent(data?.coefficients, data?.coefficient_deviations)}
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        // onPrevious={handlePrevious}
        onNext={handlenext}
        handleSaveAsDraftClick={handleSaveAsDraftClick}
      />
    </>
  );
};

export default AbsoluteyouAgent;

import React from "react";
import LabelField from "../../common/atoms/LabelField";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import InputTextfield from "../../common/atoms/InputTextField";
import { AuthState, DefaultFormState } from "../services/CompanyInterface";
import { translate } from "../../CentralDataMangement/translation/Translation";

interface UserDetailsFormProps {
  formState: DefaultFormState;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handlePasswordChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setFormState: React.Dispatch<React.SetStateAction<any>>;
  clearFieldError: (fieldName: string) => void;
  inputFieldSharedProps: any;
  t: (text: string) => string;
  user: AuthState;
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = ({
  formState,
  handleChange,
  setFormState,
  handlePasswordChange,
  clearFieldError,
  inputFieldSharedProps,
  t,
  user,
}) => {
  const handleShowPasswordToggle = () => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      showPassword: !prevState?.showPassword,
    }));
  };

  const handleShowConfirmPasswordToggle = () => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      showConfirmPassword: !prevState?.showConfirmPassword,
    }));
  };
  return (
    <div>
      <div className="row mb-2" onClick={() => clearFieldError("search_name")}>
        <InputTextFieldWithLabel
          label={t("Company name") + ":"}
          type="text"
          value={formState?.formValues?.search_name}
          handleChange={handleChange}
          name={"search_name"}
          error={formState?.errors?.search_name}
          id="search_name"
          autoComplete="off"
          {...inputFieldSharedProps}
          isMandatory
        />
      </div>
      <div
        className="row mb-2"
        onClick={() => clearFieldError("official_name")}
      >
        <InputTextFieldWithLabel
          label={t("Official name") + ":"}
          type="text"
          value={formState?.formValues?.official_name}
          handleChange={handleChange}
          name={"official_name"}
          error={formState?.errors?.official_name}
          id="official_name"
          {...inputFieldSharedProps}
          isMandatory
          autoComplete="off"
        />
      </div>
      {!user?.token && (
        <>
          <div
            className="row mb-2"
            onClick={() => clearFieldError("first_name")}
          >
            <InputTextFieldWithLabel
              label={t("First name") + ":"}
              type="text"
              value={formState?.formValues?.first_name}
              handleChange={handleChange}
              name={"first_name"}
              error={formState?.errors?.first_name}
              id="first_name"
              {...inputFieldSharedProps}
              isMandatory
              autoComplete="off"
            />
          </div>
          <div
            className="row mb-2"
            onClick={() => clearFieldError("last_name")}
          >
            <InputTextFieldWithLabel
              label={t("Last name") + ":"}
              type="text"
              value={formState?.formValues?.last_name}
              handleChange={handleChange}
              name={"last_name"}
              error={formState?.errors?.last_name}
              id="last_name"
              {...inputFieldSharedProps}
              isMandatory
              autoComplete="off"
            />
          </div>
        </>
      )}
      <div className="row mb-2" onClick={() => clearFieldError("email")}>
        <InputTextFieldWithLabel
          label={t("Email address") + ":"}
          type="email"
          value={user?.token ? user?.email : formState?.formValues?.email}
          handleChange={handleChange}
          error={formState?.errors?.email}
          name={"email"}
          id="email"
          {...inputFieldSharedProps}
          isMandatory
          autoComplete="off"
          isDisabled={user?.token ? true : false}
        />
      </div>
      {!user?.token && (
        <>
          <div
            className="row mb-2"
            onClick={() => clearFieldError("phone_number")}
          >
            <InputTextFieldWithLabel
              label={t("Phone number") + ":"}
              type="text"
              value={formState?.formValues?.phone_number}
              handleChange={handleChange}
              error={formState?.errors?.phone_number}
              name={"phone_number"}
              id="phone_number"
              {...inputFieldSharedProps}
              isMandatory
              autoComplete="off"
            />
          </div>
          <div className="row mb-2">
            <div className="col-3 align-self-center">
              <LabelField
                title={t("Password") + ":"}
                className="label colorPrimary"
                isMandatory
              />
            </div>
            <div className="col-7 position-relative">
              <div className="row d-flex">
                <div
                  className="col-12 position-relative"
                  onClick={() => clearFieldError("password")}
                >
                  <InputTextfield
                    value={formState?.formValues?.security?.password}
                    type={formState?.showPassword ? "text" : "password"}
                    handleChange={handlePasswordChange}
                    error={formState?.errors?.password}
                    name="password"
                    id="security.password"
                    {...inputFieldSharedProps}
                    autoComplete="off"
                    isDisabled={user?.token ? true : false}
                    iconName={
                      formState?.showPassword ? "eyeSolid" : "eyeSlashSolid"
                    }
                    handleIcon={handleShowPasswordToggle}
                    isIcon={true}
                    width={"1vw"}
                    height={"1vw"}
                    inputClassWrapper="position-relative"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-3 align-self-center">
              <LabelField
                title={t("Confirm password") + ":"}
                className="label colorPrimary"
                isMandatory
              />
            </div>
            <div className="col-7 position-relative">
              <div className="row d-flex">
                <div
                  className="col-12 position-relative"
                  onClick={() => clearFieldError("confirm_password")}
                >
                  <InputTextfield
                    value={formState?.formValues?.security?.confirm_password}
                    type={formState?.showConfirmPassword ? "text" : "password"}
                    handleChange={handlePasswordChange}
                    error={formState?.errors?.confirm_password}
                    name="confirm_password"
                    id="security.confirm_password"
                    {...inputFieldSharedProps}
                    autoComplete="off"
                    isDisabled={user?.token ? true : false}
                    iconName={
                      formState?.showConfirmPassword
                        ? "eyeSolid"
                        : "eyeSlashSolid"
                    }
                    handleIcon={handleShowConfirmPasswordToggle}
                    isIcon={true}
                    width={"1vw"}
                    height={"1vw"}
                    inputClassWrapper="position-relative"
                    disableCopyPaste
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default translate(UserDetailsForm);

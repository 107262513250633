import React, { useCallback, useEffect, useRef, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import ModalPopup from "../common/atoms/ModalPopup";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";
import { Companies } from "../Qrcode/QRCodeDisplay";
import { formattedDate, createQrCode, fetchData } from "./utils/qrCodeUtils";

interface QrCodeModalProps {
    show: boolean;
    close: () => void;
    handleSubmit: () => void;
    companyId?: number;
}
interface Data {
    qrCodeUrl: string;
    createdOn: string;
}

const QrCodeModal: React.FC<QrCodeModalProps> = ({
    show,
    close,
    handleSubmit,
    companyId,
}) => {
    const user = useSelector(selectAuth);
    const userId = user?.userId;
    const companies: Companies[] = user?.companies;
    const company = companies?.find((item) => item?.id === Number(companyId));

    const companyName = company ? company.name : "Company not found";
    const [data, setData] = useState<Data>({ qrCodeUrl: "", createdOn: "" });

    const hasFetched = useRef(false); // Track if API has already been called

    const fetchAndSetData = useCallback(async () => {
        if (hasFetched.current) return;
        hasFetched.current = true;

        const result = await fetchData?.(companyId ?? null, userId, createQrCode);
        setData(result);
    }, [companyId, userId]);

    useEffect(() => {
        if (companyId && userId) {
            fetchAndSetData();
        }
    }, [fetchAndSetData, companyId, userId]);

    return (
        <div>
            <ModalPopup
                show={show}
                handleClose={close}
                modalTitle={t("QR code") + " - " + t("" + companyName + "")}
                modalBackgroundColor="#8571b9"
                className="addCompanyModal"
                onNextClick={handleSubmit}
                modalBodyClassName="scrollBar"
            >
                <div className="text-center">
                    <img
                        className="w-50 m-auto h-100"
                        src={data?.qrCodeUrl}
                        alt={t("QR Code")}
                        title={t("QR code")}
                    />
                </div>
            </ModalPopup>
        </div>
    );
};

export default translate(QrCodeModal);

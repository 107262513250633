// src/components/QRCodeDisplay.tsx

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import Button from "../common/atoms/Button";
import BackLink from "../common/atoms/BackLink";
import PrintFile from "../common/molecules/PrintFile";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { QR_CODE_REFRESH } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { locations } from "../ManageAdministrator/AddAdministrator";
import { createQrCode, fetchData, formattedDate } from "../Dashboard/utils/qrCodeUtils";

export interface Companies {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string | null;
  locations: Array<locations>; // Adjust the type based on your location data structure
}
interface QRCodeDisplayProps {
  // companyId: string;
  // item: any;
}
// Same interface and component as before
const QRCodeDisplay: React.FC<QRCodeDisplayProps> = () => {
  const { companyId } = useParams();
  const authData = useSelector((state: any) => state?.auth);
  const userId = authData?.userId;
  const companies: Companies[] = authData?.companies;
  const company = companies?.find((item) => item?.id === Number(companyId));

  const companyName = company ? company.name : "Company not found";

  const [data, setData] = useState({
    qrCodeUrl: "",
    createdOn: "",
  });

  // Function to handle refresh
  const handleRefresh = async () => {
    const dataObj = {
      company_id: companyId,
      user_id: userId,
    };
    let url = `${QR_CODE_REFRESH}`;
    try {
      const response = await ApiCall.service(url, "POST", dataObj, true);
      if (response.message === "Success") {
        setData((prevState: any) => ({
          ...prevState,
          qrCodeUrl: response?.data?.link,
          createdOn: formattedDate(response?.data?.qr_code?.created_at),
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (companyId && userId) {
      fetchData(Number(companyId), userId, createQrCode).then((fetchedData) => {
        setData(fetchedData);
      });
    }
  }, [companyId, userId]);

  return (
    <div>
      <Header headerName={t("QR code")} headerClassName="myAccountCardTitle" />
      <div className="search-bar pb-md-2">
        <div className="row">
          <BackLink backLink={`/manage-companies/${companyId}`} />

          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField title={t("QR code")} className="myAccountCardTitle mb-0" />
          </div>
        </div>
      </div>
      <div className="createpagesBg py-5">
        <div className="row justify-content-center">
          <div className="col-lg-5">
            <LabelField title={t("QR code") + ": " + t("" + companyName + "")} className="mb-2" />
            <br />
            <LabelField title={"1. " + t("Scan the QR code")} className="mb-2" />
            <br />
            <LabelField title={"2. " + t("Choose an action in the app that you want to perform")} className="mb-2" />
            <br />
            <LabelField title={"3. " + t("Confirm your choice")} className="mb-2" />
            <br />
            <div className="qr-code-img-wrapper">
              {data?.qrCodeUrl && (
                <img className="w-50 m-auto h-100" src={data?.qrCodeUrl} alt={t("QR Code")} title={t("QR code")} />
              )}
              <div className="d-lg-flex align-items-center mt-3">
                <LabelField title={t("QR code created on") + ": " + data?.createdOn} />
                <div className="d-lg-inline-flex align-items-center mt-3 mt-lg-0">
                  <span className="secondaryColorHoverEffect">
                    <CustomActionIcon iconName="refreshIcon" className="me-2 ms-3" buttonClass="resetBtn" title={t("Refresh")} handleClick={handleRefresh} />
                  </span>
                  {data?.qrCodeUrl && (
                    <div className="print-container secondaryColorHoverEffect cursor-pointer">
                      <PrintFile fileUrl={data?.qrCodeUrl} isQRcode={true} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Button title={t("View history")} className="searchBtn float-start mt-3" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(QRCodeDisplay);

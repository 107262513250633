import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Shift,
  ShiftRow,
  Fields,
  ShiftRowType,
  defaultFields,
  fieldLabels,
  initialShiftSates,
  formatTotalValue,
  formatTime,
  calculateTotalTime,
  isValidTime,
  formatName,
  isValidShiftName,
} from "./shifthelper";
import { t, translate } from "../translation/Translation";
import InputTextfield from "../../common/atoms/InputTextField";
import Header from "../../common/layout/Header";
import { ApiCall } from "../../../services/ApiServices";
import { SHIFTS } from "../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import CustomNotify from "../../common/atoms/CustomNotify";
import "./shifts.css";
import Title from "../../common/atoms/Title";
import Icon from "../../../Icon";
import Button from "../../common/atoms/Button";
import LabelField from "../../common/atoms/LabelField";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface ShiftProps {
  fields?: Fields[];
  onChange?: (shifts: ShiftRowType) => void;
}

interface TimeError {
  startTime: string;
  endTime: string;
  comparison?: string;
}

interface ShiftErrors {
  shift1: TimeError;
  shift2: TimeError;
}

const ManageShifts: React.FC<ShiftProps> = ({
  fields = defaultFields,
  onChange,
}) => {
  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);
  const [shifts, setShifts] = useState<ShiftRowType[]>([initialShiftSates]);
  const [validationErrors, setValidationErrors] = useState<boolean[]>([]);
  const [highlightedErrors, setHighlightedErrors] = useState<boolean[]>([]);
  const [timeErrors, setTimeErrors] = useState<ShiftErrors[]>([]);
  const [showErrors, setShowErrors] = useState(false);
    const { id } = useParams();

  useEffect(() => {
    getShiftDataByCompanyId();
  }, []);

  const getShiftDataByCompanyId = async () => {
    const response = await ApiCall.getService(
      `${'get-shifts'}?company_id=${companyId ?? id}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      if (response.data.shifts.length > 0) {
        setShifts(response.data.shifts);
      }
    }
  };

  const compareStartEndTime = (startTime: string, endTime: string): string => {
    if (!startTime || !endTime) return "";
    if (!isValidTime(startTime) || !isValidTime(endTime)) return "";

    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);

    const startTimeMinutes = startHours * 60 + startMinutes;
    const endTimeMinutes = endHours * 60 + endMinutes;

    if (startTimeMinutes >= endTimeMinutes) {
      return "End time must be greater than start time";
    }
    return "";
  };

  const validateTimeFormat = (time: string): string => {
    if (!time) return "Time is required";
    if (!isValidTime(time)) return "Invalid time format";
    
    const [hours, minutes] = time.split(':').map(Number);
    if (hours < 0 || hours > 23) return "Hours must be between 0-23";
    if (minutes < 0 || minutes > 59) return "Minutes must be between 0-59";
    
    return "";
  };

  const validateAllShifts = () => {
    const nameErrors = shifts.map((shift) => shift.shift_name.trim() === "");
    setValidationErrors(nameErrors);
    setHighlightedErrors(nameErrors);

    const newTimeErrors: ShiftErrors[] = shifts.map((shift) => ({
      shift1: {
        startTime: validateTimeFormat(shift.shift1.startTime),
        endTime: validateTimeFormat(shift.shift1.endTime),
        comparison: compareStartEndTime(shift.shift1.startTime, shift.shift1.endTime)
      },
      shift2: {
        startTime: validateTimeFormat(shift.shift2.startTime),
        endTime: validateTimeFormat(shift.shift2.endTime),
        comparison: compareStartEndTime(shift.shift2.startTime, shift.shift2.endTime)
      }
    }));

    setTimeErrors(newTimeErrors);
    setShowErrors(true);

    const hasNameErrors = nameErrors.includes(true);
    const hasTimeErrors = newTimeErrors.some(
      error =>
        error.shift1.startTime ||
        error.shift1.endTime ||
        error.shift1.comparison ||
        error.shift2.startTime ||
        error.shift2.endTime ||
        error.shift2.comparison
    );

    return !hasNameErrors && !hasTimeErrors;
  };

  const handleShiftChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement>,
    shiftType: Fields,
    timeKey?: keyof Shift
  ) => {
    const currentValue =
      shiftType === "total"
        ? formatTotalValue(e.target.value)
        : formatTime(e.target.value);
    const newShifts = [...shifts];

    if (shiftType === "shift_name") {
      newShifts[index] = {
        ...newShifts[index],
        shift_name: formatName(e.target.value),
      };
      setHighlightedErrors((prevErrors) => {
        const newErrors = [...prevErrors];
        newErrors[index] = false;
        return newErrors;
      });
    } else if (shiftType === "shift1" || shiftType === "shift2") {
      const updatedShift = { ...(newShifts[index][shiftType] as Shift) };
      updatedShift[timeKey!] = currentValue;

      const otherShift =
        shiftType === "shift1" ? newShifts[index].shift2 : newShifts[index].shift1;
      const totalTime = calculateTotalTime(
        shiftType === "shift1" ? updatedShift : otherShift,
        shiftType === "shift1" ? otherShift : updatedShift
      );

      newShifts[index] = {
        ...newShifts[index],
        [shiftType]: updatedShift,
        total: totalTime,
      };
    }
    setShifts(newShifts);
  };

  const addShift = () => {
    if (shifts.length < 10) {
      setShifts([...shifts, initialShiftSates]);
      setShowErrors(false);
    }
  };

  const removeShift = (index: number) => {
    const newShifts = shifts.filter((_, i) => i !== index);
    setShifts(newShifts);
    setShowErrors(false);
  };

  const getCellStyleForInvalidTime = (time: string, field: Fields) => {
    if (field === "total") return "";
    return !isValidTime(time) && time ? "error-border" : "";
  };

  const saveShifts = async () => {
    if (shifts.length < 1) {
      setShifts([initialShiftSates]);
      return;
    }

    if (!validateAllShifts()) {
      CustomNotify({
        type: "error",
        message: t("Invalid shift timings."),
      });
      return;
    }

    try {
      let data = {
        companyId: companyId,
        shiftData: {
          ["shifts"]: shifts,
        },
      };
      const response = await ApiCall.service(
        SHIFTS,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        setShowErrors(false);
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving shifts:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Manage Shifts")}
        headerClassName="myAccountCardTitle"
      />
      <div className="manageContentBg">
        <div className="container-fluid manageShiftTable">
          <div className="row">
            {fields.map((day) => (
              <div
                className={`${day === "total" ? "col-2" : "col-3"} text-center shiftNumber`}
                key={day}
              >
                {t(fieldLabels[day])}
              </div>
            ))}
          </div>
          {shifts.map((shift, index) => (
            <div key={index} className="row align-items-center my-2">
              <div className="col-3 d-flex flex-column">
                <InputTextfield
                  value={shift.shift_name}
                  className={`form-control me-2 ps-2 ${
                    highlightedErrors[index] && showErrors ? "error-border" : ""
                  }`}
                  handleChange={(e) =>
                    handleShiftChange(index, e, "shift_name")
                  }
                  inputClassWrapper="w-100"
                />
                {showErrors && highlightedErrors[index] && (
                  <div className="text-danger small mt-1">
                    {t("Shift name is required")}
                  </div>
                )}
              </div>

              <div className="col-3 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <InputTextfield
                    value={shift.shift1.startTime}
                    className={`form-control ps-2 ${
                      getCellStyleForInvalidTime(shift.shift1.startTime, "shift1")
                    }`}
                    handleChange={(e) =>
                      handleShiftChange(index, e, "shift1", "startTime")
                    }
                  />
                  <div className="mx-2">{" - "}</div>
                  <InputTextfield
                    value={shift.shift1.endTime}
                    className={`form-control ps-2 ${
                      getCellStyleForInvalidTime(shift.shift1.endTime, "shift1")
                    }`}
                    handleChange={(e) =>
                      handleShiftChange(index, e, "shift1", "endTime")
                    }
                  />
                </div>
                {showErrors && (
                  timeErrors[index]?.shift1.startTime || 
                  timeErrors[index]?.shift1.endTime || 
                  timeErrors[index]?.shift1.comparison
                ) && (
                  <div className="text-danger small mt-1">
                    {timeErrors[index]?.shift1.startTime || 
                     timeErrors[index]?.shift1.endTime || 
                     timeErrors[index]?.shift1.comparison}
                  </div>
                )}
              </div>

              <div className="col-3 d-flex flex-column">
                <div className="d-flex align-items-center">
                  <InputTextfield
                    value={shift.shift2.startTime}
                    className={`form-control ps-2 ${
                      getCellStyleForInvalidTime(shift.shift2.startTime, "shift2")
                    }`}
                    handleChange={(e) =>
                      handleShiftChange(index, e, "shift2", "startTime")
                    }
                  />
                  <div className="mx-2">{" - "}</div>
                  <InputTextfield
                    value={shift.shift2.endTime}
                    className={`form-control ps-2 ${
                      getCellStyleForInvalidTime(shift.shift2.endTime, "shift2")
                    }`}
                    handleChange={(e) =>
                      handleShiftChange(index, e, "shift2", "endTime")
                    }
                  />
                </div>
                {showErrors && (
                  timeErrors[index]?.shift2.startTime || 
                  timeErrors[index]?.shift2.endTime ||
                  timeErrors[index]?.shift2.comparison
                ) && (
                  <div className="text-danger small mt-1">
                    {timeErrors[index]?.shift2.startTime || 
                     timeErrors[index]?.shift2.endTime ||
                     timeErrors[index]?.shift2.comparison}
                  </div>
                )}
              </div>

              <div className="col-2 text-center">
                <span className="totalHours">{shift.total}</span>
              </div>

              <div className="col-1 text-start">
                <span
                  onClick={() => removeShift(index)}
                  title={t("Delete")}
                  className="secondaryColorHoverEffect"
                >
                  <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
                </span>
                {index === shifts.length - 1 && shifts.length < 10 && (
                  <span
                    className="secondaryColorHoverEffect ms-2"
                    onClick={addShift}
                    title={t("Add shift")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </span>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="row mt-4">
          <div className="col text-end">
            <Button
              className="searchBtn"
              handleClick={saveShifts}
              title={t("Save shifts")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ManageShifts);
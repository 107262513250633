import React, { useEffect, useState } from "react";
import LabelField from "../../common/atoms/LabelField";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import Button from "../../common/atoms/Button";
import InputTextfield from "../../common/atoms/InputTextField";
import {
  GET_VAT_DETAILS,
  REGISTER_SETTINGS_LIST,
  REGISTER_VAT_DETAILS,
  VAT_DETAILS,
} from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { AuthState, DropdownOptions } from "../services/CompanyInterface";
import { useSelector } from "react-redux";
import { selectIbanCountries } from "../../../features/settings/settingSlice";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import { translate } from "../../CentralDataMangement/translation/Translation";

interface VatNumberFieldProps {
  t: (key: string) => string;
  iban_code?: string;
  vat_number?: string;
  errors?: any;
  companyid?: number | null | undefined;
  clearFieldError?: (fieldName: string) => void;
  handleSelectChange?: (
    selectedOptions: { label: string; value: string }[],
    fieldName: string
  ) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  Editing?: boolean;
  updateVat?: (response?: any, errors?: any) => void;
  isFieldCol?: boolean;
  isDataCol?: boolean;
  isVatGet?: boolean;
  showPopup?: (companyId: number) => void;
}

// VatNumberField component definition
const VatNumberField: React.FC<VatNumberFieldProps> = ({
  t,
  iban_code = "BE",
  vat_number = "",
  errors = {},
  clearFieldError = () => { },
  handleSelectChange = () => { },
  handleChange = () => { },
  Editing = false,
  updateVat = () => { },
  isFieldCol = false,
  isDataCol = false,
  isVatGet = false,
  showPopup
}) => {
  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "label colorPrimary",
    colClassName: "col-6",
    className: "inputFieldColor",
  };
  const user = useSelector((state: any) => state?.auth);
  const validateVAT_number = (value: string): string | null => {
    if (!value || value.trim() === "") {
      return t("VAT number is required");
    }

    if (/\s/.test(value)) {
      return t("VAT number contains spaces. Ensure it is entered without spaces and try again");
    }

    if (!/^[a-zA-Z0-9/]+$/.test(value)) {
      return t("Invalid VAT number");
    }

    if (value.length < 8) {
      return `${t("VAT number must be at least")} 8 ${t("characters long")}`;
    }

    if (value.length > 15) {
      return `${t("VAT number must be at most")} 15 ${t("characters long")}`;
    }
    return null;
  };

  const ibancountries = useSelector(selectIbanCountries);

  const [companyData, setCompanyData] = useState(ibancountries ?? []);

  useEffect(() => {
    if (companyData?.length === 0) {
      fetchIbanCountries();
    }
  }, [companyData]);

  const handleVerifyVat = async (
    vat_number: string,
    iban_code: string,
    user: AuthState,
    updateVat: (response?: any, errors?: any) => void
  ) => {
    const vatError = validateVAT_number(vat_number);

    // If VAT number validation fails, immediately return the error
    if (vatError) {
      updateVat(undefined, { vat_number: vatError });
      return;
    }

    let responseData = undefined;
    let responseError = undefined;

    try {
      const full_vat_number: string = `${iban_code}${vat_number}`;
      const call_vat_number_api = isVatGet ? GET_VAT_DETAILS : REGISTER_VAT_DETAILS;
      const route_name = user?.userId ? "vat-details" : "register-vat-details";

      const url = user?.userId
        ? `${VAT_DETAILS}/${full_vat_number}?route_name=${route_name}`
        : `${call_vat_number_api}/${full_vat_number}?route_name=${route_name}`;

      const response = await ApiCall.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        false
      );

      if (response?.status === "success") {
        if (response?.message.includes("A company with this VAT number already exists. Do you want to update the existing company?")) {
          showPopup?.(response?.data?.enterprise);
          return;
        }
        responseData = response?.data;
      } else {
        responseError = {
          vat_number: t(response?.message) || t("Failed to verify VAT number."),
        };
      }
    } catch (error: unknown | any) {
      console.error(error);

      responseError = {
        vat_number:
          error.response?.data?.message ||
          (error.response?.status === "validation-error"
            ? t("Validation error.")
            : t("Failed to verify VAT number.")),
      };
    }
    updateVat(responseData, responseError);
  };

  const fetchIbanCountries = async () => {
    const url = REGISTER_SETTINGS_LIST;
    try {
      const result = await ApiCall?.getService(url, "GET");
      const ibanData = result?.data?.iban_countries?.map(
        (iban: DropdownOptions) => ({
          value: iban?.value,
          label: iban?.label,
        })
      );
      setCompanyData(ibanData);
    } catch (error) {
      console.error("Error fetching IBAN countries:", error);
    }
  };

  return (
    <div className="row">
      <div className={` ${isFieldCol ? "col-12" : "col-3 leftlabelClass"}`}>
        <LabelField
          title={t("VAT number") + ":"}
          className="label colorPrimary"
          isMandatory
        />
      </div>
      <div className={`${isDataCol ? "col-10" : "col-md-7"}`}>
        <div className="row d-flex">
          <div className="col-4 multiSelectWrapper vatNumber">
            <SelectWithSearch
              search
              options={companyData || []}
              value={
                iban_code
                  ? [{ label: iban_code, value: iban_code }]
                  : [{ label: "BE", value: "BE" }]
              }
              onChange={(selectedOptions) =>
                handleSelectChange(selectedOptions, "iban_code")
              }
              isMulti={false}
              id="selectField"
              name="selectField"
              // selectPrefix="vatNumber"
              isDisabled={Editing}
            />
          </div>
          <div
            className="col-8 ps-0"
            onClick={() => clearFieldError("vat_number")}
          >
            <InputTextfield
              type="text"
              value={vat_number}
              handleChange={handleChange}
              name="vat_number"
              error={errors?.vat_number}
              id="vat_number"
              autoComplete="off"
              isDisabled={Editing}
              {...inputFieldSharedProps}
            />
          </div>
        </div>
      </div>
      <div className="col-md-2 col-6 ms-auto mt-3 mt-lg-0 ms-lg-0">
        {!Editing &&
          <Button
            handleClick={() =>
              handleVerifyVat(vat_number, iban_code, user!, updateVat)
            }
            title={t("Verify")}
            className="modalSaveBtn vatVerifyBtn"
          />
        }
      </div>
    </div>
  );
};

export default translate(VatNumberField);

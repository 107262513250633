import React from "react";
import Icon from "../../../Icon";
import Image from "../atoms/Image";
import SubIcon from "../atoms/SubIcon";
import Title from "../atoms/Title";
import { useNavigate } from "react-router-dom";
import { t } from "../../CentralDataMangement/translation/Translation";
import { useResponsiveValue } from "../atoms/ResponsivWidth";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";

interface IconWithTitleProps {
  name?: string;
  color?: string;
  width?: number | string;
  height?: number | string;
  title?: string;
  className?: string;
  labelClassName?: string;
  icon?: string; // This appears unused in the current code
  borderRadius?: string | number;
  subIconClassName?: string;
  subIconname?: string;
  src?: string;
  altName?: string;
  imageBorderRadius?: string;
  imageWidth?: string | number;
  imageHeight?: string | number;
  isImage?: boolean;
  isIcon?: boolean;
  isTitle?: boolean;
  isSubIcon?: boolean;
  isQrCode?: boolean;
  isSettings?: boolean;
  iconId?: string | number;
  imageWrapperClassName?: string;
  iconImageWrapperClassName?: string;
  titleImageWrapperClassName?: string;
  subIconImageWrapperClassName?: string;
  subIconWidth?: string | number;
  subIconHeight?: string | number;
  imageBackgroundColor?: string;
  iconBackgroundColor?: string;
  fillColor?: string;
  cusror?: string;
  handleCompanyClick?: () => void;
  handleShowModal?: () => void;
  handleQrCodeModal?: () => void;
  isManageLocation?: boolean;
  hoverTitle?: string;
  companyId?: number | null;
}

const IconWithTitle: React.FC<IconWithTitleProps> = ({
  name,
  color,
  width,
  height,
  title,
  className,
  labelClassName,
  borderRadius,
  subIconClassName,
  subIconname,
  src,
  altName,
  imageBorderRadius,
  imageWidth,
  imageHeight,
  isImage = false,
  isIcon = false,
  isTitle = false,
  isSubIcon = false,
  isQrCode = false,
  isSettings = false,
  iconId = 1,
  imageWrapperClassName,
  iconImageWrapperClassName,
  titleImageWrapperClassName,
  subIconImageWrapperClassName,
  subIconHeight,
  subIconWidth,
  imageBackgroundColor,
  iconBackgroundColor,
  fillColor,
  handleCompanyClick,
  handleShowModal,
  handleQrCodeModal,
  isManageLocation = false,
  hoverTitle,
  companyId
}) => {
  const navigate = useNavigate(); // Initialize the useNavigate hook
  const iconWidth = useResponsiveValue("5vw", "3vw", "1vw");

  return (
    <>
      {isImage && (
        <div className={imageWrapperClassName} onClick={handleCompanyClick}>
          <Image
            src={src}
            altName={altName}
            imageBorderRadius={imageBorderRadius}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            imageBackgroundColor={imageBackgroundColor}
          />
        </div>
      )}
      {isIcon && (
        <div
          className={iconImageWrapperClassName}
          style={{ cursor: "pointer" }}
        >
          <Icon
            name={name}
            color={color}
            width={width}
            height={height}
            className={className}
            borderRadius={borderRadius}
            iconBackgroundColor={iconBackgroundColor}
            fillColor={fillColor}
            isIcon={true}
          />
        </div>
      )}
      {isTitle && (
        <div
          className={titleImageWrapperClassName}
          onClick={handleCompanyClick}
          title={hoverTitle}
        >
          <Title title={title} className={labelClassName} />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-center mt-2">
        {
          isSettings && (
            <div className="mx-2 mt-2 actionWrapper cursor-pointer">
              <Icon
                isIcon={true}
                name="Settings"
                width={iconWidth}
                height={iconWidth}
                onClick={() => navigate('/manage-companies/' + companyId)}
              />
            </div>
          )
        }
        {isSubIcon && (
          <div
            className={`${subIconImageWrapperClassName} actionWrapper`}
            data-bs-toggle="tooltip"
            title={t("Edit company")}
          >
            <AccessControl
              requiredPermissions={[
                {
                  permission: "company",
                  read: true,
                },
              ]}
              renderNoAccess={true}
            >
              <Icon
                isIcon={true}
                name="EditIcon"
                width={iconWidth}
                height={iconWidth}
                onClick={handleShowModal}
              />
            </AccessControl>
          </div>
        )}

        {isManageLocation && (
          <div className="ms-2 actionWrapper cursor-pointer mt-2" title={t("Manage locations")}>
            <Icon
              isIcon={true}
              name="Managelocation"
              width={iconWidth}
              height={iconWidth}
              onClick={() => navigate('/manage-location/' + iconId)} // Redirect to /manage-location on click
            />
          </div>
        )}
        {
          isQrCode && (
            <div className="ms-2 mt-2 actionWrapper cursor-pointer">
              <Icon
                isIcon={true}
                name="qrCode"
                width={iconWidth}
                height={iconWidth}
                // onClick={() => navigate('/qr-code/' + iconId)}
                onClick={handleQrCodeModal}
              />
            </div>
          )
        }

      </div>
    </>
  );
};

export default IconWithTitle;

import React, { useRef, useCallback, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "./Button";
import { t } from "./translation";
import { useSelector } from "react-redux";
import { ReduxState } from "../../Dashboard/services/CompanyInterface";
import { uploadFile } from "../../../services/common/CommonServices";
import { FETCH_FILE } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

interface SignatureFieldProps {
  onChange?: (dataUrl: string) => void;
  onSave?: (dataUrl: string) => void;
  className?: string;
  height?: string;
  width?: string;
  clearBtnClass?: string;
  saveBtnClass?: string;
  disabled?: boolean;
  dataUrl?: string;
}

const SignatureField: React.FC<SignatureFieldProps> = ({
  onChange = () => { },
  onSave = () => { },
  className = "",
  height = "300px",
  width = "450px",
  clearBtnClass = "",
  saveBtnClass = "",
  disabled = false,
  dataUrl = "",
}) => {
  const signatureRef = useRef<SignatureCanvas>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const user = useSelector((state: ReduxState) => state?.auth?.userId);

  const signatureLoaded = useRef(false); // Track if signature has been loaded

  useEffect(() => {
    if (!dataUrl || signatureLoaded.current) return; // Avoid redundant API calls
    signatureLoaded.current = true;
    fetchSignature(dataUrl);
  }, [dataUrl]);

  const fetchSignature = async (dataUrl: string) => {
    try {
      const response = await ApiCall.service(
        FETCH_FILE,
        "POST",
        { file_url: dataUrl },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response?.data?.base64 && signatureRef.current) {
        signatureRef.current.fromDataURL(`data:image/png;base64,${response?.data?.base64}`);
      } else {
        console.error("Invalid response: No base64 data received");
      }
    } catch (error) {
      console.error("Error fetching company documents:", error);
      setErrorMessage("Failed to load signature.");
    }
  };

  const base64ToBlob = (base64Data: string, contentType: string = 'image/png') => {
    const byteCharacters = atob(base64Data.split(',')[1] || base64Data);
    const byteNumbers = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Blob([byteNumbers], { type: contentType });
  };

  const handleSaveSignature = useCallback(async () => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      try {
        const signatureDataUrl = signatureRef.current.toDataURL("image/png");
        const base64Data = signatureDataUrl.split(',')[1];

        const blob = base64ToBlob(base64Data);
        const fileName = user ? `${user}_signature.png` : "signature.png";
        const file = new File([blob], fileName, { type: "image/png" });

        const uploadResponse = await uploadFile(file, fileName, 15, "signature");
        onSave(uploadResponse?.data?.fileUrl);
        setErrorMessage(null);
      } catch (error) {
        console.error(error);
        setErrorMessage("Failed to save signature due to a CORS issue.");
      }
    } else {
      setErrorMessage(`${t("Please add signature before saving")}!!`);
    }
  }, [onSave, user]);

  const handleEndSignature = useCallback(() => {
    if (signatureRef.current && !signatureRef.current.isEmpty()) {
      const signatureDataUrl = signatureRef.current.getTrimmedCanvas().toDataURL("image/png");
      onChange(signatureDataUrl);
    }
  }, [onChange]);

  return (
    <div>
      <div className={`border rounded-4 mb-3 ${className}`} style={{ width, height }}>
        {!disabled ? (
          <SignatureCanvas
            ref={signatureRef}
            penColor="black"
            canvasProps={{
              className: "signatureCanvas",
              style: { width, height },
            }}
            onEnd={handleEndSignature}
          />
        ) : (
          <div className="text-center">
            <img src={dataUrl} alt="Signature" style={{ width, height, opacity: 0.7 }} />
          </div>
        )}
      </div>
      {errorMessage && <div className="text-danger">{errorMessage}</div>}
      <div style={{ width }} className="d-flex justify-content-between">
        <Button
          disabled={disabled}
          className={`btn delete-btn px-3 ${clearBtnClass}`}
          style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-white)' }}
          handleClick={() => signatureRef.current?.clear()}
        >
          {t("Clear")}
        </Button>
        <Button
          disabled={disabled}
          className={`btn form-button float-end px-3 ${saveBtnClass}`}
          style={{ backgroundColor: 'var(--color-primary)', color: 'var(--color-white)' }}
          handleClick={handleSaveSignature}
        >
          {t("Save")}
        </Button>
      </div>
    </div>
  );
};

export default SignatureField;

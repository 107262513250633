import React, { useEffect, useState } from "react";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import { t } from "../../../CentralDataMangement/translation/Translation";
import Header from "../../../common/layout/Header";
import Button from "../../../common/atoms/Button";
import { initialstate } from "../State";
import {
  EmployeeDetails,
  EmployeeInviteState,
  InviteForm as InviteFormType,
} from "../Interfaces";
import { Option } from "../../registration/Interfaces";
import { updateField } from "../../registration/Service";
import { validate } from "../Service";
import SearchEmail from "../organisms/SearchEmail";
import { ApiCall } from "../../../../services/ApiServices";
import { INVITE_EMPLOYEE } from "../../../../routes/ApiEndpoints";
import { useNavigate } from "react-router-dom";
import CustomNotify, { NotifyType } from "../../../common/atoms/CustomNotify";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import EmployeeSalaryInfo from "../../registration/organisms/EmployeeSalaryInfo";
import InviteForm from "../organisms/InviteForm";
import LabelField from "../../../common/atoms/LabelField";
import * as ROUTE from "../../../../routes/RouteConstants";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import AccessControl from "../../../../services/RolesAndPermissions/AccessControl";

const InviteEmployeeTemplate: React.FC = () => {
  const [state, setState] = useState<EmployeeInviteState>(initialstate);
  console.log("state", state);
  const navigate = useNavigate();
  const user = useSelector(selectAuth);

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      all_employer_companies: user?.employer_companies,
    }));
  }, [user?.employer_companies]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setState((prevState: EmployeeInviteState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
      errors: {
        ...prevState.errors,
        [name]: "",
      },
    }));
  };

  const handleSelectChange = (
    selectedOption: Option | null,
    fieldPath: string
  ) => {
    const value = selectedOption ? selectedOption.value : null;
    setState((prevState: EmployeeInviteState) => ({
      ...prevState,
      formData: updateField(state.formData, fieldPath, value),
    }));
  };

  const updatePcValues = (updatedState: any) => {
    setState((prevState: EmployeeInviteState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        companies: updatedState.companies,
        default_emp_legal: updatedState.default_emp_legal,
      },
      errors: updatedState.errors,
      missing_companies: updatedState.missing_companies,
    }));
  };

  const updateErrors = (errors: any) => {
    setState((prevState: EmployeeInviteState) => ({
      ...prevState,
      errors,
    }));
  };

  const handleSubmit = () => {
    let { validated, errors } = validate(state);
    if (validated) {
      let postData: InviteFormType = {
        ...state.formData,
        default_emp_legal: state.formData.default_emp_legal.map((item) => ({
          ...item,
          actual_salary: item.actual_salary ? item.actual_salary : item.minWage,
          is_actual_added:
            parseFloat(item.actual_salary) > parseFloat(item.minWage),
        })),
        new_companies: state?.formData?.companies?.filter?.(
          (companyId) => !state?.fetched_companies?.includes?.(companyId)
        ),
      };
      invite(postData);
    } else {
      setState((prevState: EmployeeInviteState) => ({
        ...prevState,
        errors: errors,
      }));
    }
  };

  const invite = async (postData: InviteFormType) => {
    const response = await ApiCall.service(
      INVITE_EMPLOYEE,
      "POST",
      postData,
      true,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    let status: NotifyType = "error";
    let message = "";
    if (response.status === "success") {
      status = "success";
      message = response.message;
      navigate("/employee-overview");
    } else if (response.status === "validation-error") {
      const { data } = response;
      message = Object.values(data).join(", ");
    } else {
      message = response.message;
    }
    CustomNotify({
      type: status,
      message: message,
    });
  };

  const updateValues = (data: EmployeeDetails, user_not_found: boolean) => {
    setState((prevState: EmployeeInviteState) => {
      return {
        ...prevState,
        formData: {
          ...prevState.formData,
          user_id: data.user_id,
          first_name: data.first_name,
          last_name: data.last_name,
          phone_number: data.phone_number,
          email: data.email,
          date_of_birth: data.date_of_birth,
          registry_number: data.registry_number,
          default_emp_legal: data.default_emp_legal,
          companies: data.companies,
          status:data?.status,
        },
        errors: {},
        not_found: user_not_found,
        fetched_companies: data?.companies,
        prev_invited_companies_count: data?.companies?.length,
        isDataNotAvailable: (data.first_name && data.last_name && data.phone_number && data.registry_number && data.email && data.date_of_birth) ? false : true,
      };
    });
  };


  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "invite_employee",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <Header
        headerName={t("Add employee")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row">
          <div className="row">
            <div className="col-8 align-self-center text-center d-block d-lg-none">
              <LabelField
                title={t("Add employee")}
                className="myAccountCardTitle mb-0"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="createpagesBgInvite mb-4 createpagesBg">
        <OverFlowWrapper className="createpagesBgInviteHeight container-fluid">
          <SearchEmail
            email={state.formData?.verify_email}
            handleChange={handleChange}
            updateValues={updateValues}
            verify_email_error={state?.errors?.verify_email}
            updateErrors={updateErrors}
          />
          {state.not_found && (
            <div className="col-12 mb-2">
              <div className="text-danger employeeRegisterErrorText">
                {t("Employee not found in the system. Please") + " "}
                <span
                  onClick={() => {
                    const email = state?.formData?.verify_email;
                    if (email) {
                      navigate(
                        `${ROUTE.EMPLOYEE_REGISTRATION
                        }?email=${encodeURIComponent(email)}`
                      );
                    } else {
                      navigate(ROUTE.EMPLOYEE_REGISTRATION);
                    }
                  }}
                  className="linkColor text-decoration-underline cursor-pointer registerLink poppins-medium"
                >
                  {t("register")}
                </span>
                {" " + t("to add the employee to the system.")}
              </div>
            </div>
          )}
          {state?.formData?.status === "draft" && (
            <div className="col-12 mb-2">
              <div className="text-danger employeeRegisterErrorText">
                {t("This employee's data is incomplete.") + " "}
                <span
                  onClick={() => {
                    const user_id = state?.formData?.user_id;
                    if (user_id) {
                      navigate(
                        `${ROUTE.EMPLOYEE_REGISTRATION
                        }/${encodeURIComponent(user_id)}`
                      );
                    } else {
                      navigate(ROUTE.EMPLOYEE_REGISTRATION);
                    }
                  }}
                  className="linkColor text-decoration-underline cursor-pointer registerLink poppins-medium"
                >
                  {t("Click here")}
                </span>
                {" " + t("to review and update before registering.")}
              </div>
            </div>
          )}
          {state?.formData?.user_id !== 0 && state?.formData?.status !== "draft" &&(
            <>
              {state?.isDataNotAvailable && (
                <div className="col-12 mb-2">
                  <div className="text-danger employeeRegisterErrorText">
                    {t("Incomplete employee details detected. Please") + " "}
                    <span
                      onClick={() => {
                        const user_id = state?.formData?.user_id;
                        if (user_id) {
                          navigate(
                            `${ROUTE.EMPLOYEE_REGISTRATION
                            }/${encodeURIComponent(user_id)}`
                          );
                        } else {
                          navigate(ROUTE.EMPLOYEE_REGISTRATION);
                        }
                      }}
                      className="linkColor text-decoration-underline cursor-pointer registerLink poppins-medium"
                    >
                      {t("click here")}
                    </span>
                    {" " + t("to update the information.")}
                  </div>
                </div>
              )}
              <InviteForm
                formValues={state.formData}
                formErrors={state.errors}
                handleChange={handleChange}
                isDisabled={state?.isDisableState}
              />
              <EmployeeSalaryInfo
                user_id={state.formData?.user_id}
                companies={state.formData?.companies}
                errors={state.errors}
                default_emp_legal={state.formData?.default_emp_legal}
                updatePcValues={updatePcValues}
                handleSelectChange={handleSelectChange}
                missing_companies={state?.missing_companies}
                dob={state?.formData?.date_of_birth}
                currentPage="employee-invite"
                fetched_companies={state?.fetched_companies}
              />
            </>
          )}
        </OverFlowWrapper>
        <div className="text-end py-3">
          {state?.formData?.user_id !== 0 && state?.prev_invited_companies_count !== state?.formData?.companies?.length && (
            <Button
              title="Submit"
              className="modalSaveBtn"
              handleClick={handleSubmit}
            />
          )}
        </div>
      </div>
      {/* </AccessControl> */}
    </>
  );
};
export default InviteEmployeeTemplate;

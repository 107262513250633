import React from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { Coefficient, Deviation, LeftPartProps } from "./Interfaces";


const LeftPart: React.FC<LeftPartProps> = ({ data }) => {
  // Check if the data is for coefficients or deviations
  const isCoefficientData = Object?.values?.(data)?.some?.(
    (list: any) => list?.some?.((item: any) => item.coeff_name)
  );

  // Create a unique list of coefficient or deviation names
  const uniqueNames = Array.from(
    new Set(
      Object.values?.(data)?.flatMap?.((list: Coefficient[] | Deviation[]) =>
        list?.map((item) => {
          if ('coeff_name' in item) {
            return (item as Coefficient)?.coeff_name;
          }
          return (item as Deviation)?.deviation_name;
        })
      )
    )
  );


  return (
    <table className="table">
      <thead>
        <tr className="TableHeader" style={{ height: "4.5vw" }}>
          <th>{t(isCoefficientData ? "Coefficients" : "Coefficient deviations")}</th>
        </tr>
      </thead>
      <tbody>
        {uniqueNames?.map?.((name, index) => (
          <tr key={index}>
            <td>{t(name)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default translate(LeftPart);

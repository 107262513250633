import React, { useState, useEffect } from "react";
import { FAV_PROJECT, PROJECTS, PROJECTS_BY_COMPANY } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import Icon from "../../../Icon";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import BackLink from "../../common/atoms/BackLink";
import LinkCustom from "../../common/atoms/Link";
import { Link, useParams } from "react-router-dom";
import InputTextfield from "../configurations/manageCoefficientDeviations/InputTexfield";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Pagination from "../../common/atoms/Pagination";
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import CustomNotify from "../../common/atoms/CustomNotify";
import ConfirmPopup from "../../common/atoms/ConfirmPopup";
import CommonPopUp from "../../common/atoms/CommonPopUp";
import { useSelector } from "react-redux";
import LoadingIcon from "../../common/utlis/LoadingIcon";
import NoRecords from "../../common/atoms/NoRecords";
import LabelField from "../../common/atoms/LabelField";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import CustomActive from "../../common/molecules/Actions/CustomActive";
import { useResponsiveValue } from "../../common/atoms/ResponsivWidth";

interface Project {
  id: number;
  name: string;
  is_active: boolean;
  address_id: number;
  company_id: number;
  is_favourite: boolean;
  location_id: number;
}

const ManageProjects: React.FC = () => {
  
  const [projects, setProjects] = useState<Project[]>([]); // State to store projects
  const [filteredProjects, setFilteredProjects] = useState<Project[]>([]); // State for filtered Projects
  const [searchTerm, setSearchTerm] = useState<string>(""); // State for search input
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [error, setError] = useState<string | null>(null); // Error state
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const companyId = useSelector((state: any) => state.auth.currentCompany.id);
  const EditIconStyle = useResponsiveValue("5vw", "2.5vw", "1vw");
  const { id } = useParams();

  // Fetch Projects from API
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  useEffect(() => {
    if (id) {
      fetchProjectsByCompany(Number(id));
    }
    else {
      fetchProjectsByCompany(companyId);
    }
  }, [companyId]);

  const fetchProjectsByCompany = async (companyId: number) => {
    setLoading(true);
    try {
      let payload = {
        company_id: companyId,
      };
      const response = await ApiCall.service(
        PROJECTS_BY_COMPANY,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {

        const projectsData = response.data.map((item: any) => ({
          id: item.id,
          name: item.name,
          is_active: item.is_active,
          address_id: item.address_id,
          company_id: item.company_id,
          is_favourite: item.is_favourite
          // location_id:item
        }));
        setProjects(projectsData);
        setFilteredProjects(projectsData); // Initially set filtered projects to all projects
        setLoading(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Handle input change for search
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value); // Only store the search term but don't filter yet
  };

  // Handle reset button click to clear search
  const handleResetClick = () => {
    setSearchTerm("");
    setFilteredProjects(projects); // Reset to original projects
  };

  // Handle search button click
  const handleSearchClick = () => {
    // Filter projects based on the search term
    const filteredData = projects.filter((project) =>
      project.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredProjects(filteredData);
  };

  const handleIsActiveClick = (project: Project) => {
    setSelectedProject(project);
    setShowConfirmPopup(true);
  };

  const handleFavIcon = async (project: Project) => {

    let payload = {
      company_id: project.company_id,
      location_id: project.location_id,
      project_id: project.id
    };

    try {
      project.is_favourite = !project.is_favourite;

      const response = await ApiCall.service(
        `${FAV_PROJECT}`,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status == "success") {

        CustomNotify({
          type: "success",
          message: t(response.message),
        });
      }
      else {
        CustomNotify({
          type: "error",
          message: t(response.message),
        });
      }
    } catch (error) {
      // handle error
      console.log(error);
    } finally {
      fetchProjectsByCompany(companyId)
      setShowConfirmPopup(false);
    }


  }

  const handleConfirm = async () => {
    if (!selectedProject) return;

    try {
      selectedProject.is_active = !selectedProject.is_active;

      const response = await ApiCall.service(
        `${PROJECTS}/${selectedProject.id}`,
        "PUT",
        selectedProject,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status == "success") {
        const message = response?.data?.active
          ? "Project successfully activated"
          : "Project successfully made inactive";

        CustomNotify({
          type: "success",
          message: t(message),
        });
      }
    } catch (error) {
      // handle error
    } finally {
      setShowConfirmPopup(false);
    }
  };

  return (
    <>
      <Header
        headerName={t("Manage projects")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-md-2">
        <div className="row mb-2">
          {!id && <BackLink backLink={"/"} />}
          {id && <BackLink backLink={`/manage-companies/${id}`} />}
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage projects")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="col-6 text-end ms-auto">
            <Link to="/project/add">
              <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                {t("Add project")}
              </div>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <InputTextfield
              value={searchTerm}
              handleChange={handleInputChange}
              placeholder={t("Project")}
              className="ps-2"
            />
          </div>
          <div className="col-md-4 mt-3 mt-md-0">
            <div className="d-flex align-items-center justify-content-end justify-content-lg-start">
              <ResetBtn handleResetClick={handleResetClick} />
              <SearchBtn handleSearchClick={handleSearchClick} />
            </div>
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          <table className="table table-hover customTableProperties pwa">
            <thead>
              <tr className="TableHeader">
                <th style={{ width: "60%" }}>{t("Project name")}</th>
                <th>{t("Actions")}</th>
              </tr>
              <TableHeaderSpace headerLength={2} />
            </thead>
            {loading ? (
              <tbody>
                <tr>
                  <td colSpan={2} className="border-0 px-2">
                    <LoadingIcon />
                  </td>
                </tr>
              </tbody>
            ) : filteredProjects.length === 0 ? (
              <NoRecords
                headerLength={2}
                message={t("Projects not found for this company")}
              />
            ) : (
              <tbody>
                {filteredProjects.map((project) => (
                  <tr key={project.id} className="border">
                    <td data-label={t("Project name")}>{project.name}</td>
                    <td className="px-2">
                      <EditIcon href={`/project/edit/${project.id}`} />
                      <CustomActive handleClick={() => handleIsActiveClick(project)}
                        title={
                          project.is_active ? "Deactivate" : "Activate"
                        }
                        iconName={
                          project.is_active ? "InActiveIcon" : "ActiveIcon"
                        }
                        buttonClass={`${project.is_active ? "searchBtn" : "resetBtn"} mb-3`}
                      />
                      <CommonPopUp
                        size="lg"
                        show={showConfirmPopup}
                        onHide={() => setShowConfirmPopup(false)}
                        title={t("Confirm Action")}
                        body={t(
                          project.is_active
                            ? "Are you sure you want to make this project inactive?"
                            : "Are you sure you want to make this project active?"
                        )}
                        closeTitle={t("Cancel")}
                        confirmTitle={t("Yes")}
                        onCloseButtonClick={() => setShowConfirmPopup(false)}
                        onConfirmButtonClick={handleConfirm}
                      />
                      <span className="fav-sec" onClick={() => handleFavIcon(project)}>
                        <Icon
                          className="cursor-pointer"
                          isIcon={true}
                          width={EditIconStyle}
                          height={EditIconStyle}
                          name={project.is_favourite ? 'starIcon' : 'whiteStarIcon'}
                        />
                      </span>


                    </td>
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </OverFlowWrapper>
        {/* {pageCount > 1 && (
          <div className="pagination justify-content-center mt-4 px-4">
            <Pagination
              currentPage={currentPage + 1}
              totalPages={pageCount}
              onPageChange={handlePageClick}
            />
          </div>
        )} */}
      </div>
    </>
  );
};

export default translate(ManageProjects);

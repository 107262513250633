import React, { useCallback, useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import TimePicker from "../common/atoms/TimePicker";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import UserImage from "../../static/images/User.svg";
import { formatDateTime } from "../common/utlis/dateHelper";
import { ApiCall } from "../../services/ApiServices";
import { CREATE_PLANNING, PLANNING_LAYOUT, PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  SUCCESS,
} from "../../Constants";
import EmployeeLayoutRow from "./EmployeeLayoutRow";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import {
  updatesalaryFormat,
} from "./Services/PlanningSalaryHelper";
import CancelPlanningPopup from "./CancelPlanningPopup";
import EditPlanningPopup from "./EditPlanningPopup";
import CustomNotify from "../common/atoms/CustomNotify";
import NoRecords from "../common/atoms/NoRecords";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import StatusCircle from "../common/atoms/StatusCircle";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import EditIcon from "../common/molecules/Actions/CustomEdit";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomOverlayTrigger from "../common/molecules/Actions/CustomOverlayTrigger";
import { selectAuth } from "../../features/auth/AuthSlice";
import SortableHeader from "../common/molecules/SortableHeader";
// Add these interfaces
interface SortConfig {
  key: string;
  direction: 'asc' | 'desc';
}

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}
interface TimeRegistration {
  end_time: string;
  sequence: string;
  start_time: string;
  time_registration_id: number;
  total_break: string;
  total_count: string;
}

interface EventData {
  length: number;
  map(arg0: (employee: EventData) => React.JSX.Element): React.ReactNode;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  function_id: number;
  planning_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  project_name: string | null;
  cost_center_name: string | null;
  location_id: number;
  total_break: number;
  total_hrs: number;
  schedule: Timing[];
  time_registration: TimeRegistration[];
  tr_status?: string;
}
interface PlanningTableProps {
  selectedDate?: Date;
  page?: string;
  companyId: number;
  selectedLocation?: {
    // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  selectedEmployees?: EventData[];
  updateEmployeeSchedule: (employeeIds: number[]) => void;
  removeEmployee?: (employeeId: number) => void
}
interface Shift {
  start_time: string;
  end_time: string;
  sequence: number;
  // Add other shift fields if necessary
}

interface Schedule {
  shifts: Shift[];
  project_id?: number;
  cost_center_id?: number;
  error?: "";
  warning?: [];
}

interface PlanningLayoutData {
  planning_id?: number;
  planning_employee_detail_id?: number;
  employee_id: number;
  employee_name?: string;
  employee_type_id?: number;
  employee_type?: string;
  company_id?: number;
  age: number;
  location_id?: number;
  pc_id?: number;
  pc?: string;
  function_id?: number;
  function?: string;
  salary?: string;
  start_date?: string;
  end_date?: string;
  schedule: {
    [date: string]: Schedule;
  };
}
const PlanningTable: React.FC<PlanningTableProps> = ({
  page = "default",
  selectedDate,
  companyId,
  selectedLocation,
  selectedEmployees = undefined,
  updateEmployeeSchedule,
  removeEmployee,
}) => {
  const imageStyle = useResponsiveValue("8vw", "5vw", "2vw");
  const [data, setData] = useState<EventData[]>([]);
  const [layoutData, setLayoutData] = useState<PlanningLayoutData[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  // Add new state for multiselect
  const [selectedForMultiSend, setSelectedForMultiSend] = useState<number[]>([]);
  const [isProcessingMultiSend, setIsProcessingMultiSend] = useState(false);
  const [cancel, setCancel] = useState<EventData | null>(null);
  const [edit, setEdit] = useState<EventData | null>(null);
  const authData = useSelector(selectAuth);
  /**Auto refresh */
  const [autorefresh, setAutorefresh] = useState(false);
  // Add this state for sorting
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'name',
    direction: 'asc'
  });

  // Add this sorting function
  const sortData = (data: EventData[], sortConf: { key: string; direction: "asc" | "desc"; }): EventData[] => {
    if (!data) return [];

    return [...data].sort((a, b) => {
      switch (sortConfig.key) {
        case 'name':
          return sortConfig.direction === 'asc'
            ? a.name.localeCompare(b.name)
            : b.name.localeCompare(a.name);
        case 'statute':
          return sortConfig.direction === 'asc'
            ? a.employee_type.localeCompare(b.employee_type)
            : b.employee_type.localeCompare(a.employee_type);
        case 'function':
          return sortConfig.direction === 'asc'
            ? a.function_name.localeCompare(b.function_name)
            : b.function_name.localeCompare(a.function_name);
        case 'pc':
          return sortConfig.direction === 'asc'
            ? a.pc_name.localeCompare(b.pc_name)
            : b.pc_name.localeCompare(a.pc_name);
        case 'salary':
          return sortConfig.direction === 'asc'
            ? parseFloat(a.salary) - parseFloat(b.salary)
            : parseFloat(b.salary) - parseFloat(a.salary);
        case 'totalBreak':
          return sortConfig.direction === 'asc'
            ? (a.total_break || 0) - (b.total_break || 0)
            : (b.total_break || 0) - (a.total_break || 0);
        case 'totalHours':
          return sortConfig.direction === 'asc'
            ? (a.total_hrs || 0) - (b.total_hrs || 0)
            : (b.total_hrs || 0) - (a.total_hrs || 0);
        case 'startShift1':
          const aStart1 = a.schedule[0]?.shift_starttime || '00:00';
          const bStart1 = b.schedule[0]?.shift_starttime || '00:00';
          return sortConfig.direction === 'asc'
            ? aStart1.localeCompare(bStart1)
            : bStart1.localeCompare(aStart1);

        case 'endShift1':
          const aEnd1 = a.schedule[0]?.shift_endtime || '00:00';
          const bEnd1 = b.schedule[0]?.shift_endtime || '00:00';
          return sortConfig.direction === 'asc'
            ? aEnd1.localeCompare(bEnd1)
            : bEnd1.localeCompare(aEnd1);

        case 'startShift2':
          const aStart2 = a.schedule[1]?.shift_starttime || '00:00';
          const bStart2 = b.schedule[1]?.shift_starttime || '00:00';
          return sortConfig.direction === 'asc'
            ? aStart2.localeCompare(bStart2)
            : bStart2.localeCompare(aStart2);

        case 'endShift2':
          const aEnd2 = a.schedule[1]?.shift_endtime || '00:00';
          const bEnd2 = b.schedule[1]?.shift_endtime || '00:00';
          return sortConfig.direction === 'asc'
            ? aEnd2.localeCompare(bEnd2)
            : bEnd2.localeCompare(aEnd2);
        default:
          return 0;
      }
    });
  };

  setTimeout(() => {
    setAutorefresh(!autorefresh);
  }, 60000);

  const show = useSelector(selectPlanningShow); // Access the `show` state

  const fetchData = async () => {
    if (selectedDate !== undefined) {
      setCancel(null); // Reset cancel state after refreshing
      const currentDate = formatDateTime(selectedDate);

      try {
        const dataObj = {
          company_id: companyId,
          location_id: selectedLocation?.id,
          start_date: currentDate,
          end_date: currentDate,
        };
        const result = await ApiCall.service(
          PLANNING_OVERVIEW,
          "POST",
          dataObj,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (result.status === "success") {
          setRefresh(false);

          const fetchedData = result.data[currentDate] || [];

          // Exclude selected employees from the fetched data
          const selectedEmployeeIds = selectedEmployees?.map((emp) => emp.id) || [];
          const filteredData = fetchedData.filter(
            (employee: EventData) => !selectedEmployeeIds.includes(employee.employee_id)
          );

          setData(filteredData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  const handleRemoveEmployee = (employeeId: number) => {
    // First remove from layout data
    setLayoutData((prevLayoutData) =>
      prevLayoutData.filter((emp) => emp.employee_id !== employeeId)
    );

    // Remove from selected employees
    if (removeEmployee) {
      removeEmployee(employeeId);
    }

    // Update employee schedule
    updateEmployeeSchedule([employeeId]);

    // Refresh the data
    setRefresh(true);
  };

  useEffect(() => {
    fetchData(); // Call fetchData whenever selectedDate changes
  }, [selectedDate, selectedLocation, refresh, autorefresh, selectedEmployees]);
  useEffect(() => {
    if (selectedDate !== undefined && selectedEmployees !== undefined) {
      const currentLayoutEmployeeIds = layoutData.map((emp) => emp.employee_id);
      const selectedEmployeeIds = selectedEmployees.map((emp) => emp.id);

      // Find employees to add (in selectedEmployees but not in layoutData)
      const employeesToAdd = selectedEmployees.filter(
        (emp) => !currentLayoutEmployeeIds.includes(emp.id)
      );

      // Find employees to remove (in layoutData but not in selectedEmployees)
      const employeesToRemove = layoutData.filter(
        (emp) => !selectedEmployeeIds.includes(emp.employee_id)
      );

      // Remove deselected employees from layoutData
      if (employeesToRemove.length > 0) {
        setLayoutData((prevLayoutData) =>
          prevLayoutData.filter(
            (emp) =>
              !employeesToRemove.some(
                (removeEmp) => removeEmp.employee_id === emp.employee_id
              )
          )
        );
      }

      const fetchLayoutData = async () => {
        try {
          if (employeesToAdd.length > 0) {
            const dataObj = {
              company_id: companyId,
              location_id: selectedLocation?.id,
              dates: [formatDateTime(selectedDate)],
              employee_ids: employeesToAdd.map((emp) => emp.id),
            };
            const result = await ApiCall.service(
              PLANNING_LAYOUT,
              "POST",
              dataObj,
              true,
              CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            if (result.status === "success") {
              if (Array.isArray(result.data)) {
                setLayoutData((prev) => [
                  ...prev, // Spread the previous state
                  ...result.data, // Spread the new data
                ]);

                // Filter out these employees from data
                const newLayoutEmployeeIds = result.data.map(
                  (emp: PlanningLayoutData) => emp.employee_id
                );
                setData((prevData) =>
                  prevData?.filter(
                    (emp) => !newLayoutEmployeeIds.includes(emp.employee_id)
                  )
                );
              } else {
                console.error("Error fetching data:", result.message);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchLayoutData();
    } else if (selectedEmployees == null) {
      setLayoutData([]);
    }
  }, [selectedEmployees]); // Added other dependencies
  const handleRefresh = (employeeIds: number[]) => {
    setLayoutData((prevLayoutData) =>
      prevLayoutData.filter((emp) => !employeeIds.includes(emp.employee_id))
    );
    updateEmployeeSchedule(employeeIds);
    setRefresh(true);
  };
  const validateEmployeeAndUpdateSelection = useCallback((employeeId: number) => {
    const employee = layoutData.find(emp => emp.employee_id === employeeId);
    console.log(employee);
    if (!employee) {
      setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
      console.log('1');
      return false;
    }

    // Check for required fields
    if (!employee.employee_type || !employee.function || !employee.pc || !employee.salary) {
      setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
      console.log('2');
      return false;
    }

    // Check schedule errors
    for (const dateSchedule of Object.values(employee.schedule)) {
      if (dateSchedule.error) {
        setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
        console.log('3');
        return false;
      }

      // Check if shifts are properly filled
      if (!dateSchedule.shifts || dateSchedule.shifts.length === 0) {
        setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
        console.log('4');
        return false;
      }

      for (const shift of dateSchedule.shifts) {
        if (!shift.start_time || !shift.end_time) {
          setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
          console.log('6');
          return false;
        }
      }
    }

    return true;
  }, [layoutData]);

  const groupAndSortData = (data: EventData[], sortConf: { key: string; direction: 'asc' | 'desc' }) => {
    const groupedData: { [key: string]: EventData[] } = {};

    data.forEach((emp) => {
      const groupKey = emp.cost_center_name
        ? t(`Cost center: `)+emp.cost_center_name
        : emp.project_name
          ? t(`Project: `)+ emp.project_name
          : t("No Cost Center / Project");

      if (!groupedData[groupKey]) {
        groupedData[groupKey] = [];
      }
      groupedData[groupKey].push(emp);
    });

    // Sort each group using the provided sorting function
    Object.keys(groupedData).forEach((key) => {
      groupedData[key] = sortData(groupedData[key], sortConf);
    });

    return groupedData;
  };



  // Add effect to validate all selected employees when layoutData changes
  useEffect(() => {
    selectedForMultiSend.forEach(employeeId => {
      validateEmployeeAndUpdateSelection(employeeId);
    });
  }, [layoutData, validateEmployeeAndUpdateSelection]);

  // Update multi-select handler
  const handleMultiSelectChange = useCallback((employeeId: number, isSelected: boolean) => {
    if (isSelected) {
      // Only add if validation passes
      if (validateEmployeeAndUpdateSelection(employeeId)) {
        setSelectedForMultiSend(prev => [...prev, employeeId]);
      } else {
        // Show notification about why it wasn't selected
        CustomNotify({
          type: "warning",
          message: t("Employee cannot be selected due to missing information or errors"),
          autoClose: 2500,
        });
      }
    } else {
      // Always allow deselection
      setSelectedForMultiSend(prev => prev.filter(id => id !== employeeId));
    }
  }, [validateEmployeeAndUpdateSelection]);

  // Update bulk send handler to only send valid employees
  // Update bulk send handler
  const handleBulkSend = useCallback(async () => {
    if (selectedForMultiSend.length === 0) {
      CustomNotify({
        type: "warning",
        message: t("Please select at least one employee"),
        autoClose: 2500,
      });
      return;
    }

    setIsProcessingMultiSend(true);

    try {
      // No need to filter again as invalid employees are already removed
      const selectedEmployeesData = layoutData.filter(emp =>
        selectedForMultiSend.includes(emp.employee_id)
      );

      const response = await ApiCall.service(
        CREATE_PLANNING,
        "POST",
        {
          company_id: Number(companyId),
          user_id: authData?.userId,
          planning: selectedEmployeesData,
        },
        false
      );

      if (response.status === SUCCESS) {
        CustomNotify({
          type: "success",
          message: t(`Successfully sent planning for ${selectedForMultiSend.length} employees`),
          autoClose: 2500,
        });

        updateEmployeeSchedule(selectedForMultiSend);
        setSelectedForMultiSend([]);
      } else {
        CustomNotify({
          type: "error",
          message: t(response.message || "Failed to send planning"),
          autoClose: 2500,
        });
      }
    } catch (error) {
      CustomNotify({
        type: "error",
        message: t("An unexpected error occurred"),
        autoClose: 2500,
      });
    } finally {
      setIsProcessingMultiSend(false);
    }
  }, [selectedForMultiSend, layoutData, companyId, authData?.userId, updateEmployeeSchedule]);

  return (
    <>
      <table className="table planningTableWrapper table-borderless pwa">
        <thead className="positionSticky">
          <tr className="align-middle">
            <th></th>
            <SortableHeader
              label={t("Employee")}
              sortKey="name"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Employee type")}
              sortKey="statute"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Function")}
              sortKey="function"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Paritair committee")}
              sortKey="pc"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Hourly wage")}
              sortKey="salary"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Start shift")}
              sortKey="startShift1"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("End shift")}
              sortKey="endShift1"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Start shift")}
              sortKey="startShift2"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("End shift")}
              sortKey="endShift2"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Break Total")}
              sortKey="totalBreak"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <SortableHeader
              label={t("Total work done")}
              sortKey="totalHours"
              currentSort={sortConfig}
              onSort={setSortConfig}
            />
            <th>{t("Status")}</th>
            <th>{t("Action")}</th>
          </tr>
          {window.innerWidth > 1023 && <TableHeaderSpace className="bg-transparent" headerLength={15} />}
        </thead>
        <tbody>
          {/* <AccessControl
            renderNoAccess={false}
            requiredPermissions={[
              {
                permission: "planing_layout",
              },
            ]}
          > */}
          {page !== "dashboard" &&
            layoutData.length > 0 &&
            layoutData.map((emp) => (
              <EmployeeLayoutRow
                key={emp.employee_id}
                emp={emp}
                refreshOverview={(employeeIds: number[]) =>
                  handleRefresh(employeeIds)
                }
                saveLayoutData={(updatedEmployee: PlanningLayoutData) => {
                  // Update the specific employee in the layoutData
                  setLayoutData(prevLayoutData =>
                    prevLayoutData.map(employee =>
                      employee.employee_id === updatedEmployee.employee_id
                        ? updatedEmployee
                        : employee
                    )
                  );
                }}
                onMultiSelectChange={(isSelected: boolean) => {
                  console.log(isSelected);

                  handleMultiSelectChange(emp.employee_id, isSelected)
                }
                }
                isSelected={selectedForMultiSend}
                removeEmployee={handleRemoveEmployee} // Pass the new handler
              />
            ))}
          {/* </AccessControl> */}
          {data !== undefined &&
            (Object.entries(groupAndSortData(data,sortConfig)).map(([group, employees]) => (
              <>
                <tr className="group-row">
                  <td colSpan={15} className="group-header text-danger">{group}</td>
                </tr>
                <TableHeaderSpace headerLength={13} className="bg-transparent"/>
                {employees.map((emp: EventData) => (

                  <>
                    <tr key={emp.id}>
                      {window.innerWidth <= 1023 && <CustomOverlayTrigger status={emp.planning_status} />}
                      <td className="px-2">
                        <Image
                          src={emp.employee_profile_pic ?? UserImage}
                          imageWidth={imageStyle}
                          imageHeight={imageStyle}
                          imageBorderRadius="50%"
                        />
                      </td>
                      <td data-label={t("Employee")}>
                        <div
                          className="textEllipsisName textEllipsis"
                          title={emp.name}
                        >
                          {emp.name}
                        </div>
                      </td>

                      <td data-label={t("Statute")}>
                        <div
                          className="textEllipsisEmpType textEllipsis"
                          title={emp.employee_type}
                        >
                          {emp.employee_type}
                        </div>
                      </td>
                      <td data-label={t("Function")}>
                        <div
                          title={emp.function_name}
                          className="textEllipsisFunName textEllipsis"
                        >
                          {emp.function_name}
                        </div>
                      </td>
                      <td data-label={t("Paritair committee")}>
                        <div
                          title={emp.pc_name}
                          className="textEllipsisFunName textEllipsis"
                        >
                          {emp.pc_name}
                        </div>
                      </td>
                      <td data-label={t("Hourly wage")}>€{updatesalaryFormat(emp.salary)}</td>
                      <td data-label={t("Start shift")}>
                        {page === "dashboard" ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.schedule[0].shift_starttime}
                            hour={emp.schedule[0].shift_starttime.split(":")[0]}
                            minute={emp.schedule[0].shift_starttime.split(":")[0]}
                          />
                        ) : (
                          emp.schedule[0].shift_starttime
                        )}
                      </td>
                      <td data-label={t("End shift")}>
                        {page === "dashboard" ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.schedule[0].shift_endtime}
                            hour={emp.schedule[0].shift_endtime.split(":")[0]}
                            minute={emp.schedule[0].shift_endtime.split(":")[0]}
                          />
                        ) : (
                          emp.schedule[0].shift_endtime
                        )}
                      </td>
                      <td data-label={t("Start shift")}>
                        {page === "dashboard" && emp.schedule[1] != undefined ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.schedule[0].shift_starttime}
                            hour={emp.schedule[0].shift_starttime.split(":")[0]}
                            minute={emp.schedule[0]?.shift_starttime.split(":")[1]}
                          />
                        ) : emp.schedule[1] != undefined ? (
                          emp.schedule[1].shift_starttime
                        ) : (
                          "-"
                        )}
                      </td>
                      <td data-label={t("End shift")}>
                        {page === "dashboard" && emp.schedule[1] != undefined ? (
                          <TimePicker
                            setHourMin={() => { }}
                            type={""}
                            index={0}
                            value={emp.schedule[0].shift_endtime}
                            hour={emp.schedule[0].shift_endtime.split(":")[0]}
                            minute={emp.schedule[0].shift_endtime.split(":")[1]}
                          />
                        ) : emp.schedule[1] != undefined ? (
                          emp.schedule[1].shift_endtime
                        ) : (
                          "-"
                        )}
                      </td>
                      <td data-label={t("Break Total")}>
                        <div className="totalHours text-white poppins-medium">
                          {emp.total_break ?? "00:00"}
                        </div>
                      </td>
                      <td data-label={t("Total work done")}>
                        <div className="totalHours text-white poppins-medium">
                          {emp.total_hrs ?? "00:00"}
                        </div>
                      </td>
                      {window.innerWidth > 1023 && <td>
                        <div className="text-white poppins-medium">
                          <StatusCircle status={emp.planning_status} failedReason={emp.failed_reason} />
                        </div>
                      </td>}
                      {
                        emp.pdate >= formatDateTime(new Date()) &&
                          emp.tr_status != 'stop' ? ( // Check if emp.pdate is not less than the current date
                          <td className="actionWrapper px-2">

                            <div>

                              {/* Clone */}
                              <CustomActionIcon handleClick={() => {
                                CustomNotify({
                                  type: "warning",
                                  message: t(
                                    "Clone planning functionality not yet done. Please dont check this."
                                  ),
                                });
                              }}
                                title={t("Clone")}
                                iconName="CloneIcon"
                                className="me-1" />
                              {/* Edit */}
                              <EditIcon handleClick={() => setEdit(emp)} iconName="EditIcon" title={t("Edit")} />
                              {/* Remove */}
                              <CustomActionIcon handleClick={() => setCancel(emp)} iconName="RemoveIcon" title={t("Remove")} buttonClass="resetBtn" />
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )}
                    </tr>
                    {window.innerWidth > 1023 && <TableHeaderSpace
                      headerLength={15}
                      className="p-0 bg-transparent"
                    />}
                  </>
                ))}
              </>
            ))
            )}
        </tbody>
      </table>
      {selectedForMultiSend.length > 0 && (
        <div className="d-flex justify-content-end mb-3">
          <button
            className="saveBtn"
            onClick={handleBulkSend}
            disabled={isProcessingMultiSend}
          >
            {isProcessingMultiSend
              ? t("Sending...")
              : t("Send all") + ` (${selectedForMultiSend.length})`}
          </button>
        </div>
      )}
      {cancel != null && (
        <CancelPlanningPopup
          employeeData={cancel}
          clearPopup={() => {
            setCancel(null);
            fetchData(); // Refresh the overview after canceling
          }}
        />
      )}
      {edit != null && (
        <EditPlanningPopup
          employeeData={edit}
          clearPopup={() => {
            setEdit(null);
            fetchData(); // Refresh the overview after canceling
          }}
        />
      )}
    </>
  );
};

export default translate(PlanningTable);

import React from "react";
import Header from "../../common/layout/Header";
import { t } from "../translation/Translation";
import BackLink from "../../common/atoms/BackLink";
import { useParams } from "react-router-dom";
import LabelField from "../../common/atoms/LabelField";



const ManageTravelAllowance: React.FC = () => {
    const { id } = useParams();
    return (
        <div>
            <Header
                headerName={t("Manage travel allowance")}
                headerClassName="myAccountCardTitle"
            />
            <div className="search-bar pb-md-2 h-100">
                <div className="row h-100">
                    {!id && <BackLink backLink={"/"} />}
                    {id && <BackLink backLink={`/manage-companies/${id}`} />}

                    <div className="col-8 h-100 align-self-center text-center d-block d-lg-none">
                        <LabelField title={t("This feature is currently in progress. Thank you for your patience!")} className="myAccountCardTitle mb-0" />
                    </div>
                    <p>{t("This feature is currently in progress. Thank you for your patience!")}</p>
                </div>
            </div>
        </div>

    );
};

export default ManageTravelAllowance;

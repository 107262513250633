import React from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import useWindowWidth from "../common/atoms/WindowWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";

interface InvoicesDataCardProps {
  isOutstanding: boolean;
  invoice: any;
  company: any;
  viewInvoice: (invoiceId: number) => void;
  downloadInvoice: (invoiceId: number) => void;
}

const getWeekNumber = (date: Date): number => {
  const startDate = new Date(date?.getFullYear(), 0, 1);
  const days = Math.floor(
    (date.getTime() - startDate?.getTime()) / (24 * 60 * 60 * 1000)
  );
  return Math.ceil((days + 1) / 7);
};

const InvoicesDataCard: React.FC<InvoicesDataCardProps> = ({
  isOutstanding,
  invoice,
  company,
  viewInvoice,
  downloadInvoice,
}) => {
  const tableWidth = useResponsiveValue("100%", "100%", "50%")
  const tableWidthTwo = useResponsiveValue("100%", "100%", "35%")
  const isDesktop = useWindowWidth(1024)

  const invoiceDate = new Date(invoice?.invoice_details?.invoiced_date);
  const weekNumber = getWeekNumber(invoiceDate);

  return (
    <>
      <div className="d-flex justify-content-center mx-5">
        <table className="table table-borderless mb-0 pwa">
          <tbody>
            <tr className="position-relative">
              <td
                style={{
                  width: tableWidth,
                  borderTopLeftRadius: "0.8vw",
                  borderBottomLeftRadius: "0.8vw",
                }}
                className="ps-4 p-2 text-break tableBg"
              >
                <div className="d-flex justify-content-between  align-items-center flex-wrap">
                  {isOutstanding && <div className="pendingInvoiceCircle"></div>}
                  <div className="invoiceData me-3">{t("Week")} {weekNumber}</div>
                  <div className="invoiceData me-3">{t("Company")} "{company?.search_name}"</div>
                  <div className="invoiceData me-3">{t("Listing")}: {invoice?.invoice_number}</div>

                  {/* <div className="d-flex align-items-center"> */}
                  <div className="colorPrimary me-4">
                    <span className="invoiceAmount">
                      €{new Intl.NumberFormat("de-DE").format(invoice?.invoice_details?.total)}
                    </span>
                    <span className="poppins-semibold ms-1 inClBtwFontSize">incl btw</span>
                  </div>
                  <div className="d-flex">
                    <CustomActionIcon handleClick={() => viewInvoice(invoice?.bright_id)} title={t("View")} iconName="viewIcon" className="me-4 secondaryColorHoverEffect" />
                    <CustomActionIcon handleClick={() => downloadInvoice(invoice?.bright_id)} title={t("Download")} iconName="downloadIcon" />
                  </div>
                  {/* </div> */}
                </div>
              </td>
            </tr>
            {isDesktop && <TableHeaderSpace headerLength={5} />}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default translate(InvoicesDataCard);

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
interface SortConfig {
    key: string;
    direction: 'asc' | 'desc';
}

interface SortableHeaderProps {
    label: string;
    sortKey: string;
    currentSort: SortConfig;
    onSort: (config: SortConfig) => void;
    className?: string;
}

const SortableHeader: React.FC<SortableHeaderProps> = ({
    label,
    sortKey,
    currentSort,
    onSort,
    className = ''
}) => {
    const isSorted = currentSort.key === sortKey;

    const handleClick = () => {
        if (isSorted) {
            onSort({
                key: sortKey,
                direction: currentSort.direction === 'asc' ? 'desc' : 'asc'
            });
        } else {
            onSort({
                key: sortKey,
                direction: 'asc'
            });
        }
    };

    const getSortSymbol = () => {
        if (!isSorted) return '';
        return currentSort.direction === 'asc' ? (
          <FontAwesomeIcon icon={faArrowUp} />
        ) : (
          <FontAwesomeIcon icon={faArrowDown} />
        );
    };

    return (
        <th
            className={`cursor-pointer select-none ${className}`}
            onClick={handleClick}
            style={{ cursor: 'pointer' }}
        >
            <span>
                {label}
                <span className="sortingWrapper ms-1" style={{
                    color: isSorted ? '#3b82f6' : '#9ca3af',
                    
                }}>
                    {getSortSymbol()}
                </span>
            </span>
        </th>
    );
};

export default SortableHeader;
import React, { useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { Coefficient, Deviation, RightPartProps } from "./Interfaces";

function isCoefficientArray(data: any): data is Coefficient[] {
  return data?.every?.((item: any) => item?.hasOwnProperty("coeff_name"));
}

function isDeviationArray(data: any): data is Deviation[] {
  return data?.every?.((item: any) => item?.hasOwnProperty("deviation_name"));
}

const formatNumber = (value: number | string) => {
  if (value === null || value === undefined) return "-";
  return new Intl.NumberFormat("de-DE", {
    minimumFractionDigits: 4,
    maximumFractionDigits: 4,
  })?.format?.(Number(value));
};

const RightPart: React.FC<RightPartProps> = ({ data, onValueChange }) => {
  const [errors, setErrors] = useState<Record<string, string>>({});

  const uniqueNames = Array.from(
    new Set(
      Object.values?.(data)?.flatMap?.((list: any[]) =>
        list?.map?.((item: any) => item?.coeff_name || item?.deviation_name)
      )
    )
  );

  const validateValue = (value: string, high: number | null, low: number | null) => {
    if (high === null || low === null) return ""; // Skip validation if high or low is null

    const numValue = Number(value?.replace(",", "."));
    if (isNaN?.(numValue)) return "Invalid number";
    if (numValue > high) return `Value must be less than ${formatNumber(high)}`;
    if (numValue < low) return `Value must be greater than ${formatNumber(low)}`;
    return "";
  };

  const handleInput = (e: React.FocusEvent<HTMLDivElement>, employeeType: string, name: string) => {
    const newValue = e?.currentTarget?.innerText?.trim();
    const errorKey = `${employeeType}-${name}`;

    const employeeData = data?.[employeeType];
    let item: any;

    if (isCoefficientArray?.(employeeData)) {
      item = employeeData?.find((item: Coefficient) => item?.coeff_name === name);
    } else if (isDeviationArray?.(employeeData)) {
      item = employeeData?.find((item: Deviation) => item?.deviation_name === name);
    }

    if (item?.high !== null && item?.low !== null) {
      const error = validateValue?.(newValue, item?.high, item?.low);

      if (error) {
        setErrors?.((prev) => ({ ...prev, [errorKey]: error }));
        return;
      } else {
        setErrors?.((prev) => {
          const newErrors = { ...prev };
          delete newErrors?.[errorKey];
          return newErrors;
        });
      }
    }

    if (onValueChange) {
      onValueChange?.(employeeType, name, newValue);
    }
  };

  const clearErrorOnEdit = (employeeType: string, name: string) => {
    const errorKey = `${employeeType}-${name}`;
    if (errors?.[errorKey]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors?.[errorKey];
        return newErrors;
      });
    }
  };

  return (
    <table className="table">
      <thead>
        <tr className="TableHeader" style={{ height: "4.575vw" }}>
          {Object.keys(data)?.map?.((employeeType, index) => (
            <th key={index}>{t(employeeType)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {uniqueNames?.map((name, rowIndex) => (
          <tr key={rowIndex}>
            {Object.keys(data)?.map?.((employeeType: string, colIndex) => {
              const employeeData = data?.[employeeType];
              let item: Coefficient | Deviation | undefined;

              if (isCoefficientArray?.(employeeData)) {
                item = employeeData?.find(
                  (item: Coefficient) => item?.coeff_name === name
                );
              } else if (isDeviationArray(employeeData)) {
                item = employeeData?.find(
                  (item: Deviation) => item?.deviation_name === name
                );
              }

              const value = item?.value ?? "-";
              const formattedValue = formatNumber?.(value);
              const errorKey = `${employeeType}-${name}`;
              const error = errors[errorKey];

              return (
                <td key={`${rowIndex}-${colIndex}`} className="relative focus-ring shadow-none">
                  <div
                    contentEditable
                    suppressContentEditableWarning
                    onClick={() => clearErrorOnEdit?.(employeeType, name)}
                    onBlur={(e) => handleInput?.(e, employeeType, name)}
                    className="focus-ring shadow-none"
                  >
                    {formattedValue}
                  </div>
                  {error && (
                    <div className="text-danger">
                      {error}
                    </div>
                  )}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default translate(RightPart);
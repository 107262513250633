import React, { useEffect, useState, ChangeEvent } from "react";
import LabelField from "../../../common/atoms/LabelField";
import { t } from "../../../common/atoms/translation";
import Header from "../../../common/layout/Header";
import EmployeeAddress from "../organisms/EmployeeAddress";
import EmployeeSalaryInfo from "../organisms/EmployeeSalaryInfo";
import EmployeeProfile from "../organisms/EmployeeProfile";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../common/atoms/Button";
import EmergencyContact from "../organisms/EmergencyContact";
import * as ROUTE from "../../../../routes/RouteConstants";
import {
  COUNTRY_LIST,
  SSNR_DETAILS,
  EMPLOYEE_REGISTRATION,
  UPDATE_EMPLOYEE,
  EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
  REGISTER_COUNTRY_LIST,
  REGISTER_SETTINGS_LIST,
  SSNR_DETAILS_WITHOUT_LOGIN,
  VERIFY_EMAIL,
  VERIFY_EMAIL_WITHOUT_LOGIN,
  CALCULATED_GROUP_OF_MINIMUM_SALARIES,
} from "../../../../routes/ApiEndpoints";
import { ApiCall } from "../../../../services/ApiServices";
import { isValidIBANNumber } from "../organisms/isValidIBANNumber";
import { useSelector } from "react-redux";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import EmployeeAgreements from "../organisms/EmployeeAgreements";
import {
  Address,
  AddressDetails,
  Countries,
  DefaultEmpLegal,
  EmployeeFormData,
  Option,
  OptionsData,
} from "../../registration/Interfaces";
import {
  defaultDomicileAddress,
  defaultOptionData,
  errorFieldMapping,
  getDefaultFormValues,
  tabFieldMapping,
} from "../../registration/State";
import { formValidation, saveAsDraftFormValidation, setResponseData, updateField } from "../Service";
import {
  fetchBicForIban,
  validateDOB,
  validateSSNNumber,
} from "../../../../services/ValidationService";
import {
  formatRegistryNumber,
  showNotification,
  uploadFile,
} from "../../../../services/common/CommonServices";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import { selectSettings } from "../../../../features/settings/settingSlice";
import { Tabs, Tab } from "react-bootstrap";
import IDFileUploads from "../organisms/IDFileUploads";
import EmployeePersonal from "../organisms/EmployeePersonal";
import "../../css/EmployeeRegistration.css";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import BackLink from "../../../common/atoms/BackLink";
import Icon from "../../../../Icon";
import { useResponsiveValue } from "../../../common/atoms/ResponsivWidth";

const RegisterEmployeeTemplate = () => {
  const navigationHeight = useResponsiveValue("10vw", "6vw", "2vw");
  const iconSize = useResponsiveValue("5vw", "3vw", "1.25vw");
  const navigator = useNavigate();
  const [activeKey, setActiveKey] = useState<string>("profile"); // Default active tab
  const tabOrder = [
    "profile",
    "personalAndEmergencyContact",
    "address",
    "other",
  ];
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const classname = searchParams.get("type") === "login" ? "mx-5" : "";
  const emailFromUrl = searchParams.get("email");   // It will used when user is redirected to registration page from invite employee page
  const url = new URL(window.location.href);
  const updateId = url.pathname.split("/").pop()?.trim();
  const pageTitle =
    updateId === "employee-my-account"
      ? "My account"
      : updateId && !isNaN(Number(updateId))
        ? "Edit Employee"
        : "Employee registration";

  const user = useSelector(selectAuth);
  const settings = useSelector(selectSettings);
  const [state, setState] = useState<EmployeeFormData>(getDefaultFormValues);
  const [countryOptions, setCountryOptions] = useState<Countries>({
    countries: [],
  });
  const [optionsData, setOptionsData] =
    useState<OptionsData>(defaultOptionData);
  const [actualDisable, setActualDisable] = useState<boolean[]>([]);
  const goToNextTab = () => {
    const currentIndex = tabOrder.indexOf(activeKey);
    if (currentIndex < tabOrder.length - 1) {
      setActiveKey(tabOrder[currentIndex + 1]);
    }
  };

  const goToPreviousTab = () => {
    const currentIndex = tabOrder.indexOf(activeKey);
    if (currentIndex > 0) {
      setActiveKey(tabOrder[currentIndex - 1]);
    }
  };

  // For Update Employee getting employee details from API
  const fetchEmployeeDetails = async (employeeId: any) => {
    try {
      const response = await ApiCall.getService(
        `${UPDATE_EMPLOYEE}/${employeeId}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      const formattedNumber: string = formatRegistryNumber(
        response?.data?.registry_number
      );
      setState(setResponseData(response?.data, formattedNumber));
      // Update disable state based on the fetched data
      if (response?.data?.default_emp_legal) {  // if data come by ascending order it will work
        const updatedDisableState = response.data.default_emp_legal.map(
          (item: any) =>
            item?.id !== null &&
            item?.actual_salary?.trim() !== ""
        );
        console.log("updatedDisableState", updatedDisableState);
        setActualDisable(updatedDisableState);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch options data from country-list, settings-list API
  const fetchCountryOptions = async () => {
    let result;
    try {
      if (user.token) {
        result = await ApiCall.getService(COUNTRY_LIST, "GET");
      } else {
        result = await ApiCall.getService(REGISTER_COUNTRY_LIST, "GET");
      }
      setCountryOptions({
        countries: result?.data || [],
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchOptionsData = async () => {
    let result;
    if (user.token) {
      result = settings;
    } else {
      try {
        const response = await ApiCall.getService(
          REGISTER_SETTINGS_LIST,
          "GET"
        );
        result = response?.data;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    setOptionsData({
      initial: result?.initial || [],
      gender: result?.gender || [],
      education: result?.education || [],
      civil_status: result?.marital_status || [],
      dependent_spouce: result?.dependent_spouse || [],
      driving_license: result?.driving_license || [],
      languages: result?.languages || [],
      employee_type_groups: result?.employee_type_groups || [],
      transportations: result?.transportations || [],
      ibancountries: result?.iban_countries || [],
    });
  };

  useEffect(() => {
    console.log("updateId", updateId);
    if (updateId && !isNaN(Number(updateId))) {
      fetchEmployeeDetails(updateId);
    } else if (updateId === "employee-my-account") {
      fetchEmployeeDetails(user?.userId);
    }
    fetchCountryOptions();
    fetchOptionsData();

    if (emailFromUrl) {
      handleVerifyEmail(emailFromUrl);
    }
  }, []);

  useEffect(() => {
    if (activeKey === "other") {
      const employeesToSend = state.default_emp_legal
        .map((employee, index) => ({ ...employee, originalIndex: index })) // Attach the original index
        .filter(
          (employee) =>
            employee.employee_type_id !== null &&
            employee.function_id !== null &&
            [55, 57, 58, 59].includes(employee.employee_type_id) &&
            employee.company_id !== null // Ensure valid company_id
        )
        .map((employee) => ({
          index: employee.originalIndex, // Use the original index from the mapped object
          id: state.id ?? -999, // Use -999 if `id` is null
          paritaire_commitee_id: employee.paritaire_commitee_id,
          company_id: employee.company_id,
          employee_type_id: employee.employee_type_id,
          function_id: employee.function_id,
          dob: state.date_of_birth, // Add DOB if needed
        }));

      if (employeesToSend.length > 0) {
        const body = { employees: employeesToSend };
        const fetchSalaries = async () => {
          try {
            const response = await ApiCall.service(
              CALCULATED_GROUP_OF_MINIMUM_SALARIES,
              "POST",
              body
            );

            if (response?.status === "success" && response?.data) {
              const updatedEmployees = [...state.default_emp_legal];
              response?.data?.forEach(
                (salaryData: { index: number; salary: string }) => {
                  const { index, salary } = salaryData;
                  if (updatedEmployees[index]) {
                    updatedEmployees[index].minWage = salary;
                  }
                }
              );
              setState((prevState) => ({
                ...prevState,
                default_emp_legal: updatedEmployees,
              }));
            }
          } catch (error) {
            console.error("Failed to fetch salaries:", error);
          }
        };

        fetchSalaries();
      }
    }
  }, [activeKey === "other"]);

  // Validate IBAN
  const validateIban = async (iban: string) => {
    const validationError = isValidIBANNumber(iban); // From ValidationService.ts
    if (validationError) {
      setFormErrors("bank_account.iban", validationError);
      setState((prevData) => ({
        ...prevData,
        bank_account: {
          ...prevData.bank_account,
          bic: "",
        },
      }));
      return;
    }
    setFormErrors("bank_account.iban", null);

    // Fetch BIC if IBAN is valid
    await handleIbanValidation(iban);
  };

  // Function to handle IBAN validation and fetching BIC
  const handleIbanValidation = async (iban: string) => {
    try {
      // Fetch BIC for IBAN
      const ibanData = await fetchBicForIban(iban);

      // Validate IBAN and update state based on the result
      if (ibanData.valid && ibanData?.bankData?.bic !== undefined) {
        // Check if bankData.bic is defined
        setState((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: ibanData?.bankData?.bic,
          },
          is_iban_not_valid: false,
        }));

        // Clear any IBAN/BIC errors
        setFormErrors("bank_account.iban", null);
        setFormErrors("bank_account.bic", null);
      } else {
        // Clear BIC in form state and set error
        let is_iban_not_validation = false;
        if (!ibanData.valid) {
          is_iban_not_validation = true;
          setFormErrors(
            "bank_account.iban",
            t("Please enter a valid IBAN number.")
          );
        }
        setState((prevData) => ({
          ...prevData,
          bank_account: {
            ...prevData.bank_account,
            bic: "",
          },
          is_iban_not_valid: is_iban_not_validation,
        }));
      }
    } catch (error) {
      console.log("bank_account.bic : ", error);
    }
  };

  // Handle updates for non-default_emp_legal fields
  const handleSelectChange = (
    selectedOption: Option | null,
    fieldPath: string
  ) => {
    const value = selectedOption ? selectedOption.value : null;
    setState((prevFormData) => {
      return updateField(prevFormData, fieldPath, value);
    });
  };

  // Function to handle toggle
  const handleToggle =
    (field: keyof EmployeeFormData, subfield?: string) => (value: boolean) => {
      setState((prevData) => ({
        ...prevData,
        [field]: subfield
          ? {
            ...(prevData[field] as object),
            [subfield]: value, // Handle nested subfield
          }
          : value, // Handle top-level field
      }));
    };

  // Function to handle address toggle
  const handleAddressToggle = () => {
    setState((prevData) => ({
      ...prevData,
      same_address: !prevData.same_address,
      address: {
        ...prevData.address,
        domicile: !prevData.same_address
          ? {
            id: prevData.address.domicile.id, // Keep the previous domicile id
            street: prevData.address.residence.street,
            number: prevData.address.residence.number,
            postal_code: prevData.address.residence.postal_code,
            box: prevData.address.residence.box,
            city: prevData.address.residence.city,
            country_id: prevData.address.residence.country_id,
            latitude: prevData.address.residence.latitude,
            longitude: prevData.address.residence.longitude,
          }
          : {
            ...defaultDomicileAddress, // Use default values for other properties
            id: prevData.address.domicile.id, // Keep the previous domicile id
          },
      },
    }));
  };

  const handleDateFormat = (dateValue: Date | null, field: string) => {
    const formattedDate = dateValue
      ? new Date(
        new Date(dateValue).getTime() -
        new Date(dateValue).getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      : null;

    const fieldParts = field.split(".");
    const updateState = (state: any, value: any) => {
      let currentState = state;
      for (let i = 0; i < fieldParts.length - 1; i++) {
        currentState[fieldParts[i]] = currentState[fieldParts[i]] || {};
        currentState = currentState[fieldParts[i]];
      }
      currentState[fieldParts[fieldParts.length - 1]] = value;
      return state;
    };

    setState((prevState: any) => {
      const updatedState = updateState({ ...prevState }, formattedDate);

      // Validate `date_of_birth` only when it changes
      if (field === "date_of_birth" && formattedDate) {
        updatedState.errors = {
          ...updatedState.errors,
          date_of_birth: validateDOB(formattedDate, 15),  // Pass the formatted date and minimum ristricted age 
        };
      }

      return updatedState;
    });
  };

  const handleChange =
    <
      T extends keyof EmployeeFormData,
      K extends keyof DefaultEmpLegal // Add generic K for DefaultEmpLegal keys
    >(
      field: T,
      subfield?: T extends "default_emp_legal" ? K : keyof EmployeeFormData[T], // Conditional type for subfield
      nestedSubfield?: keyof AddressDetails,
      index?: number
    ) =>
      (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { type, value, checked } = event.target as HTMLInputElement;
        const inputValue = type === "checkbox" ? checked : value;

        if (state.same_address && subfield === "domicile") {
          state.same_address = false;
        }

        setState((prevData) => {
          // Other cases (like 'address')
          if (field === "address" && subfield && nestedSubfield) {
            // Update the 'residence' or 'domicile' first
            const updatedAddress = {
              ...prevData.address,
              [subfield as keyof Address]: {
                ...(prevData.address[
                  subfield as keyof Address
                ] as AddressDetails),
                [nestedSubfield]: inputValue,
              },
            };

            // If 'same_address' is true and we're not updating 'domicile', update 'domicile' to match 'residence'
            if (prevData.same_address && subfield !== "domicile") {
              updatedAddress.domicile = {
                ...updatedAddress.residence, // Copy all properties from residence
                id: prevData.address.domicile.id, // Keep the previous domicile id
              };
            }

            return {
              ...prevData,
              address: updatedAddress,
            };
          }

          // Updating other fields with subfield
          if (subfield) {
            return {
              ...prevData,
              [field]: {
                ...(prevData[field] as object),
                [subfield]: inputValue,
              },
            };
          }
          // Updating fields without subfield
          return {
            ...prevData,
            [field]: inputValue,
          };
        });
      };

  const handleVerifyEmail = async (email: string = state.verify_email) => {
    try {
      let response: any;
      const url = `${VERIFY_EMAIL}/${email}`;
      const url_with_token = `${VERIFY_EMAIL_WITHOUT_LOGIN}/${email}`;
      if (user.token) {
        response = await ApiCall.getService(url, "GET");
      } else {
        response = await ApiCall.getService(url_with_token, "GET");
      }
      if (response.status === "success") {
        if (email === state.verify_email) {
          setFormErrors("verify_email", null);
        }
        const formattedNumber: string = formatRegistryNumber(
          response?.data?.registry_number
        );
        setState(setResponseData(response?.data, formattedNumber));
        // For fetch BIC while verify SSN
        if (
          response?.data?.bank_account?.iban?.length > 0 &&
          response?.data?.bank_account?.bic?.length === 0
        ) {
          let value = response?.data?.bank_account?.iban ?? "";
          let valueSend = value;
          if (
            valueSend &&
            valueSend
              ?.toUpperCase()
              ?.startsWith(response?.data?.bank_account?.iban_country_code)
          ) {
            valueSend = valueSend.slice(2);
          }
          validateIban(
            response?.data?.bank_account?.iban_country_code + valueSend
          );
        }
      } else {
        if (email === state.verify_email) {
          setFormErrors("verify_email", response?.message);
        }
      }
    } catch (error) {
      if (email === state.verify_email) {
        setFormErrors("verify_email", t("Failed to verify e-mail."));
      }
    }
  };

  //SSN Validation
  const handleVerifySSN = async () => {
    const ssnr = state.registry_number;
    // Validate the raw SSN number
    const ssnError = validateSSNNumber(ssnr);
    if (ssnError) {
      setFormErrors("registry_number", ssnError);
      return;
    }

    // API call with the cleaned SSN number
    try {
      let response: any;
      const url = `${SSNR_DETAILS}/${ssnr}`;
      const url_with_token = `${SSNR_DETAILS_WITHOUT_LOGIN}/${ssnr}`;
      if (user.token) {
        response = await ApiCall.getService(
          url,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
      } else {
        response = await ApiCall.getService(
          url_with_token,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
      }
      if (response.status === "success") {
        setFormErrors("registry_number", null);
        const formattedNumber: string = formatRegistryNumber(
          response?.data?.registry_number
        );
        setState(setResponseData(response?.data, formattedNumber));
        // For fetch BIC while verify SSN
        if (
          response?.data?.bank_account?.iban?.length > 0 &&
          response?.data?.bank_account?.bic?.length === 0
        ) {
          let value = response?.data?.bank_account?.iban ?? "";
          let valueSend = value;
          if (
            valueSend &&
            valueSend
              ?.toUpperCase()
              ?.startsWith(response?.data?.bank_account?.iban_country_code)
          ) {
            valueSend = valueSend.slice(2);
          }
          validateIban(
            response?.data?.bank_account?.iban_country_code + valueSend
          );
        }
      } else {
        setFormErrors("registry_number", response?.message);
      }
    } catch (error) {
      setFormErrors("registry_number", t("Failed to verify SSN number."));
    }
  };

  //Validation Messages
  const setFormErrors = (name: string, error: string | null) => {
    setState((prevData) => ({
      ...prevData,
      errors: {
        ...prevData.errors,
        [name]: error ?? null, // Convert null to empty string
      },
    }));
  };

  const handleFileUpload = async (
    file: File,
    name: string,
    fileType: number,
    path: string,
    fieldName: string
  ) => {
    if (name === "profile_picture")
      setState((prev) => ({ ...prev, loading: true }));

    const data = await uploadFile(file, file.name, fileType, path);
    if (data) {
      const { fileId: id, fileUrl: url } = data.data;
      setState((prevData) => ({
        ...prevData,
        [fieldName + "_url"]: url,
        [fieldName + "_id"]: id,
        errors: {
          ...prevData.errors,
          [name]: "", // Clear the error if file upload succeeds
        },
      }));
    }

    if (name === "profile_picture")
      setState((prev) => ({ ...prev, loading: false }));
  };

  const handleSaveAsDraft = async () => {
    let errors = saveAsDraftFormValidation(state, user, updateId);

    // Remove keys with null or falsy values
    Object.keys(errors).forEach((key) => {
      if (errors[key] == null) {
        delete errors[key];
      }
    });
    // Update state with validation errors
    setState((prev) => ({
      ...prev,
      errors,
    }));
    console.log("state", state);
    console.log("errors", state?.errors?.first_name);

    // Check for errors
    if (Object.keys(errors).length > 0) {
      showNotification("error", t("Please give valid details."));
      return;
    } else {
      const updatedFormData = {
        ...state,
        registry_number: state?.registry_number?.replace(/_/g, ""),
        email: state?.email?.toLowerCase(),
        confirm_email: state?.confirm_email?.toLowerCase(),
        bank_account: {
          ...state?.bank_account,
          iban: state?.bank_account?.iban
            ?.toUpperCase() // Convert IBAN to uppercase for consistency
            ?.replace(
              new RegExp(`^${state.bank_account.iban_country_code}`, "i"),
              ""
            )
            ?.replace(/[\s-]/g, ""), // Remove spaces and hyphens
        },
        default_emp_legal: state.default_emp_legal.map((item) => ({
          ...item,
          actual_salary: item?.actual_salary ? item.actual_salary : item.minWage,
          is_actual_added:
            parseFloat(item?.actual_salary) > parseFloat(item?.minWage),
        })),
        companies: state?.companies?.length === 0 ? [user?.currentCompany?.id] : state?.companies,
        status: "draft",
      };
      console.log(updatedFormData);

      if (state.id !== 0) {
        // Update employee API call
        try {
          const response = await ApiCall.service(
            `${UPDATE_EMPLOYEE}/${state.id}`,
            "PUT",
            updatedFormData,
            true
          );
          // Response handling logic
          if (
            response?.status === "success" &&
            updateId === "employee-my-account"
          ) {
            showNotification(
              "success",
              t("Account details updated successfully.")
            );
            navigator(ROUTE.LOCATION_DASHBOARD);
          } else if (response?.status === "success") {
            showNotification("success", response?.message);
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response?.status === "validation-error") {
            const { data } = response;

            // Combine error messages into a single string for notification
            const errorMessage = Object.values(data).join(", ");
            showNotification("error", errorMessage);

            // Prepare the updated errors object based on error codes and their corresponding fields
            const updatedErrors: Record<string, string> = Object.keys(
              data
            ).reduce((acc, code) => {
              const field =
                errorFieldMapping[code as keyof typeof errorFieldMapping];
              if (field) {
                acc[field] = data[code as keyof typeof data]; // Assign the error message to the mapped field
              }
              return acc;
            }, {} as Record<string, string>);
            // Update state with the prepared errors object
            setState((prevData) => ({
              ...prevData,
              errors: {
                ...prevData.errors,
                ...updatedErrors,
              },
            }));
          } else {
            showNotification("error", response?.message);
          }
        } catch (error) {
          console.error("Error updating employee:", error);
        }
      } else {
        // Create employee API call
        let response;
        try {
          if (user.token) {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION,
              "POST",
              updatedFormData,
              true
            );
            if (response?.status === "success") {
              showNotification("success", "Employee saved successfully.");
              navigator(ROUTE.EMPLOYEE_OVERVIEW);
            } else if (response.status === "validation-error") {
              const { data } = response;

              // Combine error messages into a single string for notification
              const errorMessage = Object.values(data).join(", ");
              showNotification("error", errorMessage);

              // Prepare the updated errors object based on error codes and their corresponding fields
              const updatedErrors: Record<string, string> = Object.keys(
                data
              ).reduce((acc, code) => {
                const field =
                  errorFieldMapping[code as keyof typeof errorFieldMapping];
                if (field) {
                  acc[field] = data[code as keyof typeof data]; // Assign the error message to the mapped field
                }
                return acc;
              }, {} as Record<string, string>);
              // Update state with the prepared errors object
              setState((prevData) => ({
                ...prevData,
                errors: {
                  ...prevData.errors,
                  ...updatedErrors,
                },
              }));
            } else {
              showNotification("error", response?.message);
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  const handleSubmit = async () => {
    let errors = formValidation(state, user, updateId);

    // Remove keys with null or falsy values
    Object.keys(errors).forEach((key) => {
      if (errors[key] == null) {
        delete errors[key];
      }
    });
    // Update state with validation errors
    setState((prev) => ({
      ...prev,
      errors,
    }));
    console.log("state", state);

    // Check for errors
    if (Object.keys(errors).length > 0) {
      showNotification("error", t("Please give valid details."));
      return;
    } else {
      //API call for check registry number to there or not
      const updatedFormData = {
        ...state,
        registry_number: state.registry_number.replace(/_/g, ""),
        email: state.email.toLowerCase(),
        confirm_email: state.confirm_email.toLowerCase(),
        bank_account: {
          ...state.bank_account,
          iban: state.bank_account.iban
            .toUpperCase() // Convert IBAN to uppercase for consistency
            .replace(
              new RegExp(`^${state.bank_account.iban_country_code}`, "i"),
              ""
            )
            .replace(/[\s-]/g, ""), // Remove spaces and hyphens
        },
        default_emp_legal: state.default_emp_legal.map((item) => ({
          ...item,
          actual_salary: item.actual_salary ? item.actual_salary : item.minWage,
          is_actual_added:
            parseFloat(item.actual_salary) > parseFloat(item.minWage),
        })),
        status: state?.status === "draft" ? "active" : state?.status,
      };
      if (state.id !== 0) {
        // Update employee API call
        try {
          const response = await ApiCall.service(
            `${UPDATE_EMPLOYEE}/${state.id}`,
            "PUT",
            updatedFormData,
            true
          );
          // Response handling logic
          if (
            response?.status === "success" &&
            updateId === "employee-my-account"
          ) {
            showNotification(
              "success",
              t("Account details updated successfully.")
            );
            navigator(ROUTE.LOCATION_DASHBOARD);
          } else if (response?.status === "success") {
            showNotification("success", response?.message);
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response?.status === "validation-error") {
            const { data } = response;

            // Combine error messages into a single string for notification
            const errorMessage = Object.values(data).join(", ");
            showNotification("error", errorMessage);

            // Prepare the updated errors object based on error codes and their corresponding fields
            const updatedErrors: Record<string, string> = Object.keys(
              data
            ).reduce((acc, code) => {
              const field =
                errorFieldMapping[code as keyof typeof errorFieldMapping];
              if (field) {
                acc[field] = data[code as keyof typeof data]; // Assign the error message to the mapped field
              }
              return acc;
            }, {} as Record<string, string>);
            // Update state with the prepared errors object
            setState((prevData) => ({
              ...prevData,
              errors: {
                ...prevData.errors,
                ...updatedErrors,
              },
            }));
          } else {
            showNotification("error", response?.message);
          }
        } catch (error) {
          console.error("Error updating employee:", error);
        }
      } else {
        // Create employee API call
        let response;
        try {
          if (user.token) {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION,
              "POST",
              updatedFormData,
              true
            );
          } else {
            response = await ApiCall.service(
              EMPLOYEE_REGISTRATION_WITHOUT_LOGIN,
              "POST",
              updatedFormData,
              true
            );
          }
          if (response?.status === "success") {
            showNotification("success", response?.message);
            navigator(ROUTE.EMPLOYEE_OVERVIEW);
          } else if (response.status === "validation-error") {
            const { data } = response;

            // Combine error messages into a single string for notification
            const errorMessage = Object.values(data).join(", ");
            showNotification("error", errorMessage);

            // Prepare the updated errors object based on error codes and their corresponding fields
            const updatedErrors: Record<string, string> = Object.keys(
              data
            ).reduce((acc, code) => {
              const field =
                errorFieldMapping[code as keyof typeof errorFieldMapping];
              if (field) {
                acc[field] = data[code as keyof typeof data]; // Assign the error message to the mapped field
              }
              return acc;
            }, {} as Record<string, string>);
            // Update state with the prepared errors object
            setState((prevData) => ({
              ...prevData,
              errors: {
                ...prevData.errors,
                ...updatedErrors,
              },
            }));
          } else {
            showNotification("error", response?.message);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    }
  };

  const updatePcValues = (updatedState: any) => {
    setState((prevState: EmployeeFormData) => ({
      ...prevState,
      companies: updatedState.companies,
      default_emp_legal: updatedState.default_emp_legal,
      errors: updatedState.errors,
      missing_companies: updatedState.missing_companies,
    }));
  };
  const handleFileUploadResponse = (uploadResponse: any) => {
    if (uploadResponse?.response) {
      const name = uploadResponse?.response?.data?.name;
      setState((prevData) => ({
        ...prevData,
        [name + "_url"]: uploadResponse?.response?.data?.fileUrl,
        [name + "_id"]: uploadResponse?.response?.data?.fileId,
        [name + "_name"]: uploadResponse?.response?.data?.fileName,
        errors: {
          ...prevData.errors,
          [name]: null,
        },
      }));
    }
  };
  const handleFileDeleteResponse = (name: string) => {
    setState((prevData) => ({
      ...prevData,
      [name + "_url"]: null,
      [name + "_id"]: null,
      [name + "_name"]: null,
      errors: {
        ...prevData.errors,
        [name]: null,
      },
    }));
  };

  const selectedEmployeeTypeOption =
    optionsData?.employee_type_groups?.find(
      (option) => option.value === state?.desired_employee_type_id
    ) || null; // Use null for an optional value

  const renderEmployeeSalaryInfo =
    user.token && updateId !== "employee-my-account" ? (
      <EmployeeSalaryInfo
        user_id={state?.id}
        companies={state?.companies}
        errors={state?.errors}
        default_emp_legal={state?.default_emp_legal}
        updatePcValues={updatePcValues}
        handleSelectChange={handleSelectChange}
        missing_companies={state?.missing_companies}
        dob={state?.date_of_birth}
        default_employee_id_option={
          selectedEmployeeTypeOption ? [selectedEmployeeTypeOption] : []
        } // Ensure array of OptionType
        fetched_companies={state?.fetched_companies}
        currentPage="employee-registration"
        actualDisable={actualDisable}
        setActualDisable={setActualDisable}
        employeeStatus={state?.status}
      />
    ) : null;

  // Check if a tab has any validation errors
  const tabHasErrors = (tab: keyof typeof tabFieldMapping) => {
    return tabFieldMapping[tab]?.some((fieldPattern) => {
      if (fieldPattern.includes("{index}")) {
        // Handle dynamic indices for default_emp_legal
        const fieldParts = fieldPattern.split("{index}");

        // Check through all dynamic indices (i.e., for default_emp_legal)
        return Object.keys(state.errors).some((errorKey) => {
          // Check if the error key matches the pattern for any index
          if (
            errorKey.startsWith(fieldParts[0]) &&
            errorKey.includes(fieldParts[1])
          ) {
            return state.errors[errorKey] || false; // Return true if there is an error
          }
          return false;
        });
      }

      // Handle non-index fields directly
      return state.errors[fieldPattern] || false;
    });
  };

  const getTabClassName = (tab: any) =>
    tabHasErrors(tab) ? "border-danger border-2" : "";

  return (
    <div className={classname}>
      <Header headerName={t(pageTitle)} headerClassName="myAccountCardTitle" />
      <div className="employeeRegistrationWrapper mb-3">
        <div className="employeeBackLink">
          <div className="row">
            {activeKey === "profile" && (
              <BackLink
                backLink={
                  user.token
                    ? updateId === "employee-my-account"
                      ? "/location-dashboard"
                      : "/employee-overview"
                    : "/login"
                }
              />
            )}
          </div>
        </div>
        <Tabs
          id="employee-registration-tabs"
          activeKey={activeKey}
          onSelect={(k) => setActiveKey(k || "profile")} // Maintain default value
          className={`mb-3 border-0 d-grid`}
        >
          <Tab
            eventKey="profile"
            title="Profile"
            tabClassName={getTabClassName("profile")}
          >
            <OverFlowWrapper className="employeeRegistrationHeight">
              <div className="row">
                <div className="col-12">
                  <div className="mb-3">
                    <LabelField title={t("Profile")} className="tab-title" />
                  </div>
                </div>
              </div>
              <EmployeeProfile
                state={state}
                setState={setState}
                handleChange={handleChange}
                handleToggle={handleToggle}
                handleDateFormat={handleDateFormat}
                optionsData={optionsData}
                countryOptions={countryOptions}
                handleSelectChange={handleSelectChange}
                handleVerifySSN={handleVerifySSN}
                handleVerifyEmail={handleVerifyEmail}
                handleFileUpload={handleFileUpload}
                validateIban={validateIban}
                handleFileUploadResponse={handleFileUploadResponse}
                handleFileDeleteResponse={handleFileDeleteResponse}
              />
              <IDFileUploads
                state={state}
                setState={setState}
                handleFileUploadResponse={handleFileUploadResponse}
                handleFileDeleteResponse={handleFileDeleteResponse}
              />
            </OverFlowWrapper>
          </Tab>
          <Tab
            eventKey="personalAndEmergencyContact"
            title="Personal &amp; Emergency contacts"
            tabClassName={getTabClassName("personalAndEmergencyContact")}
          >
            <OverFlowWrapper className="employeeRegistrationHeight">
              <EmployeePersonal
                state={state}
                handleChange={handleChange}
                countryOptions={countryOptions}
                optionsData={optionsData}
                handleDateFormat={handleDateFormat}
                handleSelectChange={handleSelectChange}
              />
              <EmergencyContact state={state} handleChange={handleChange} />
            </OverFlowWrapper>
          </Tab>
          <Tab
            eventKey="address"
            title="Address"
            tabClassName={getTabClassName("address")}
          >
            <OverFlowWrapper className="employeeRegistrationHeight">
              <EmployeeAddress
                state={state}
                handleChange={handleChange}
                handleAddressToggle={handleAddressToggle}
                countryOptions={countryOptions}
                handleSelectChange={handleSelectChange}
              />
            </OverFlowWrapper>
          </Tab>
          <Tab
            eventKey="other"
            title="Other"
            tabClassName={getTabClassName("other")}
          >
            <OverFlowWrapper className="employeeRegistrationHeight">
              {renderEmployeeSalaryInfo}
              <EmployeeAgreements state={state} handleToggle={handleToggle} />
            </OverFlowWrapper>
          </Tab>
        </Tabs>
        <div
          className="row"
          style={{
            padding: "0 1vw",
            height:
              navigationHeight
          }}
        >
          <div className="col-2  align-self-center">
            {activeKey !== "profile" && (
              <span
                onClick={goToPreviousTab}
                className="backIconWrapper"
                title={t("Previous")}
              >
                <Icon
                  isIcon={true}
                  name="backIcon"
                  width={iconSize}
                  height={iconSize}
                />
              </span>
            )}
          </div>
          <div className="col-10 align-self-center">
            <div className="text-end">
              {user?.token && (
                (state.id && state.status !== "draft") ? (activeKey !== "other" &&
                  <Button
                    handleClick={handleSubmit}
                    title={t("Update")}
                    className="modalSaveBtn mobileFont me-3"
                  />
                ) : (
                  <Button
                    handleClick={handleSaveAsDraft}
                    title={t("Save")}
                    className="modalSaveBtn mobileFont me-3"
                  />
                )
              )}
              {activeKey === "other" ? (
                <Button
                  handleClick={handleSubmit}
                  title={
                    updateId === "employee-my-account"
                      ? t("Save")
                      : (state?.id !== 0 && state?.status !== "draft")
                        ? t("Update")
                        : t("Register")
                  }
                  className="modalSaveBtn mobileFont"
                />
              ) : (
                <span
                  onClick={goToNextTab}
                  className="backIconWrapper m-1"
                  title={t("Next")}
                >
                  <Icon
                    isIcon={true}
                    name="nextIcon"
                    width={iconSize}
                    height={iconSize}
                  />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RegisterEmployeeTemplate;

export interface InputField {
  label: string;
  name: keyof LocationFormData;
  id: string;
  placeholder: string;
  isMandatory?: boolean;
  type: string;
  className?: string;
  columnSize?: string; // new property to define column size
}

export interface LocationFormData {
  company_id: number | any;
  location_pic: string;
  location_name: string;
  street: string;
  number: string;
  postal_code: string;
  box: string;
  city: string;
  country_id: string;
}

export interface fileData {
  fileId: number | null;
  filePath: string;
  fileName: string;
  fileUrl: string;
}

export const intialFileData: fileData = {
  fileId: null,
  filePath: "",
  fileName: "",
  fileUrl: "",
};

export const inputFields: InputField[] = [
  {
    label: "Location name",
    name: "location_name",
    id: "locationName",
    placeholder: "Enter location name",
    isMandatory: true,
    type: "text",
    columnSize: "col-lg-6",
  },
  {
    label: "Street name",
    name: "street",
    id: "streetName",
    placeholder: "Enter street name",
    isMandatory: true,
    type: "text",
    columnSize: "col-md-6",
  },
  {
    label: "House number",
    name: "number",
    id: "houseNumber",
    placeholder: "Enter house number",
    isMandatory: true,
    type: "text",
    columnSize: "col-lg-3 col-md-6",
  },

  {
    label: "Box",
    name: "box",
    id: "box",
    placeholder: "Enter box",
    type: "text",
    columnSize: "col-lg-3 col-md-6",
  },
  {
    label: "Postal code",
    name: "postal_code",
    id: "postalCode",
    placeholder: "Enter postal code",
    isMandatory: true,
    type: "text",
    columnSize: "col-lg-4 col-md-6",
  },
  {
    label: "City",
    name: "city",
    id: "city",
    placeholder: "Enter city",
    isMandatory: true,
    type: "text",
    columnSize: "col-lg-4 col-md-6",
  },
  {
    label: "Country",
    name: "country_id",
    id: "country",
    placeholder: "Select country",
    isMandatory: true,
    type: "select",
    columnSize: "col-lg-4 col-md-6",
  },
];



export const formatBox = (value: string) => {
  // Replace all non-alphanumeric characters with an empty string
  return value.replace(/[^a-zA-Z0-9]/g, "");
};

export const formatPostalCode = (value: string) => {
  // Remove all non-numeric characters
  return value.replace(/[^0-9]/g, "");
};

export const formatHouseNumber = (value: string) => {
  // Remove any character that is not alphanumeric, space, or dot
  const cleanedValue = value.replace(/[^\w\s!@#$%^&*()\-_=+\\|[\]{};:/?.>]/g, "");
  // Trim the cleanedValue to ensure it does not exceed 50 characters
  return cleanedValue.slice(0, 50);
};

export const formatStreet = (value: string) => {
  // Allow specified special characters along with alphanumeric and space characters
  const cleanedValue = value.replace(/[^\w\s!@#$%^&*()\-_=+\\|[\]{};:/?.>]/g, "");
  // Trim the cleanedValue to ensure it does not exceed 50 characters
  return cleanedValue.slice(0, 50);
};

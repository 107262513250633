import React, { useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import UserImage from "../../static/images/User.svg";
import InputTextfield from "../common/atoms/InputTextField";
import {
  calculateShiftsTotalTime,
  formatTimeInput,
  sanitizedInputLength,
} from "./Services/PlanningTimingHelper";
import { useSelector } from "react-redux";
import { ApiCall } from "../../services/ApiServices";
import { CREATE_PLANNING } from "../../routes/ApiEndpoints";
import CustomNotify from "../common/atoms/CustomNotify";
import { selectAuth, selectCurrentCompany } from "../../features/auth/AuthSlice";
import { validatePlanningData, validatePlanningFieldsForDate } from "./Validations/PlanningValidations";
import { RootState } from "../../store";
import { ageLevelWarnings } from "./Validations/PlanningTimingValidations";
import { fetchPcInfo } from "./Services/PlanningServices";
import { useDispatch } from "react-redux";
import { selectPCData } from "../../features/planning/employeeSlice";
import CustomAlert from "../common/atoms/CustomAlert";
import { SUCCESS } from "../../Constants";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { updatesalaryFormat } from "./Services/PlanningSalaryHelper";
import EditIcon from "../common/molecules/Actions/CustomEdit";
import LayoutEditPopup from "./LayoutEditPopup";
import OverlayTooltip from "./TypeBasedPopup";
import Icon from "../../Icon";


interface Shift {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  shifts: Shift[];
  project_id?: number;
  cost_center_id?: number;
  error?: string;
  warning?: string[];
}

interface PlanningLayoutData {
  planning_id?: number;
  planning_employee_detail_id?: number;
  employee_id: number;
  employee_name?: string;
  employee_type_id?: number;
  employee_profile_pic?: string;
  new_salary?: string;
  employee_type?: string;
  company_id?: number;
  location_id?: number;
  pc_id?: number;
  pc?: string;
  age: number;
  function_id?: number;
  function?: string;
  salary?: string;
  start_date?: string;
  is_selected?: boolean; // New field for checkbox state
  end_date?: string;
  schedule: {
    [date: string]: Schedule;
  };
}
type UpdatableScheduleFields = Exclude<keyof Schedule, "shifts">;

interface Props {
  emp: PlanningLayoutData;
  refreshOverview: (employeeIds: number[]) => void;
  selectedEmployeesForPlan?: (employeeIds: number[]) => void;
  saveLayoutData: (employee: any) => void;
  removeEmployee?: (employeeId: number) => void;
  onMultiSelectChange?: (isSelected: boolean) => void;
  isSelected?: number[];
}

const EmployeeLayoutRow: React.FC<Props> = ({
  emp,
  refreshOverview,
  selectedEmployeesForPlan,
  saveLayoutData,
  removeEmployee,
  onMultiSelectChange,
  isSelected = [],

}) => {
  const dynamicValue = useResponsiveValue("100%", "100%", "5%");
  const imageStyle = useResponsiveValue("5vw", "2.5vw", "2vw");
  const [employeeData, setEmployeeData] = useState<PlanningLayoutData>(emp);
  const state = useSelector((state: RootState) => state);
  const pcDetails = useSelector(selectPCData);
  const [editLayout, setEditLayout] = useState<any>(null);
  const authData = useSelector(selectAuth);
  // Add state for multi send checkbox
  const companyId = useSelector(selectCurrentCompany);
  const dispatch = useDispatch();

  const [error, setError] = useState<any>({
    scheduleError: ""
  })
  useEffect(() => {
    if (pcDetails?.length == 0) {
      fetchPcInfo(companyId.id, dispatch, state);
    }
  }, [companyId]);
  // EmployeeLayoutRow Component
  const handleRemove = (date: string) => {
    setEmployeeData((prevState) => {
      const updatedSchedule = { ...prevState.schedule };
      const currentShifts = updatedSchedule[date]?.shifts || [];

      if (currentShifts.length > 0) {
        // If more than one shift exists, remove the last shift
        updatedSchedule[date] = {
          ...updatedSchedule[date],
          shifts: currentShifts.slice(0, -1),
        };

        // Clear errors and warnings
        updatedSchedule[date].error = "";
        updatedSchedule[date].warning = [];

        saveLayoutData({ ...prevState, schedule: updatedSchedule });
        return { ...prevState, schedule: updatedSchedule };
      } else {
        // If it's the last shift, remove the employee completely
        if (removeEmployee) {
          // Remove from selected employees if it was selected
          if (isSelected.includes(employeeData.employee_id)) {
            onMultiSelectChange?.(false);
          }

          // Remove the employee from layout
          removeEmployee(employeeData.employee_id);

          // Remove from overview/schedule
          refreshOverview([employeeData.employee_id]);
        }
        return prevState;
        // If more than one shift exists, remove the last shift
      }
    });
  };
  // Update the checkbox handler
  const handleMultiSendChange = (checked: boolean) => {
    // Only validate if attempting to check the box
    if (checked) {
      // Validate all data
      const validationResult = validatePlanningData(employeeData, state);
      if (!validationResult.isValid) {
        // Set errors to display them
        setError(validationResult.errors);
        // Only call onMultiSelectChange if validation passed or unchecking
        if (onMultiSelectChange) {
          onMultiSelectChange(false);
        }
      }
      // Only call onMultiSelectChange if validation passed or unchecking
    }
    if (onMultiSelectChange) {
      onMultiSelectChange(checked);
    }

  };
  const handleShiftChange = (
    date: string,
    index: number,
    key: string,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);

    if (sanitizedInputLength(value) <= 4) {
      setEmployeeData((prevState) => {
        const updatedSchedule = { ...prevState.schedule };
        const updatedShifts = [...updatedSchedule[date].shifts];
        updatedShifts[index] = {
          ...updatedShifts[index],
          [key]: formattedValue,
        };
        updatedSchedule[date].shifts = updatedShifts;

        // Validate and update error and warnings directly within the schedule
        const errorMessage = validatePlanningFieldsForDate(
          state,
          updatedSchedule[date],
          employeeData.pc_id!,
          date
        );

        const warningMessages = ageLevelWarnings(
          employeeData.pc_id!,
          employeeData.age,
          state,
          updatedSchedule[date].shifts
        );

        // Update the schedule with error and warning messages
        updatedSchedule[date].error = errorMessage || "";
        updatedSchedule[date].warning = warningMessages || [];

        return { ...prevState, schedule: updatedSchedule };
      });
      saveLayoutData(employeeData);
    }
  };

  const handleShiftSelect = (shift: any, date: string) => {
    setEmployeeData((prevState) => {
      const updatedSchedule = { ...prevState.schedule };

      // Create new shifts array with the selected shift times
      const newShifts = [
        {
          start_time: shift.shift1.startTime,
          end_time: shift.shift1.endTime,
          sequence: 1,
        }
      ];

      // Add second shift if it exists
      if (shift.shift2?.startTime && shift.shift2?.endTime) {
        newShifts.push({
          start_time: shift.shift2.startTime,
          end_time: shift.shift2.endTime,
          sequence: 2,
        });
      }

      // Update the schedule with new shifts
      updatedSchedule[date] = {
        ...updatedSchedule[date],
        shifts: newShifts
      };

      // Validate and update error and warnings directly within the schedule
      const errorMessage = validatePlanningFieldsForDate(
        state,
        updatedSchedule[date],
        employeeData.pc_id!,
        date
      );

      const warningMessages = ageLevelWarnings(
        employeeData.pc_id!,
        employeeData.age,
        state,
        updatedSchedule[date].shifts
      );

      // Update the schedule with error and warning messages
      updatedSchedule[date].error = errorMessage || "";
      updatedSchedule[date].warning = warningMessages || [];

      const updatedEmployeeData = { ...prevState, schedule: updatedSchedule };
      saveLayoutData(updatedEmployeeData);
      return updatedEmployeeData;
    });
  };

  const OnProjectClick = (
    projectId: number,
    type: number,
    field: UpdatableScheduleFields,
    date?: string
  ) => {
    if (!date) return;

    setEmployeeData((prevState) => {
      const updatedSchedule = { ...prevState.schedule };

      if (!updatedSchedule[date]) {
        updatedSchedule[date] = { shifts: [] }; // Initialize a valid Schedule object
      }

      // Toggle projectId: If the same, remove it; otherwise, update it.
      updatedSchedule[date] = {
        ...updatedSchedule[date],
        [field]: updatedSchedule[date][field] === projectId ? undefined : projectId,
      };

      const updatedEmployeeData = { ...prevState, schedule: updatedSchedule };
      saveLayoutData(updatedEmployeeData);
      return updatedEmployeeData;
    });
  };

  const handleSendPlanning = async () => {
    // Reset all errors first
    setError({
      scheduleError: "",
      generalError: "",
      validationError: "",
      warning: ""
    });

    const validationResult = validatePlanningData(employeeData, state);
    // Show error notification
    if (!validationResult.isValid) {
      setError(validationResult.errors);
      // Iterate over schedule dates and validate individual shifts
      const updatedSchedule = { ...employeeData.schedule };
      Object.keys(updatedSchedule).forEach((date) => {
        const scheduleForDate = updatedSchedule[date];

        // Validate fields for this specific date
        const errorMessage = validatePlanningFieldsForDate(
          state,
          scheduleForDate,
          employeeData.pc_id!,
          date
        );

        // Check for warnings based on employee's age and shifts
        const warningMessages = ageLevelWarnings(
          employeeData.pc_id!,
          employeeData.age,
          state,
          scheduleForDate.shifts
        );

        // Update schedule with validation results
        updatedSchedule[date].error = errorMessage || "";
        updatedSchedule[date].warning = warningMessages || [];
      });

      setEmployeeData((prevState) => ({
        ...prevState,
        schedule: updatedSchedule,
      }));


      // Extract only errors, excluding warnings
      const errorMessages = Object.entries(validationResult.errors)
        .filter(([key, msg]) => key !== "warning" && msg) // Exclude "warning" key and empty messages
        .map(([_, msg]) => `*  ${msg}`) // Format with bullet points
        .join("\n"); // Join with new lines

      CustomNotify({
        type: "error",
        message: errorMessages || t("Validation failed"),
        autoClose: 2500,
      });

      return; // Stop execution here if there are any errors
    }
    // Proceed with API call only if all validations pass
    try {
      employeeData.company_id = parseInt(companyId.id);

      // Ensure salary is sent as a properly parsed float value
      const preparedEmployeeData = {
        ...employeeData,
        salary: parseFloat(
          employeeData.salary?.toString().replace(",", ".") || "0"
        ),
      };

      const dataObj = {
        company_id: parseInt(companyId.id),
        user_id: authData?.userId,
        planning: [preparedEmployeeData],
      };

      const response = await ApiCall.service(
        CREATE_PLANNING,
        "POST",
        dataObj,
        false
      );

      if (response.status === SUCCESS) {
        CustomNotify({
          type: "success",
          message: t(response.message),
          autoClose: 2500,
        });
        refreshOverview([employeeData.employee_id]);
      } else if (response.status === 422) {
        // Set API error response
        setError({
          scheduleError: "",
          generalError: t(response.message),
          validationError: "",
          warning: ""
        });

        CustomNotify({
          type: "error",
          message: t(response.message),
          autoClose: 2500,
        });
      }
    } catch (error) {
      console.error("Error making API call:", error)
    }
  };
  const handleAddShift = (date: string) => {
    setError({
      scheduleError: ""
    });
    setEmployeeData((prevState) => {
      const updatedSchedule = { ...prevState.schedule };
      // Initialize the date's schedule if it doesn't exist
      if (!updatedSchedule[date]) {
        updatedSchedule[date] = { shifts: [] };
      }
      const existingShifts = updatedSchedule[date].shifts || [];

      if (existingShifts.length === 0) {
        const newShift = {
          start_time: "",
          end_time: "",
          sequence: 1,
        };
        updatedSchedule[date].shifts = [...existingShifts, newShift];
      }
      if (existingShifts.length === 1) {
        const newShift = {
          start_time: "",
          end_time: "",
          sequence: 2,
        };
        updatedSchedule[date].shifts = [...existingShifts, newShift];
      }

      return { ...prevState, schedule: updatedSchedule };
    });
  };


  const shiftCells = (daySchedule: Schedule, date: string) => {
    return (
      <>
        {daySchedule?.shifts?.map((shift, index) => (
          <React.Fragment key={index}>
            <td className="align-middle" data-label={t("Start time")} style={{ width: dynamicValue }}>
              <InputTextfield
                value={shift.start_time}
                className="me-2"
                handleChange={(e) =>
                  handleShiftChange(date, index, "start_time", e.target.value)
                }
              />
            </td>

            <td className="align-middle" data-label={t("End time")} style={{ width: dynamicValue }}>
              <InputTextfield
                value={shift.end_time}
                handleChange={(e) =>
                  handleShiftChange(date, index, "end_time", e.target.value)
                }
              />
            </td>
          </React.Fragment>
        ))}

        {daySchedule?.shifts?.length < 2 && (
          <td
            className="align-middle px-2 shiftAddIcon"
            colSpan={daySchedule?.shifts?.length === 0 ? 4 : 2}
          >
            <CustomActionIcon title={t("Add shift")} handleClick={() => handleAddShift(date)} iconName="add" buttonClass="searchBtn" marginBottom="mb-0" />
          </td>
        )}

        <td className="align-middle" data-label={t("Break total")}>{"00:00"}</td>
        <td className="align-middle" data-label={t("Total work done")}>
          <div className="totalHours text-white poppins-medium">
            {calculateShiftsTotalTime(daySchedule?.shifts)}
          </div>
        </td>
      </>
    );
  };

  return (
    <>
      {Object.keys(employeeData?.schedule)?.map((date) => {
        const daySchedule = employeeData?.schedule[date];
        const errorMessage = daySchedule?.error;
        const warningMessages = daySchedule?.warning || [];
        return (
          <React.Fragment key={date}>
            <tr className="mb-2">
              <td>
                <Image
                  src={employeeData?.employee_profile_pic ?? UserImage}
                  imageWidth={imageStyle}
                  imageHeight={imageStyle}
                  imageBorderRadius="50%"
                />
              </td>
              <td data-label={t("Employee")}>
                <div
                  className="textEllipsisName textEllipsis"
                  title={employeeData.employee_name}
                >
                  {employeeData.employee_name}
                </div>
              </td>

              <td data-label={t("Status")}>
                <div
                  className="textEllipsisEmpType textEllipsis"
                  title={employeeData?.employee_type}
                >
                  {employeeData.employee_type}
                </div>
              </td>
              <td data-label={t("Function")}>
                <div
                  title={employeeData.function}
                  className="textEllipsisFunName textEllipsis"
                >
                  {employeeData.function}
                </div>
              </td>
              <td data-label={t("Paritair committee")}>
                <div
                  title={employeeData.pc}
                  className="textEllipsisFunName textEllipsis"
                >
                  {employeeData.pc}
                </div>
              </td>
              <td data-label={t("Hourly wage")} >€
                {employeeData.new_salary != undefined && employeeData.new_salary != '' ?
                  updatesalaryFormat(employeeData.new_salary) :
                  employeeData.salary && updatesalaryFormat(employeeData.salary)}
              </td>
              {shiftCells(daySchedule, date)}
              <td></td>
              <td className="p-2">
                <div>
                  <EditIcon
                    handleClick={() => setEditLayout(employeeData)}
                    iconName="EditIcon"
                    title={t("Edit")}
                  />

                  <OverlayTooltip
                    selectedProject={(projectId: number, type: number, field: UpdatableScheduleFields) =>
                      OnProjectClick(projectId, type, field, date)}
                    selectedProjectId={daySchedule.project_id}
                    selectedCcId={daySchedule.cost_center_id}
                    tooltipText="Projects"
                  >
                    <span title="Projects">
                      <Icon
                        name="LocationIcon"
                        width="1vw"
                        height="1vw"
                        className="cursor-pointer secondaryColorHoverEffect"
                        isIcon={true}
                      />
                    </span>
                  </OverlayTooltip>

                  <OverlayTooltip
                    tooltipText="Shifts"
                    onShiftSelect={(shift: any) => handleShiftSelect(shift, date)}
                  >
                    <span title="Shifts">
                      <Icon
                        name="shiftsIcon"
                        width="1vw"
                        height="1vw"
                        fillColor="#8571b9"
                        className="cursor-pointer"
                        isIcon={true}
                      />
                    </span>
                  </OverlayTooltip>

                  {!isSelected.includes(employeeData.employee_id) && (
                    <CustomActionIcon
                      handleClick={handleSendPlanning}
                      title={t("Send")}
                      iconName="sendIcon"
                      buttonClass="searchBtn"
                    />
                  )}
                  <div className="d-inline-block mt-2">
                    <CustomActionIcon
                      handleClick={() => handleRemove(date)}
                      iconName="RemoveIcon"
                      title={t("Remove shift")}
                      buttonClass="resetBtn"
                      className="me-2 removeUploadedFile"
                    />
                  </div>
                  <input
                    name={`multiSelect-${employeeData.employee_id}`}
                    type="checkbox"
                    checked={isSelected.includes(employeeData.employee_id)}
                    onChange={(e) => handleMultiSendChange(e.target.checked)}
                    className={`shadow-none cursor-pointer form-check-input mt-2 me-2`}
                  />
                </div>
              </td>
            </tr >
            {window.innerWidth > 1023 && <TableHeaderSpace className="bg-transparent" headerLength={15} />}

            {/* Display General Errors */}
            {
              (error.generalError || error.scheduleError) && (
                <tr>
                  <td colSpan={15} className="p-0 pb-2 bg-transparent">
                    <CustomAlert className="alert-danger" iconName="warningIcon">
                      {error.generalError || error.scheduleError}
                    </CustomAlert>
                  </td>
                </tr>
              )
            }
            {/* Display Error Messages */}
            {
              errorMessage && (
                <tr>
                  <td colSpan={15} className="p-0 pb-2 bg-transparent">
                    <CustomAlert className="alert-danger" iconName="warningIcon">
                      {errorMessage}
                    </CustomAlert>
                  </td>
                </tr>
              )
            }
            {/* Display Warning Messages */}
            {warningMessages?.length > 0 && (
              <tr>
                <td colSpan={15} className="p-0 pt-2">
                  <CustomAlert className="alert-warning" iconName="warningIcon">
                    {warningMessages?.map((warning, idx) => (
                      <div key={idx}>{warning}</div>
                    ))}
                  </CustomAlert>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
      {
        editLayout != null && (
          <LayoutEditPopup
            employeeData={editLayout}
            clearPopup={() => {
              setEditLayout(null);
            }}
            saveEmployeeData={(empData: any) => { setEmployeeData(empData); saveLayoutData(empData); }}
          />
        )
      }
    </>
  );
};

export default translate(EmployeeLayoutRow);

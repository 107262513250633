import React, { useEffect, useState } from "react";
import Header from "../../../common/layout/Header";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import Button from "../../../common/atoms/Button";
import { useSelector } from "react-redux";
import { REGISTER_COUNTRY_LIST, UPDATE_EMPLOYEE } from "../../../../routes/ApiEndpoints";
import { ApiCall } from "../../../../services/ApiServices";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { initialState } from "../MyAccount/State";
import { MyAccount } from "../MyAccount/MyAccountInterface";
import { DropdownOptions, ReduxState } from "../../../Dashboard/services/CompanyInterface";
import { formatUserData, formValidation } from "../MyAccount/services/MyaccountUtils";
import { selectLanguages } from "../../../../features/settings/settingSlice";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import PersonalInformationOrganism from "./ProfilePageOrganisms/PersonalInformationOrganism";
import SignatureOrganism from "./ProfilePageOrganisms/SignatureOrganism";
import PrivacyOrganism from "./ProfilePageOrganisms/PrivacyOrganism";
import SecurityOrganism from "./ProfilePageOrganisms/SecurityOrganism";
import { useDispatch } from "react-redux";
import { addUserDetails } from "../../../../features/auth/AuthSlice";

const ProfilePageTemplate = () => {
  const user = useSelector((state: ReduxState) => state.auth);
  const [formState, setFormState] = useState<MyAccount>(initialState);
  const optionsData = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const handleShowPasswordToggle = () => setFormState((prevState: MyAccount) => ({
    ...prevState,
    showPassword: !prevState?.showPassword,
  }));
  const handleShowConfirmPasswordToggle = () => setFormState((prevState: MyAccount) => ({
    ...prevState,
    showConfirmPassword: !prevState?.showConfirmPassword,
  }));
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    setFormState((prevState) => {
      const updatedFormValues = { ...prevState.formValues };
      let current: any = updatedFormValues;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current[key] = { ...current[key] };
          current = current[key];
        }
      });

      return {
        ...prevState,
        formValues: updatedFormValues,
      };
    });
  };

  const fetchUser = async (userid: number) => {
    try {
      const url = `${UPDATE_EMPLOYEE}/${userid}`;
      const response = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);

      if (response && response?.status === 'success') {
        const formattedData = formatUserData(response?.data);

        setFormState((prevState: any) => ({
          ...prevState,
          formValues: formattedData,
        }));
      } else {
        console.error("Unexpected response status:", response?.status);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };


  const fetchCountryOptions = async () => {
    const url = REGISTER_COUNTRY_LIST;
    try {
      const result = await ApiCall?.getService(url, "GET");
      setFormState((prev) => ({
        ...prev,
        countries: result?.data || [],
      }));
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  const handleDateFormat = (dateValue: Date | null, field: string) => {
    // Format the date to "YYYY-MM-DD"
    const formattedDate = dateValue
      ? new Date(
        new Date(dateValue).getTime() -
        new Date(dateValue).getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 10)
      : null;

    const fieldParts = field.split(".");

    const updateState = (state: any, value: any) => {
      let currentState = state;
      fieldParts.forEach((part, index) => {
        if (index === fieldParts.length - 1) {
          currentState[part] = value;
        } else {
          currentState[part] = currentState[part] || {};
          currentState = currentState[part];
        }
      });
      return state;
    };
    setFormState((prevState) => ({
      ...prevState,
      formValues: updateState({ ...prevState.formValues }, formattedDate),
    }));
  };

  useEffect(() => {
    if (user?.userId) {
      fetchUser(user.userId);
      fetchCountryOptions();
    }
  }, [user?.userId]);

  const handleSelectChange = (
    selectedOption: DropdownOptions | DropdownOptions[] | null,
    fieldPath: string,
    isMulti = false
  ) => {
    const value = isMulti
      ? (selectedOption as DropdownOptions[])?.map((option) => option.value) || []
      : selectedOption ? (selectedOption as DropdownOptions).value : null;

    setFormState((prev: MyAccount) => {
      const updatedFormValues = { ...prev.formValues };
      setNestedValue(updatedFormValues, fieldPath, value);

      return {
        ...prev,
        formValues: updatedFormValues,
      };
    });
  };

  const handleToggle = (field: string, value: boolean) => {
    setFormState((prevState: any) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        privacy: {
          ...prevState.formValues.privacy,
          [field]: value,
        }
      }
    }));
  };

  // Helper function to update nested values
  const setNestedValue = (obj: Record<string, any>, path: string, value: any) => {
    const keys = path.split(".");
    let current = obj;

    keys.slice(0, -1).forEach((key) => {
      if (!current[key]) current[key] = {};
      current = current[key];
    });

    current[keys[keys.length - 1]] = value;
  };

  const handleSubmit = async () => {
    const errors = formValidation(
      formState,
    );
    if (Object.keys(errors).length > 0) {
      setFormState((prev: MyAccount) => ({
        ...prev,
        errors,
      }));
      return;
    }
    try {
      let response;
      if (user?.token) {
        response =
          await ApiCall?.service(
            `${UPDATE_EMPLOYEE}/${user?.userId}`,
            "PUT",
            formState?.formValues,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          )
      }
      if (response.status === 'success') {
        CustomNotify({ type: "success", message: t(response?.message) });
        setFormState((prevState: any) => ({
          ...prevState,
          formValues: {
            ...prevState?.formValues,
            security: {
              ...prevState.formValues?.security,
              password: "",
              confirm_password: ""
            }
          },
          showPassword: false,
          showConfirmPassword: false
        }));
        dispatch(addUserDetails({
          profilePicPath: response?.data?.profile_picture_url,
        }));
      } else if (response.status === 'validation-error') {
        const errorMessages = response?.data;
        const formattedErrorMessages = Object.values(errorMessages)
          .flat()
          .join("\n");
        CustomNotify({
          type: "error",
          message: t(formattedErrorMessages),
        });
      } else {
        CustomNotify({ type: "error", message: t(response?.message) });
      }
    }
    catch (error: unknown) {
      console.error(error);
    }
  };

  const clearFieldError = (fieldPath: string) => {
    const errorKey = fieldPath.replace(/\./g, "_");
    setFormState((prevState: MyAccount) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [errorKey]: null,
      },
    }));
  };

  const handleSaveSignature = (dataUrl: string) => {
    setFormState((prevState: MyAccount) => ({
      ...prevState,
      formValues: {
        ...prevState.formValues,
        signature: dataUrl,
      },
    }));
  };

  return (
    <>
      <Header
        headerName={t("My Account")}
        headerClassName="myAccountCardTitle"
      />
      <div className="row equal-cols myProfilePageWrapper scrollBar justify-content-center">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <div className="contentBg mb-3">
            <PersonalInformationOrganism
              formState={formState}
              handleChange={handleChange}
              handleDateFormat={handleDateFormat}
              handleSelectChange={handleSelectChange}
              clearFieldError={clearFieldError}
              optionsData={optionsData}
              setFormState={setFormState}
            />
          </div>
          <SignatureOrganism
            formState={formState}
            handleSaveSignature={handleSaveSignature}
          />
        </div>
        <div className="col-lg-6">
          <div className="contentBg">
            <SecurityOrganism
              formState={formState}
              handleChange={handleChange}
              clearFieldError={clearFieldError}
              handleShowPasswordToggle={handleShowPasswordToggle}
              handleShowConfirmPasswordToggle={handleShowConfirmPasswordToggle}
              showPassword={formState?.showPassword ?? false}
              showConfirmPassword={formState?.showConfirmPassword ?? false}
            />
            {/* <CommunicationOrganism
              formState={formState}
              handleChange={handleChange}
              clearFieldError={clearFieldError}
            /> */}
            <PrivacyOrganism
              formState={formState}
              handleToggle={handleToggle}
            />
          </div>
        </div>
        <div className="col-12 my-3">
          <div>
            <Button
              type="submit"
              className="searchBtn px-3 float-end"
              title={t("Save")}
              style={{ width: "fit-content" }}
              handleClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(ProfilePageTemplate);

import React, { useEffect, useState, useCallback, useRef } from "react";
import LabelField from "../common/atoms/LabelField";
import Header from "../common/layout/Header";
import "./css/invoices.css";
import InvoicesDataCard from "./InvoicesDataCard";
import OverFlowWrapper from "../common/OverFlowWrapper";
import PdfViewer from "../common/molecules/PdfViewer";
import { ApiCall } from "../../services/ApiServices";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { FETCH_ALL_INVOICES, GET_ALL_INVOICES, GET_INVOICE } from "../../routes/ApiEndpoints";
import CommonServices from "../../services/common/CommonServices";
import { useSelector } from "react-redux";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import Button from "../common/atoms/Button";
import { FaSyncAlt } from "react-icons/fa";
import CustomNotify from "../common/atoms/CustomNotify";
import { VariableSizeList as List } from "react-window";
import { ReduxState } from "../Dashboard/services/CompanyInterface";
import Calender from "../common/molecules/Calender";

const InvoiceOverviewTemplate = () => {
  interface Data {
    paid_invoices: Array<any>;
    unpaid_invoices: Array<any>;
    outstanding_amount: string;
    company: object;
    start_date?: string | null;
    end_date?: string | null;
    error?: {
      start_date?: string | null;
      end_date?: string | null;
    };
  }

  // Helper function to format dates consistently in DD-MM-YYYY format
  const formatDate = (date: Date) => {
    const day = String(date?.getDate())?.padStart(2, "0");
    const month = String(date?.getMonth() + 1)?.padStart(2, "0");
    const year = date?.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const [state, setState] = useState<{
    data: Data;
    pdfUrls: Record<number, string | any>;
    showPdf: string | null;
    invoiceNumber: string | null;
    scrollPosition: number;
  }>({
    data: {
      paid_invoices: [],
      unpaid_invoices: [],
      outstanding_amount: "",
      company: {},
      start_date: formatDate(new Date(new Date()?.setMonth?.(new Date()?.getMonth() - 3))),
      end_date: formatDate(new Date()),
      error: {},
    },
    pdfUrls: {},
    showPdf: null,
    invoiceNumber: null,
    scrollPosition: 0,
  });

  const listRef = useRef<List>(null);
  const id = useSelector((state: ReduxState) => state?.auth?.currentCompany?.id);
  const [isTyping, setIsTyping] = useState(false);


  const fetchData = useCallback(async (startDate: any, endDate: any) => {
    try {
      const swapDate = (date: string | null) => {
        if (!date) return "";
        const [day, month, year] = date.split("-");
        return `${day}-${month}-${year}`;
      };

      const formattedStartDate = swapDate(startDate);
      const formattedEndDate = swapDate(endDate);

      const url = `${GET_ALL_INVOICES}?enterprise_id=${id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
      const response = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      if (response?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          data: {
            ...prevState.data,
            paid_invoices: Object.values(response?.data?.paid_invoices || []),
            unpaid_invoices: Object.values(response?.data?.unpaid_invoices || []),
            outstanding_amount: new Intl.NumberFormat("de-DE").format(
              response?.data?.outstanding_amount || 0
            ),
            company: response?.data?.unpaid_invoices?.[0]?.company || {},
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id]);


  useEffect(() => {
    if (id && !state?.data?.error?.end_date && !state?.data?.error?.start_date) {
      fetchData(state?.data?.start_date, state?.data?.end_date);
    }
  }, [id, state?.data?.start_date, state?.data?.end_date]);


  const fetchInvoicePdf = useCallback(async (invoiceId: number) => {
    if (state?.pdfUrls?.[invoiceId]) {
      return state?.pdfUrls?.[invoiceId];
    }
    try {
      const url = `${GET_INVOICE}/${invoiceId}`;
      const response = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      if (response?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          pdfUrls: {
            ...prevState.pdfUrls,
            [invoiceId]: response?.data?.url,
          },
        }));
      }
      return response?.data?.url;
    } catch (error) {
      console.error("Error fetching invoice:", error);
      return null;
    }
  }, [state?.pdfUrls]);

  const fetchInvoices = useCallback(async () => {
    try {
      const url = `${FETCH_ALL_INVOICES}/${id}`;
      const response = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      if (response?.status === "success") {
        CustomNotify({ type: "success", message: t(response?.message) });
        if (!state?.data?.error?.end_date && !state?.data?.error?.start_date) {
          fetchData(state?.data?.start_date, state?.data?.end_date);
        }
      } else {
        CustomNotify({ type: "warning", message: t(response?.message) });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [id, fetchData, state?.data?.error?.end_date]);

  const handleDateChange = (dateValue: Date | null, field: "start_date" | "end_date") => {
    if (!dateValue || isNaN(dateValue.getTime())) {
      setState((prevState) => ({
        ...prevState,
        data: {
          ...prevState.data,
          error: {
            ...prevState.data.error,
            [field]: t("Invalid date format."),
          },
        },
      }));
      return;
    }

    const formattedDate = formatDate(dateValue);
    setState((prevState) => {
      const startDate = prevState?.data?.start_date ? new Date(prevState?.data?.start_date.split("-").reverse().join("-")) : null;
      const endDate = prevState?.data?.end_date ? new Date(prevState?.data?.end_date.split("-").reverse().join("-")) : null;
      const newError = { ...prevState?.data?.error };

      if (field === "end_date" && startDate && dateValue <= startDate) {
        newError.end_date = t("End date should be greater than start date.");
      } else {
        newError.end_date = null;
      }

      if (field === "start_date" && endDate && dateValue >= endDate) {
        newError.start_date = t("Start date should be lesser than end date.");
      } else {
        newError.start_date = null;
      }

      return {
        ...prevState,
        data: {
          ...prevState.data,
          [field]: newError[field]
            ? prevState?.data[field]
            : formattedDate,
          error: newError,
        },
        [field === "start_date" ? "lastValidStartDate" : "lastValidEndDate"]: formattedDate,
      };
    });
  };

  useEffect(() => {
    if (!state?.data?.error?.end_date && !state?.data?.error?.start_date) {
      fetchData(state?.data?.start_date, state?.data?.end_date);
    }
  }, [state?.data?.start_date, state?.data?.end_date, fetchData]);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, field: "start_date" | "end_date") => {
    if (event.key === "Enter") {
      setIsTyping(false);
      handleDateChange(
        new Date(event?.currentTarget?.value?.split("-")?.reverse()?.join("-")),
        field
      );
    } else {
      setIsTyping(true);
    }
  };

  const viewInvoice = useCallback(async (invoiceId: number) => {
    if (listRef.current) {
      const list = listRef?.current as any;
      setState((prevState) => ({
        ...prevState,
        scrollPosition: list?._outerRef?.scrollTop || 0,
      }));
    }

    const url = await fetchInvoicePdf(invoiceId);
    if (url) {
      const invoice = [
        ...state?.data?.paid_invoices,
        ...state?.data?.unpaid_invoices,
      ]?.find((invoice) => invoice?.bright_id === invoiceId);

      setState((prevState) => ({
        ...prevState,
        invoiceNumber: invoice?.invoice_number || "",
        showPdf: url,
      }));
    }
  }, [state?.data, fetchInvoicePdf]);

  const handleClosePdf = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      showPdf: null,
    }));
    setTimeout(() => {
      if (listRef?.current) {
        const list = listRef?.current as any;
        list._outerRef.scrollTop = state?.scrollPosition || 0;
      }
    }, 0);
  }, [state?.scrollPosition]);

  const downloadInvoice = useCallback(async (invoiceId: number) => {
    const url = await fetchInvoicePdf?.(invoiceId);
    if (url) {
      try {
        const invoice = [
          ...state?.data?.paid_invoices,
          ...state?.data?.unpaid_invoices,
        ]?.find((invoice) => invoice?.bright_id === invoiceId);
        const invoiceNumber = invoice?.invoice_number || "Unknown";
        await CommonServices?.downloadFile?.(url, invoiceNumber, "Invoice");
      } catch (error) {
        console.error("Error downloading the invoice:", error);
      }
    }
  }, [state?.data, fetchInvoicePdf]);

  const renderRow = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    const invoice = [...state?.data?.unpaid_invoices]?.[index];
    return (
      <div style={{ ...style, width: "98%", padding: "0vw 0vw 0vw 0vw" }} className="mb-3">
        <InvoicesDataCard
          key={invoice?.id}
          isOutstanding={true}
          invoice={invoice}
          company={state?.data?.company}
          viewInvoice={() => viewInvoice(invoice?.bright_id)}
          downloadInvoice={downloadInvoice}
        />
      </div>
    );
  };

  const getItemSize = (index: number) => {
    const systemHeight = parseInt(
      getComputedStyle(document?.documentElement)?.getPropertyValue?.('--invoice-item-min-height')
    ) || 70;
    return systemHeight;
  };

  return (
    <>
      <Header headerName={t("Invoices")} headerClassName="myAccountCardTitle" />

      <div className="row equal-cols">
        <div className="col-lg-12 mb-4 mb-lg-0">
          {state?.showPdf ? (
            <div className="contentBg">
              <OverFlowWrapper className="invoiceLeftWrapper">
                <PdfViewer
                  fileUrl={state?.showPdf}
                  setShowPdf={handleClosePdf}
                  fileNumber={state?.invoiceNumber}
                  fileType={"Invoice"}
                />
              </OverFlowWrapper>
            </div>
          ) : (
            <div className="bg-white" style={{ borderRadius: "2vw", padding: "2vw 2vw 2vw 0vw" }}>
              <div className="d-flex justify-content-between">
                <LabelField
                  title={t("Invoices")}
                  className="inVoiceHeader mb-3"
                  customStyle={{ padding: "0vw 2.5vw" }}
                />

                <div className="d-flex">
                  <div className="leftlabelClass me-2">
                    <LabelField title={t("Start date") + ":"} />
                  </div>
                  <div className="">
                    <div className="position-relative calendarSectionWrapper">
                      <Calender
                        onChange={(date) => {
                          if (date && !isTyping) {
                            handleDateChange(date, "start_date");
                          }
                        }}
                        onKeyDown={(event, date) => handleKeyDown(event, "start_date")}
                        selectedDate={state?.data?.start_date ? new Date(state?.data?.start_date.split("-").reverse().join("-")) : null}
                        maxDate={new Date()}
                        error={state?.data?.error?.start_date}
                        name="start_date"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="leftlabelClass me-2">
                    <LabelField title={t("End date") + ":"} />
                  </div>
                  <div className="">
                    <div className="position-relative calendarSectionWrapper mb-2">
                      <Calender
                        onChange={(date) => {
                          if (date && !isTyping) {
                            handleDateChange(date, "end_date");
                          }
                        }}
                        onKeyDown={(event) => handleKeyDown(event, "end_date")}
                        selectedDate={state?.data?.end_date ? new Date(state?.data?.end_date.split("-").reverse().join("-")) : null}
                        maxDate={new Date()}
                        error={state?.data?.error?.end_date}
                        name="end_date"
                        dateFormat="dd-MM-yyyy"
                      />
                    </div>
                  </div>
                </div>

                <Button
                  title={<FaSyncAlt />}
                  type="submit"
                  className="syncSearchBtn searchBtn px-0 mb-3"
                  handleClick={fetchInvoices}
                  toolTipName="Sync Invoices"
                />
              </div>
              <div className="mb-3" style={{ paddingLeft: "2.5vw", height: "650px" }}>
                {state?.data?.unpaid_invoices?.length > 0 ? (
                  <List
                    ref={listRef}
                    height={650}
                    itemCount={state?.data?.unpaid_invoices?.length}
                    itemSize={getItemSize}
                    width="100%"
                    className="pe-3 scrollBar has-scroll"
                    style={{ overflow: "auto" }}
                  >
                    {renderRow}
                  </List>
                ) : (
                  <div className="text-center rounded-3 p-4 d-flex justify-content-center">
                    <p>{t("No invoices found for the selected date range.")}</p>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default translate(InvoiceOverviewTemplate);

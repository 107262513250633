import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import EmpployeeShiftTable from "./EmpployeeShiftTable";
import SelectedEmployeesDetailsTable from "./SelectedEmployeesDetailsTable";
import SelectedEmployeesNameCard from "./SelectedEmployeesNameCard";
import {
  resetAllData,
  selectContractType,
  selectEmployees,
  selectEmployeesWithScheduleErrors,
  setTab,
  updateEmployeeDetails,
} from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { RootState } from '../../store'; // Adjust based on your store
import SelectedEmployeesDetailsEdit from "./SelectedEmployeesDetailsEdit";
import { selectPCData } from "../../features/planning/employeeSlice";
import { getTitle } from "./Services/PlanningServices";
import { validatePlanningAllEmployeeFields } from "./Validations/PlanningScheduleValidation";
import { updatesalaryFormat } from "./Services/PlanningSalaryHelper";
interface EmployeeData {
  pc: {
    value: number; // Adjust based on your data types
    label: string;
    pc_max_age: number;
  };
  employee_type: {
    value: number; // Adjust based on your data types
    label: string;
  };
  function_id: {
    value: number; // Adjust based on your data types
    label: string;
  };
  salary: string; // Depending on how you handle salary
  new_salary: string; // Keeping it as string to handle formats
}


const EmployeeScheduling = () => {
  const state = useSelector((state: RootState) => state);
  const [showModal, setShowModal] = useState(true);
  const monthView = useSelector(selectContractType);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number>(0);
  const selectedEmployees = useSelector(selectEmployees);
  const [activeEmployee, setActiveEmployee] = useState(selectedEmployees[0]);
  const errorEmployeeIds = useSelector(selectEmployeesWithScheduleErrors);
  const [editPc, setEditPc] = useState(false);
  const pcData = useSelector(selectPCData);
  const [employeeData, setEmployeedata] = useState<EmployeeData>({
    pc: { value: activeEmployee.pc_id, label: activeEmployee.pc, pc_max_age: activeEmployee.pc_max_age },
    employee_type: { value: activeEmployee.employee_type_id, label: activeEmployee.pc },
    function_id: { value: activeEmployee.function_id, label: activeEmployee.pc },
    salary: activeEmployee.salary,
    new_salary: ""
  });
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAllData());
  };

  const handleNextEmployee = () => {
    if (currentEmployeeIndex < selectedEmployees.length - 1) {
      setCurrentEmployeeIndex(currentEmployeeIndex + 1);
    }
  };

  const handlePreviousEmployee = () => {
    if (currentEmployeeIndex > 0) {
      setCurrentEmployeeIndex(currentEmployeeIndex - 1);
    }
  };
  useEffect(() => {
    if (selectedEmployees[currentEmployeeIndex]) {
      setActiveEmployee(selectedEmployees[currentEmployeeIndex]);
      setEmployeedata({
        pc: { value: selectedEmployees[currentEmployeeIndex].pc_id, label: selectedEmployees[currentEmployeeIndex].pc, pc_max_age: selectedEmployees[currentEmployeeIndex].pc_max_age },
        employee_type: { value: selectedEmployees[currentEmployeeIndex].employee_type_id, label: selectedEmployees[currentEmployeeIndex].employee_type },
        function_id: { value: selectedEmployees[currentEmployeeIndex].function_id, label: selectedEmployees[currentEmployeeIndex].function },
        salary: selectedEmployees[currentEmployeeIndex].salary,
        new_salary: ""
      });
      setEditPc(false);
    }
  }, [currentEmployeeIndex, selectedEmployees]);
  const handleNext = async () => {
    let is_valid = validatePlanningAllEmployeeFields(state, dispatch);

    if (errorEmployeeIds.length <= 0 && !is_valid) {
      setShowModal(false);
      dispatch(setTab("tab_3"));
    }
  };
  const handleBackClick = () => {
    dispatch(setTab("tab_1"));
  };
  const handleUpdateEmployee = (updatedEmployee: Partial<typeof employeeData>) => {
    setEmployeedata((prevState: any) => {
      // Check if the PC value is being updated
      if (updatedEmployee.pc != undefined && updatedEmployee?.pc?.value !== prevState.pc.value) {
        // Reset other fields if the PC value changed
        return {
          pc: updatedEmployee.pc,
          employee_type: { value: "", label: "" },
          function_id: { value: "", label: "" },
          salary: "",
          new_salary: ""
        };
      }
      return {
        ...prevState,
        ...updatedEmployee,
      };
    });
  };


  return (
    <ModalPopup
      show={showModal}
      handleClose={handleCloseModal}
      modalTitle={getTitle(monthView)}
      modalBackgroundColor="#8571b9"
      className="planningModal"
      buttonText={t("Next")}
      showNextIcon={true}
      iconFillColor="#8571b9"
      showBackIcon={true}
      onNextClick={handleNext}
      handleBack={handleBackClick}
    >
      <div className="row">
        <div className="col-lg-9 col-12">
          <Title
            title={t("Choose an employee(s) for whom you want to create a schedule") + ":"}
            className="bodyTitle colorPrimary py-2 mb-lg-0"
          />
        </div>
      </div>

      <div className="row">
        <SelectedEmployeesNameCard
          currentEmployeeIndex={currentEmployeeIndex}
          onEmployeeSelect={(index: number) => setCurrentEmployeeIndex(index)}
        />
      </div>

      {activeEmployee && (
        <>
          {/* {editPc ? ( */}
            <SelectedEmployeesDetailsEdit
              onEmployeeSelected={activeEmployee}
              onNextEmployee={handleNextEmployee}
              onPreviousEmployee={handlePreviousEmployee}
              pcs={pcData}
              onUpdateEmployee={handleUpdateEmployee} // Pass the update function
              onChangeData={employeeData}
            />
          <EmpployeeShiftTable onEmployeeSelected={activeEmployee} />
        </>
      )}
    </ModalPopup>
  );
};

export default translate(EmployeeScheduling);

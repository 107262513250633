import React, { ReactNode, useState } from "react";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import PlanningFilterSection from "./PlanningFilterSection";
import { translate } from "../CentralDataMangement/translation/Translation";
import { useSelector } from "react-redux";
import { selectCompanyBlockedStatus, selectCompanyStatus, selectCurrentLocations } from "../../features/auth/AuthSlice";
import EmployessNameCard from "./EmployeesNameCard";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { RootState } from "../../store";
import CoopMessageDisplay from "../common/molecules/CoopMessageDisplay";

interface HeaderWithFilterProps {
  children?: ReactNode;
  headerName?: string;
  startDate?: Date;
  type?: string;
  page?: string;
  selectedLocation?: { // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  onLocationChange?: (index: number) => void; // Add prop for location change handler
  handleEmployeeClick?: () => void;
  selectedEmployees: Employee[];
}
interface Employee {
  id: number;
  first_name: string;
  last_name: string;
  is_fav: boolean;
  profile_picture_url: string | null;
  employee_type: string;
  employee_type_id: number;
  employee_type_category: string;
  employee_type_category_id: number;
  paritaire_commitee: string;
  paritaire_commitee_id: number;
  functional_salary: string;
  function_id: number;
  function_name: string;
}
const HeaderWithFilter: React.FC<HeaderWithFilterProps> = ({
  children,
  headerName,
  type,
  startDate,
  page,
  selectedLocation,
  onLocationChange,
  handleEmployeeClick,
  selectedEmployees
}) => {
  const locations = useSelector(selectCurrentLocations) ?? [];
  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);
  // Use selectors to get the status and block status from Redux state
  const companyStatus = useSelector((state: RootState) =>
    selectCompanyStatus(state, companyId)
  );
  const companyBlockedStatus = useSelector((state: RootState) =>
    selectCompanyBlockedStatus(state, companyId)
  );
  // Local state initialized with Redux selector values
  const [coopStatus, setCoopStatus] = useState<boolean>(companyStatus ?? false);
  const [blockStatus, setBlockStatus] = useState<boolean>(companyBlockedStatus ?? false);



  return (
    <div className="row headerWrapperWithFilter ">
      <div className="col-12 ">
        <Header headerName={headerName} headerClassName="myAccountCardTitle" />
      </div>
      <AccessControl
        renderNoAccess={false}
        requiredPermissions={[
          {
            permission: "planing_layout"
          },
        ]}
      >

      {type === "day" &&
        <EmployessNameCard onEmployeeClick={handleEmployeeClick} selectedEmployee={selectedEmployees} />
      }
      </AccessControl>
      <CoopMessageDisplay coopStatus={coopStatus} blockStatus={blockStatus} />
      <div className="col-12">
        <TableHeaderWithIcon
          title={selectedLocation?.name} // Use the selected location name
          TableHeaderWithIconClassName="plannedLocationHeader px-3"
          TableHeaderTitle="poppins-semibold text-white"
          onPreviousClick={() => {
            const prevIndex = (locations.indexOf(selectedLocation) - 1 + locations.length) % locations.length; // Loop back to the last location
            onLocationChange?.(prevIndex); // Call the location change handler
          }}
          onNextClick={() => {
            const nextIndex = (locations.indexOf(selectedLocation) + 1) % locations.length; // Loop back to the first location
            onLocationChange?.(nextIndex); // Call the location change handler
          }}
        />
      </div>
      <PlanningFilterSection type={type} page={page} location={selectedLocation}>
        {children}
      </PlanningFilterSection>
    </div>
  );
};

export default translate(HeaderWithFilter);

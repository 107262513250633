import "./css/login.css";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Icon from "../../../../Icon";
import LoadImage from "../../../../static/images/LoginImage.png";
import Button from "../../../common/atoms/Button";
import IconWithTitle from "../../../common/molecules/IconWithTitle";
import { t, translate } from "../../../CentralDataMangement/translation/Translation";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { ApiCall } from "../../../../services/ApiServices";
import { EMPLOYEE_INVITATION, INVITATION_EXPIRY } from "../../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import LinkCustom from "../../../common/atoms/Link";

const EmployeeInvitation: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [message, setMessage] = useState<string>("");
    const queryParams = new URLSearchParams(location.search);
    const company_name = queryParams.get('company_name');
    const userId = queryParams.get('user_id');
    const companyId = queryParams.get('company_id');

    const items = [
        { name: "Time", title: "SAVE TIME" },
        { name: "pathway", title: "FLEXIBLE" },
        { name: "protection", title: "WEEKLY PAY" },
        { name: "timeSlot", title: "24/7 CONTRACTING" },
        { name: "security", title: "SAFETY" },
        { name: "shakeHands", title: "SECURITY" },
        { name: "Headset", title: "ANSWERS TO ALL YOUR QUESTIONS" },
        { name: "editAgreement", title: "CONTRACTING TOOL" },
    ];

    const handleClick = () => {
        return "/login"
    }

    useEffect(() => {
        checkExpiry();
    }, [userId, companyId]);

    const checkExpiry = async () => {
        try {
            const dataObj = {
                user_id: userId,
                company_id: companyId,
            };

            const response = await ApiCall?.service(
                INVITATION_EXPIRY,
                "POST",
                dataObj,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            setMessage(response?.message);
        } catch (error) {
            console.error(error);
        }
    }

    const handleSubmit = async (status: boolean) => {
        try {
            const dataObj = {
                user_id: userId,
                company_id: companyId,
                status,
            };

            const response = await ApiCall?.service(
                EMPLOYEE_INVITATION,
                "POST",
                dataObj,
                true,
                CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );

            CustomNotify({
                type: response.status === 'success' ? 'success' : 'error',
                message: t(response?.message),
            });
            navigate('/login');
        } catch (error) {
            CustomNotify({
                type: 'error',
                message: t("An error occurred while processing your request."),
            });
        }
    };

    return (
        <div className="container-fluid">
            <div className="loginWrapper">
                <div className="row gx-5">
                    <div className="col-4">
                        <div className="loginLeftSection text-center h-100 position-relative">
                            <div className="loginLeftSectionContent text-white">
                                {t("Completely")}
                                <span className="colorYellow ms-1">{t("digital")},</span>
                                <br />
                                {t("but with a")}
                                <br />
                                {t("smile")}!
                            </div>
                            <div className="loginImageWrapper">
                                <img src={LoadImage} alt={t("login")} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="col-4 loginMiddleSection">
                        <div className="card border-0 loginCard h-100 rounded-0 d-grid align-content-center">
                            <div className="loginCardPadding py-0">
                                <div className="text-center">
                                    <LinkCustom href={handleClick()} className="headerLogo">
                                        <Icon name="Logo" width="15vw" height="3vw" isIcon={true} className="img-fluid mx-auto" />
                                    </LinkCustom>
                                </div>
                                <div className="loginMiddleSectionContent text-center">
                                    <div className="colorSkyBlue loginInText">
                                        {t("Invitation to Join Company")}
                                    </div>
                                    <div className="row">
                                        <p>
                                            {message === ""
                                                ? <>We are thrilled to invite you to join our company <strong>{company_name}</strong>.</>
                                                : <span dangerouslySetInnerHTML={{ __html: message }} />}
                                        </p>
                                        <p>
                                            {message === ""
                                                ? "Kindly confirm your decision by selecting one of the options below:"
                                                : ''}
                                        </p>
                                    </div>
                                    <div className="row">
                                        {!message && <div className="col-12 d-flex">
                                            <div className="col-6 me-2">
                                                <Button
                                                    type="submit"
                                                    title={t("Accept")}
                                                    className="signInBtn w-100"
                                                    handleClick={() => handleSubmit(true)}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Button
                                                    type="submit"
                                                    title={t("Reject")}
                                                    className="signInBtn w-100 me-2"
                                                    handleClick={() => handleSubmit(false)}
                                                />
                                            </div>
                                        </div>}
                                        <div className="col-12 mt-3 forgotLinkWrapper">
                                            <LinkCustom
                                                href="/login"
                                                className="linkColor mobileFon"
                                                title={t("Back to login")}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="loginRightSection h-100">
                            <div className="row">
                                {items.map(item => (
                                    <div className="col-6 text-center" key={item?.name}>
                                        <IconWithTitle
                                            name={item?.name}
                                            isIcon={true}
                                            isTitle={true}
                                            width="10vw"
                                            height="2vw"
                                            className="img-fluid mx-auto"
                                            title={t(`${item?.title}`)}
                                            borderRadius={"10vw"}
                                            labelClassName="text-white loginRightSectionTitle"
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default translate(EmployeeInvitation);

import React from "react";
import Image from "../../common/atoms/Image";
import LoadingIcon from "../../common/utlis/LoadingIcon";
import InputTextfield from "../../common/atoms/InputTextField";
import userIcon from "../../../static/images/User.svg";
import { AxiosCall } from "../../../services/AxiosService";
import { AuthState, DefaultFormState } from "../services/CompanyInterface";
import Icon from "../../../Icon";
import LabelField from "../../common/atoms/LabelField";
import { translate } from "../../CentralDataMangement/translation/Translation";

interface ProfilePictureUploadProps {
    t: (key: string) => string;
    formState: any;
    user: AuthState;
    setFormState: React.Dispatch<React.SetStateAction<any>>;
    title: string;
    fileType: string;
}

const ProfilePictureUpload: React.FC<ProfilePictureUploadProps> = ({
    t,
    formState,
    setFormState,
    title,
    fileType
}) => {

    // Handle file change and upload
    const handleFileChange = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const file = event?.target?.files && event?.target?.files[0];
        const allowedFormats = [".jpg", ".jpeg", ".png"];
        let errorString = "";

        if (file) {
            const fileExtension = `.${file?.name?.split(".").pop()?.toLowerCase()}`;

            // Check for allowed formats
            if (!allowedFormats?.includes(fileExtension)) {
                errorString = `${t("Invalid file format")}, ${t("please upload only")} ${allowedFormats?.join(", ")} ${t("file format")}`;
                console.error(errorString);
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev.errors, file: errorString },
                }));
                return; // Prevent further execution if file format is invalid
            }

            // Check file size
            if (file.size > 2 * 1024 * 1024) { // File size validation: greater than 2MB
                errorString = t("File size is greater than 2MB");
                console.error(errorString);
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev?.errors, file: errorString },
                }));
                return;
            }

            setFormState((prev: DefaultFormState) => ({ ...prev, loading: true }));

            try {
                const formData = new FormData();
                formData.append("file", file);
                formData.append("file_type", fileType);
                formData.append("name", "company_profile_picture");

                const response = await AxiosCall?.fileUploadService(formData, "Employer");

                setFormState((prevValues: DefaultFormState) => ({
                    ...prevValues,
                    formValues: {
                        ...prevValues?.formValues,
                        profile_picture_url: response?.data["fileUrl"],
                        profile_picture_id: response?.data["fileId"],
                    },
                    errors: { ...prevValues?.errors, file: "" },
                }));
            } catch (error) {
                console.error("File upload failed", error);
                setFormState((prev: DefaultFormState) => ({
                    ...prev,
                    errors: { ...prev?.errors, file: t("File upload failed.") },
                }));
            } finally {
                setFormState((prev: DefaultFormState) => ({ ...prev, loading: false }));
            }
        }
    };

    const handleDeleteImage = async () => {

        if (formState.formValues.profile_picture_id !== null) {
            try {
                // Update the form state with default values
                setFormState((prevValues: DefaultFormState) => ({
                    ...prevValues,
                    formValues: {
                        ...prevValues?.formValues,
                        profile_picture_url: "",
                        profile_picture_id: null,
                    },
                    errors: { ...prevValues?.errors, file: "" },
                }));
                // Clear the file input
                const fileInput = document?.getElementById("profile-picture-input") as HTMLInputElement;
                if (fileInput) {
                    fileInput.value = "";
                }
            } catch (error) {
                console.error("Error deleting file: ", error);
            }
        }
    };


    return (
        <div className="col-12">
            {/* <h3 className="bodyTitle colorPrimary">{title}:</h3> */}
            <LabelField title={t(title)} className="bodyTitle colorPrimary mb-3" />
            <div className="mb-3 position-relative">
                <div
                    className={`addCompanyImage d-flex align-items-center justify-content-center ${formState?.formValues?.profile_picture_url
                        ? "userIconPresent"
                        : "p-2"
                        }`}
                    onClick={() => document?.getElementById("profile-picture-input")?.click()}
                    style={{ cursor: "pointer" }}
                >
                    {formState.loading ? (
                        <LoadingIcon iconType="bars" color="#8571b9" className="fileUploadLoading" />
                    ) : (
                        <Image
                            src={formState?.formValues?.profile_picture_url || userIcon}
                            imageWidth="100%"
                            imageHeight="100%"
                            imageBorderRadius="50%"
                        />
                    )}
                </div>
                <InputTextfield
                    type="file"
                    id="profile-picture-input"
                    name="company_profile_picture"
                    customStyle={{ display: "none" }}
                    handleChange={handleFileChange}
                    autoComplete="off"
                />
                {formState?.formValues?.profile_picture_url && (
                    <div
                        onClick={handleDeleteImage}
                        className="delete-icon position-absolute deleteIcon secondaryColorHoverEffect"
                        role="button"
                        data-bs-toggle="tooltip"
                        title={t("Delete")}
                    >
                        <Icon name="delete" isIcon={true} width="0.8vw" height="0.8vw" />
                    </div>
                )}
                {formState?.errors?.file && (
                    <p className="text-danger mt-2">{formState?.errors?.file}</p>
                )}
            </div>
        </div>
    );
};

export default translate(ProfilePictureUpload);

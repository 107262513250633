import React from "react";
import HeaderWIthFilter from "./HeaderWIthFilter";
import PlanningTable from "./PlanningTable";
import {t , translate } from "../CentralDataMangement/translation/Translation";

const PlanningsByLocation = () => {
  return (
    <div className="container-fluid">
      <div className="headerWrapper">
        <HeaderWIthFilter headerName={t("Dashboard")} />
      </div>
      <div className="row">
        <div className="col-12">
          <div className="PlanningTableContainer">
            <PlanningTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(PlanningsByLocation);

import { t } from "../CentralDataMangement/translation/Translation";
import { CooperationAgreementState, EmailField } from "./Interfaces";

export const initialState: CooperationAgreementState = {
  formData: {
    MainDetail: {
      id: 0,
      employer_id: 0,
      company_id: 0,
      sales_agent_id: 0,
      request_at: "",
      sales_signed_at: "",
      employer_signed_at: "",
      assigned_type: 0,
      start_date: "",
      end_date: null,
      status: 0,
      bright_Id: 0,
      created_by: null,
      updated_by: 0,
      is_deleted: 0,
      deleted_by: null,
      employer_req_at: null,
      is_blocked: false,
      blocked_reason: null,
      FirstStep: {
        id: 0,
        consultant_id: 0,
        office_id: 0,
        coop_agg_id: 0,
        add_project: false,
        wp_fische_language: 0,
        auto_sign_wp_fische: 0,
        updated_by: 0,
        who_will_sign_the_wpf: null,
        company_pc_and_employee_types: [],
      },
    },
    CompanyConfig: {
      id: 0,
      coop_agg_id: 0,
      vat_number: "",
      official_company_name: "",
      company_name: "",
      company_rsz_number: "",
      company_type: 0,
      client_number: "",
      browsers: 0,
      legal_address_id: 0,
      legal_address: {
        id: 0,
        street: "",
        number: "",
        box: "",
        city: "",
        country: 0,
        postal_code: "",
        latitude: "",
        longitude: "",
      },
      website: "",
      phone_number: "",
      fax_number: "",
      company_language: 0,
      labour_regulation: 0,
      legal_entity_mail: "",
    },
    CompanyContactPersonDetails: [
      {
        id: 0,
        contact_person_duties_id: 0,
        coop_agg_id: 0,
        created_at: "",
        updated_at: "",
        status: false,
        contact_person_duties: {
          id: 0,
          user_id: 0,
          location_id: 0,
          decision_maker: false,
          contact_title: 0,
          contact_called: false,
          contact_mailed: false,
          contacted_for: "",
          func_title: "",
          influencer: false,
          language: 0,
          created_at: null,
          updated_at: null,
          phone: null,
          mail: null,
          user: {
            id: 0,
            email: "",
            first_name: "",
            last_name: "",
            phone_number: "",
            personal_details: {
              user_id: 0,
              date_of_birth: "",
              place_of_birth: ""
            }
          },
          location: {
            id: 0,
            name: ""
          }
        }
      }
    ],
    CooperationAgreementOnlineDetails: {
      id: 0,
      coop_agg_id: 0,
      online_prestaties_mail: "",
      prestatieblad_mail: "",
      digital_mail: "",
      digital_contract_mail: "",
      invoice_reminder_mail: "",
      online_prestaties: false,
      digital_contract: false,
      online_encodage: false,
      prestatieblad: false,
    },
    CooperationAgreementInvoiceConfig: {
      id: 0,
      coop_agg_id: 0,
      vat_rate: "",
      vat_applicable: false,
      domicilry_payment: false,
      private_customer: false,
      digital_invoice: false,
      invoice_frequency: 0,
      invoice_mail: "",
      payment_condition: null,
      invoice_correction_type: 0,
      invoice_per_employee: false,
      invoice_per_location: false,
      invoice_per_worker_servant: false,
      total_per_cc: false,
      actual_work_hours: false,
      actual_work_hours_per_cc: false,
      medical_health_check: false,
      interim_person_details: false,
      contact_discount: false,
      customer_code: "",
    },
  },
  errors: {},
  offices: {},
  sales: [],
  pcs: {
    workers: [],
    servants: []
  }
};

// OnlineDetails.tsx
export const emailFields: EmailField[] = [
  {
    label: t("Email address legal entity"),
    name: "digital_mail",
    isDisabled: true,
  },
  {
    label: t("Email address online performance"),
    name: "online_prestaties_mail",
  },
  {
    label: t("Email address performance sheet"),
    name: "prestatieblad_mail",
  },
  {
    label: t("Email address digital contracts"),
    name: "digital_contract_mail",
  },
  {
    label: t("Email address invoicing reminders 'rappels'"),
    name: "invoice_reminder_mail",
  },
];

export const radioFields = [
  {
    title: t("Digital contract"),
    name: "digital_contract",
    ids: ["digitalYes", "digitalNo"],
  },
  {
    title: t("Online performance"),
    name: "online_prestaties",
    ids: ["onlineYes", "onlineNo"],
  },
  {
    title: t("New online encodage"),
    name: "online_encodage",
    ids: ["newEncodageYes", "newEncodageNo"],
  },
  {
    title: t("Performance sheet"),
    name: "prestatieblad",
    ids: ["performanceYes", "performanceNo"],
  },
];

export const ContactinputFields = [
  { name: "contact_person_duties.user.first_name", isMandatory: true },
  { name: "contact_person_duties.user.last_name", isMandatory: true },
  { name: "contact_person_duties.func_title", isMandatory: true },
  { name: "contact_person_duties.user.phone_number", isMandatory: true, id: "phoneNo" },
  { name: "contact_person_duties.user.email", isMandatory: true, id: "email" },
  { name: "contact_person_duties.location.label", isMandatory: true },
  { name: "contact_person_duties.contacted_for", isMandatory: true },
  { name: "contact_person_duties.contact_title", isMandatory: true },
  { name: "contact_person_duties.language", isMandatory: true },
];

export const CompanyInputFields = [
  { label: "VAT number", name: "vat_number", id: "vatNumber", isMandatory: false },
  { label: "Official legal company name", name: "official_company_name", id: "legalName", isMandatory: true },
  { label: "Company call name", name: "company_name", id: "companyName", isMandatory: true },
  { label: "Company client number", name: "client_number", id: "clientNumber", isMandatory: true },
  { label: "RSZ-number", name: "company_rsz_number", id: "rszno", isMandatory: false },
  { label: "Legal address: Street", name: "legal_address.street", id: "streetName", isMandatory: true },
  { label: "Legal address: House number", name: "legal_address.number", id: "houseNo", isMandatory: true },
  { label: "Legal address: Box number", name: "legal_address.box", id: "boxNo", isMandatory: false },
  { label: "Legal address: Postal code", name: "legal_address.postal_code", id: "postalCode", isMandatory: true },
  { label: "Legal address: City", name: "legal_address.city", id: "city", isMandatory: true },
  { label: "Legal address: Phone number", name: "phone_number", id: "phoneNo", isMandatory: true },
  { label: "Legal address: Fax number", name: "fax_number", id: "faxNo", isMandatory: false },
  { label: "Legal address: Email", name: "legal_entity_mail", id: "email", isMandatory: true },
  { label: "Website", name: "website", id: "website", isMandatory: false },
  { name: "company_type", id: "company_type", isMandatory: true },
  { name: "legal_address.country", id: "country", isMandatory: true },
  { name: "browsers", id: "browsers", isMandatory: true },
  { name: "company_language", isMandatory: true },
  { name: "labour_regulation", isMandatory: true },
];

export const AbsoluteyouAgentMandatory = [
  { label: "Start date of agreement", name: "start_date", isMandatory: true },
  { label: "AbsoluteYou consultant", name: "sales_agent_id", isMandatory: true },
  { label: "AbsoluteYou office number", name: "FirstStep.office_id", isMandatory: true },
];

export const CompanyNonMandatoryFields = [
  "vat_number",
  "company_rsz_number",
  "legal_address.box",
  "fax_number",
  "website",
];

export const ContactNonMandatoryFields = [
  "contact_person_duties.user.personal_details.date_of_birth",
  "contact_person_duties.location.label"
];
import React, { useEffect, useRef, useState } from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import { t } from "../common/atoms/translation";
import {
  CREATE_EMPLOYER,
  GET_EMPLOYER,
  REGISTER_EMPLOYER,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import CustomNotify from "../common/atoms/CustomNotify";
import { useSelector } from "react-redux";
import {
  AddCompanyModalProps,
  DefaultFormState,
  DropdownOptions,
  FormValues,
  PCType,
  ReduxState,
} from "./services/CompanyInterface";
import { useDispatch } from "react-redux";
import {
  addUserDetails,
  updateOrAddCompany,
} from "../../features/auth/AuthSlice";
import { initialFormValues } from "./State";
import {
  formatVatResponse,
  formValidation,
  populateCompanyForm,
} from "./services/Companyutils";
import UserDetailsForm from "./EmployerRegistration/UserDetailsForm";
import VatNumberField from "./EmployerRegistration/VatNumberField";
import ProfilePictureUpload from "./EmployerRegistration/ProfilePictureUpload";
import AddressForm from "./EmployerRegistration/AddressForm";
import ErrorComponent from "../common/atoms/ErrorComponent";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import Popup from "../CentralDataMangement/manage-functions/Popup";
import { translate } from "../CentralDataMangement/translation/Translation";
import ToggleButton from "../common/atoms/ToggleButton";

const AddCompanyModal: React.FC<AddCompanyModalProps> = ({
  show,
  handleClose,
  companyid,
  countries,
  pc,
  iban,
  timezones,
}) => {
  const user = useSelector((state: ReduxState) => state?.auth);

  const [formState, setFormState] =
    useState<DefaultFormState>(initialFormValues);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const modalContentRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useDispatch();

  const fetchCompany = async (companyid: number) => {
    try {
      const url = `${GET_EMPLOYER}/${companyid}`;
      const response = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      if (response && response?.status === "success") {
        return response?.data;
      } else {
        console.error("Unexpected response status:", response?.status);
      }
    } catch (error) {
      console.error("Error fetching company data:", error);
    }
  };

  const fetchAndPopulateCompany = async (company_id?: number) => {
    const effectiveCompanyId = company_id ?? companyid;

    if (typeof effectiveCompanyId === "number" && show) {
      setFormState((prevValues: DefaultFormState) => ({
        ...prevValues,
        editing: true,
      }));
      try {
        const company: FormValues = await fetchCompany(effectiveCompanyId);
        if (company) {
          const newFormValues: FormValues | null = populateCompanyForm(company);
          if (newFormValues !== null) {
            setFormState((prevValues: DefaultFormState) => ({
              ...prevValues,
              formValues: newFormValues,
              previousPcValues: newFormValues.pc,
            }));
          }
        }
      } catch (error) {
        console.error("Error fetching company data:", error);
      }
    } else {
      setFormState(initialFormValues);
    }
  };

  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  };


  useEffect(() => {
    fetchAndPopulateCompany();
  }, [companyid, show]);

  useEffect(() => {
    setFormState((prevValues: DefaultFormState) => ({
      ...prevValues,
      formValues: {
        ...prevValues.formValues,
        first_name: user?.first_name || "",
        last_name: user?.last_name || "",
        email: user?.email || "",
        phone_number: user?.phone_number || "",
      },
      countryOptions: countries as DropdownOptions[] | undefined,
      pcOptions: pc as DropdownOptions[] | undefined,
      ibancountries: iban as DropdownOptions[] | undefined,
      timezoneOptions: timezones as DropdownOptions[] | undefined,
    }));
  }, [countries, pc, iban, timezones]);

  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "label colorPrimary",
    colClassName: "col-7",
    className: "inputFieldColor ps-2",
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.target;
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: {
        ...prev?.formValues,
        [name]: value,
      },
    }));
  };

  const handleAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "official" | "invoice"
  ) => {
    const { name, value } = e?.target;

    setFormState((prev: DefaultFormState) => {
      let updatedAddress = {
        ...prev.formValues?.address,
        [type]: {
          ...prev.formValues?.address[type],
          [name]: value,
        },
      };

      if (prev?.formValues?.is_invoice_address && type === "official") {
        updatedAddress = {
          ...updatedAddress,
          invoice: {
            ...updatedAddress?.invoice,
            [name]: value,
          },
        };
      }

      return {
        ...prev,
        formValues: {
          ...prev.formValues,
          address: updatedAddress,
          is_invoice_address:
            type === "invoice" ? false : prev?.formValues?.is_invoice_address,
        },
      };
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    // Update the state based on the input name
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: {
        ...prev.formValues,
        security: {
          ...prev.formValues.security,
          [name]: value,
        },
      },
    }));
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e?.target;
    setFormState((prev: any) => {
      const officialAddress = prev?.formValues?.address?.official;
      return {
        ...prev,
        formValues: {
          ...prev?.formValues,
          is_invoice_address: checked,
          address: {
            ...prev?.formValues?.address,
            invoice: checked
              ? {
                ...prev?.formValues?.address?.invoice,
                street: officialAddress?.street,
                number: officialAddress?.number,
                postal_code: officialAddress?.postal_code,
                city: officialAddress?.city,
                box: officialAddress?.box,
                country: officialAddress?.country,
              }
              : {
                ...prev?.formValues?.address?.invoice,
                street: "",
                number: "",
                postal_code: "",
                box: "",
                city: "",
                country: null,
              },
          },
        },
      };
    });
  };

  const handleSelectChange = (
    selectedOption: DropdownOptions | DropdownOptions[] | null | undefined,
    fieldPath: string,
    isMulti = false,
    isEditing = false,
    previousPCValues: string[] = []
  ) => {
    const value: any = isMulti
      ? selectedOption
        ? (selectedOption as DropdownOptions[] | undefined)?.map(
          (option) => option.value
        )
        : []
      : selectedOption
        ? (selectedOption as DropdownOptions).value
        : null;

    setFormState((prev: DefaultFormState) => {
      const updatedFormValues = JSON.parse(JSON.stringify(prev.formValues));
      const fieldNames = fieldPath.split(".");
      const lastField: string = fieldNames.pop() as string;

      const currentLevel = fieldNames.reduce(
        (acc: Record<string, any>, field) => {
          acc[field] = acc[field] || {};
          return acc[field];
        },
        updatedFormValues
      );

      if (isEditing) {
        const previousValues = updatedFormValues[lastField] || [];
        let combinedValues: any[];

        if (isMulti) {
          // Determine the values that are being removed
          const valuesToRemove = previousValues.filter(
            (val: any) => !value?.includes(val)
          );
          const previousValuesToRemove = valuesToRemove.filter((val: any) =>
            previousPCValues.includes(val)
          );

          // Notify only if the user tries to remove a previousPCValue
          if (previousValuesToRemove.length > 0) {
            CustomNotify({
              type: "warning",
              message:
                t("Removal of PC is not allowed.\nYou can only add new PC to the company."),
            });
          }

          // If multiple selections, combine new values without duplicates
          const allValues = [...(previousValues || []), ...(value || [])];
          combinedValues = Array.from(new Set(allValues)); // Avoid duplicates

          // Filter out previousPCValues if they are not in the new combined values
          combinedValues = combinedValues.filter(
            (val) => previousPCValues.includes(val) || value?.includes(val)
          );
        } else {
          combinedValues = previousPCValues.includes(value)
            ? previousPCValues
            : [value];
        }

        currentLevel[lastField] = combinedValues;
      } else {
        currentLevel[lastField] = value;
      }

      return {
        ...prev,
        formValues: updatedFormValues,
      };
    });
  };

  const updateVat = (response?: any, errors?: any) => {
    // If there are errors, update the form state with the error message
    if (errors) {
      setFormState((prev: any) => ({
        ...prev,
        errors: {
          ...prev.errors,
          vat_number: t(errors?.vat_number) || "Error verifying VAT number.",
        },
      }));
      return;
    }

    // If no errors, format the response and update the state
    if (response !== null || response !== undefined) {
      const formattedResponse = formatVatResponse(response, formState, user);
      setFormState((prev: any) => ({
        ...prev,
        ...formattedResponse,
      }));
    }
  };

  const handleSubmit = async () => {
    const errors = formValidation(
      formState,
      user,
      formState?.editing,
      formState.previousPcValues
    );

    if (Object.keys(errors).length > 0) {
      setFormState((prev: DefaultFormState) => ({
        ...prev,
        errors,
      }));
      return;
    }

    try {
      let response;
      if (user?.token) {
        const companyIdToUse = formState?.companyId ?? companyid;
        response = companyIdToUse
          ? await ApiCall?.service(
            `${CREATE_EMPLOYER}/${companyIdToUse}`,
            "PUT",
            formState?.formValues,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          )
          : await ApiCall?.service(
            CREATE_EMPLOYER,
            "POST",
            formState?.formValues,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
      } else {
        response = await ApiCall?.service(
          REGISTER_EMPLOYER,
          "POST",
          formState?.formValues,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
      }

      if (response?.status === "success") {
        const newCompany = {
          id: response?.data?.id,
          name: response?.data?.search_name,
          official_name: response?.data?.official_name,
          profile_picture_url: response?.data?.profile_picture_url,
          locations: [],
        };

        if (response?.data?.id === user?.currentCompany?.id) {
          dispatch(
            addUserDetails({
              currentCompany: {
                id: response?.data?.id,
                name: response?.data?.search_name,
                profile_picture_url: response?.data?.profile_picture_url,
                locations: response?.data?.locations,
              },
            })
          );
          dispatch(updateOrAddCompany(newCompany));
        } else {
          dispatch(updateOrAddCompany(newCompany));
        }

        setFormState(initialFormValues);
        CustomNotify({ type: "success", message: t(response?.message) });
        setErrorMessage(null);
        handleClose();
      } else if (response?.status === "validation-error") {
        const errorMessages = response?.data;
        const formattedErrorMessages = Object.values(errorMessages)
          .flat()
          .join("\n");
        CustomNotify({
          type: "error",
          message: formattedErrorMessages,
        });
      } else {
        if (response?.message.includes("An account with this email address")) {
          setErrorMessage(t(response?.message));
          scrollToTop();
        } else {
          CustomNotify({ type: "error", message: t(response?.message) });
        }
      }
    } catch (error: unknown) {
      console.error(error);
    }
  };

  const close = () => {
    setFormState((prev: DefaultFormState) => ({
      ...prev,
      formValues: initialFormValues?.formValues,
    }));
    setErrorMessage(null);
    handleClose();
  };

  const clearFieldError = (fieldPath: string) => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [fieldPath]: null,
      },
    }));
  };
  const handleAlertClose = () => {
    setErrorMessage(null);
  };

  const showPopup = (company: number) => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      isUpdate: true,
      companyId: company
    }));
  };

  const Confirmpopup = async () => {
    if (formState?.companyId) {
      await fetchAndPopulateCompany(formState?.companyId);
      setFormState((prevState: DefaultFormState) => ({
        ...prevState,
        isUpdate: false,
      }));
    }
  }

  const Rejectpopup = () => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      isUpdate: false,
    }))
  }

  const RejectBreaktime = () => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      formValues: prevState?.formValues,
      isConfirmBreaktime: false, // Only closes the popup, doesn't update form values
    }));
  };


  const confirmBreaktime = () => {
    const updatedFormValues = { ...formState?.formValues };
    updatedFormValues.breaktime_allowance = !formState?.formValues?.breaktime_allowance;
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      formValues: updatedFormValues,
      isConfirmBreaktime: false,
    }));
  }

  const handleToggle = () => {
    setFormState((prevState: DefaultFormState) => ({
      ...prevState,
      isConfirmBreaktime: true,
    }));
  }
  return (
    <>
      {formState?.isUpdate &&
        <Popup
          show={true}
          popupActionNo={Rejectpopup}
          popupActionYes={Confirmpopup}
          title={t("Update existing company")}
          heading={t("A company with this VAT number already exists. Do you want to update the existing company?")}
        />
      }
      {formState?.isConfirmBreaktime &&
        <Popup
          show={true}
          popupActionNo={RejectBreaktime}
          popupActionYes={confirmBreaktime}
          title={t("Confirm breaktime allowance")}
          heading={formState?.formValues?.breaktime_allowance ?
            t("Break time already included, do you want to exclude it from working hours ?") :
            t("Do you want to include the break time in working hours ?")
          }
        />
      }
      <ModalPopup
        show={show}
        handleClose={close}
        modalTitle={
          formState?.editing ? t("Edit company") : t("Register company")
        }
        modalBackgroundColor="#8571b9"
        className="addCompanyModal"
        buttonText={formState?.editing ? t("Update") : t("Register")}
        onNextClick={handleSubmit}
        modalBodyClassName="scrollBar"
        modalContentRef={modalContentRef}
      >
        {errorMessage && (
          <ErrorComponent
            alertClassName="alert-danger"
            alertBody={errorMessage}
            onClose={handleAlertClose}
          />
        )}

        <div className="companyData">
          <div className="row">
            <ProfilePictureUpload
              t={(key: string) => key}
              user={user}
              formState={formState}
              title="Company Profile:"
              setFormState={setFormState}
              fileType="10"
            />
            <div className="col-12">
              <div className="row">
                <div className="col-12">
                  <div className="mb-2">
                    <VatNumberField
                      t={t}
                      companyid={companyid}
                      errors={formState.errors}
                      iban_code={formState?.formValues?.iban_code}
                      vat_number={formState?.formValues?.vat_number}
                      handleSelectChange={handleSelectChange}
                      handleChange={handleChange}
                      Editing={formState?.editing}
                      clearFieldError={clearFieldError}
                      updateVat={updateVat}
                      isVatGet={user?.userId ? true : false}
                      showPopup={showPopup}
                    />
                  </div>
                  <UserDetailsForm
                    formState={formState}
                    handleChange={handleChange}
                    setFormState={setFormState}
                    handlePasswordChange={handlePasswordChange}
                    inputFieldSharedProps={inputFieldSharedProps}
                    t={t}
                    clearFieldError={clearFieldError}
                    user={user}
                  />
                </div>
                <AddressForm
                  formState={formState}
                  setFormState={setFormState}
                  handleAddressChange={handleAddressChange}
                  clearFieldError={clearFieldError}
                  isInvoiceAddress={formState?.formValues?.is_invoice_address}
                  handleSelectChange={handleSelectChange}
                  handleCheckboxChange={handleCheckboxChange}
                  inputFieldSharedProps={inputFieldSharedProps}
                  t={t}
                />
              </div>
              <div className="row">
                <LabelField
                  title={`${t("Sector")}:`}
                  className="bodyTitle colorPrimary my-3"
                />
              </div>
              <div
                className="row multiSelectWrapper my-2"
                onClick={() => clearFieldError("pc")}
              >
                <SelectWithSearch
                  fieldLabel={`${t("Paritair committee")}:`}
                  title={`${t("Paritair committee")}:`}
                  name="pc"
                  placeHolder={t("Select")}
                  search
                  options={formState?.pcOptions || []}
                  value={formState?.formValues?.pc?.map((pcValue: string) =>
                    formState?.pcOptions?.find(
                      (option: DropdownOptions) => option?.value === pcValue
                    )
                  )}
                  onChange={(selectedOptions) =>
                    handleSelectChange(
                      selectedOptions,
                      "pc",
                      true,
                      formState.editing,
                      formState.previousPcValues
                    )
                  }
                  isMulti={true}
                  className="select-field"
                  error={formState?.errors?.pc}
                  isMandatory
                  isMenuPlacement
                  isTranslate
                  labelWrapperClassName="col-3 leftlabelClass"
                  colClassName="col-7"
                  isEditing={true}
                />
              </div>
              <div
                className="row multiSelectWrapper"
                onClick={() => clearFieldError("timezone")}
              >
                <SelectWithSearch
                  fieldLabel={`${t("Time zone")}:`}
                  title={`${t("Time zone")}:`}
                  name="timezone"
                  placeHolder={t("Select")}
                  search
                  options={formState?.timezoneOptions || []}
                  value={
                    formState?.timezoneOptions?.find(
                      (option: DropdownOptions) =>
                        option?.value === formState?.formValues?.timezone
                    ) || null
                  }
                  onChange={(selectedOptions) =>
                    handleSelectChange(selectedOptions, "timezone", false)
                  }
                  isMenuPlacement
                  className="select-field"
                  error={formState?.errors?.timezone}
                  isMandatory
                  isTranslate
                  labelWrapperClassName="col-3 leftlabelClass"
                  colClassName="col-7"
                />
              </div>
              <div className="col-12 d-flex align-items-center mt-4">
                <LabelField
                  title={
                    <>
                      {`${t("Breaktime allowance")}:`}
                      <ToggleButton
                        value={formState?.formValues?.breaktime_allowance}
                        onToggle={handleToggle}
                        toggleClassName='d-inline-block ms-5'
                      />
                    </>}
                  className="colorPrimary bodyTitle"
                />
              </div>
              <div
                className="row multiSelectWrapper mt-3"
                onClick={() => clearFieldError("travel_allowance_pcs")}
              >
                <SelectWithSearch
                  fieldLabel={`${t("Please select the paritair committee for travel allowance for less than 1km")}:`}
                  title={`${t("Please select the paritair committee for travel allowance for less than 1km")}:`}
                  name="travel_allowance_pcs"
                  placeHolder={t("Select")}
                  search
                  options={formState?.pcOptions?.filter((option: any) => formState?.formValues?.pc?.includes(option?.value)) || []}
                  value={formState?.formValues?.travel_allowance_pc?.map((pcValue: string) =>
                    formState?.pcOptions?.find(
                      (option: DropdownOptions) => option?.value === pcValue
                    )
                  )} onChange={(selectedOptions) =>
                    handleSelectChange(selectedOptions, "travel_allowance_pc", true)
                  }
                  isMulti
                  isMenuPlacement
                  className="select-field"
                  error={formState?.errors?.timezone}
                  isTranslate
                  labelWrapperClassName="col-3 leftlabelClass"
                  colClassName="col-7"
                />
              </div>
            </div>
          </div>
        </div>
      </ModalPopup>
    </>
  );
};
export default translate(AddCompanyModal);

import React from "react";
interface ToggleButtonProps {
  value?: boolean;
  onToggle?: (value: boolean) => void;
  disabled?: boolean;
  toggleClassName?: string;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  value = false,
  onToggle = () => { },
  disabled = false,
  toggleClassName,
}) => {
  const ToggleButton = require("react-toggle-button");

  const handleToggle = () => {
    if (disabled) return;
    onToggle(!value);
  };

  return (
    <div className={`custom-toggle-container ${value ? "active" : ""} ${toggleClassName}`}>
      <ToggleButton
        value={value}
        onToggle={handleToggle}
        inactiveLabel=""
        colors={{
          active: {
            base: "#8571b9",
          },
          inactive: {
            base: "#898989",
          },
          activeThumb: {
            base: "#fff",
          },
          inactiveThumb: {
            base: "#fff",
          },
        }}
        inactiveThumbStyle={{
          boxShadow: "none",
        }}
      />
    </div>
  );
};

export default ToggleButton;
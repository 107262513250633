import EncryptDecryptService from "./EncryptDecryptService";
import store, { RootState } from "../store";
import secureLocalStorage from "react-secure-storage";
import { refreshOauthTokenIfNeeded } from "./OAuthService";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
	"Accept"?: string;
	"Content-Type"?: string;
	Authorization?: any;
	AuthToken?: string;
	"x-api-key"?: string;
	"x-tenant-id"?: string;
	//   Access-Control-Allow-Origin": string
};

const getCurrentUserId = () => {
	const currentState: RootState = store.getState(); // Get the current state
	const userId = currentState.auth.userId;
	const role = currentState.auth.role;
	return { userId, role };
};


async function getService(endpoint: string = "", method: string = "GET", microService = process.env.REACT_APP_MICROSERVICE, loadingParam: boolean = false, Obj = {}) {
	const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
	if (externalElement && loadingParam) {
		externalElement.style.display = "flex";
	}
	const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
	const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

	const oAuthToken = await refreshOauthTokenIfNeeded();

	const headers: Headers = {
		"Accept": "application/json",
		"Content-Type": "application/json",
		// "Access-Control-Allow-Origin": "*",
		Authorization: `Bearer ${authToken}`,
		"x-api-key": oAuthToken,
		"x-tenant-id": process.env.REACT_APP_CLIENT,
	};
	try {
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
		const response = await fetch(url, {
			method: method,
			headers: headers
		});
		if (response.status === 401) logoutUser();

		const data = await response.json();
		if (externalElement && loadingParam) {
			externalElement.style.display = "none";
		}

		return data;
	} catch (error) {

	}
}

async function service(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = process.env.REACT_APP_MICROSERVICE ?? 'api',
	isBlob = false

) {
	try {
		const { userId, role } = getCurrentUserId();
		const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');
		const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}

		const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en';

		const oAuthToken = await refreshOauthTokenIfNeeded();

		const headers: Headers = {
			"Accept": "application/json",
			"Content-Type": "application/json",
			// "Access-Control-Allow-Origin": "*",
			Authorization: `Bearer ${authToken}`,
			"x-api-key": oAuthToken,
			"x-tenant-id": process.env.REACT_APP_CLIENT,

		};
		// const dataObj = {userId: userId, role: role};
		dataObj = { ...dataObj, userId: userId, userRole: role, appLangKey: appLangKey }
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;
		const response = await fetch(url, {
			method: method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			redirect: "follow",
			body: JSON.stringify(dataObj),
			headers: headers,
		});
		if (response.status === 401) logoutUser();

		let data;

		if (isBlob) {
			data = await response.blob();
		} else {
			data = await response.json();

		}

		if (externalElement && loadingParam) {
			externalElement.style.display = "none";
		}
		return data;
	} catch (error) {
		console.log(error);
	}
}

async function fileSendService(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = process.env.REACT_APP_MICROSERVICE ?? 'api',
) {
	try {
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');

		const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}

		const oAuthToken = await refreshOauthTokenIfNeeded();

		const headers: Headers = {
			"Accept": "application/json",
			"Content-Type": "multipart/form-data",
			// "Access-Control-Allow-Origin": "*",
			// Authorization: token,
			Authorization: `Bearer ${authToken}`,
			"x-api-key": oAuthToken,
			"x-tenant-id": process.env.REACT_APP_CLIENT,
		};
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY}service/${microService}/${endpoint}`;

		const response = await fetch(url, {
			method: method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			redirect: "follow",
			body: JSON.stringify(dataObj),
			headers: headers,
		});

		if (response.status === 401) logoutUser();
		const data = await response.json();
		if (externalElement && loadingParam) {
			externalElement.style.display = "none";
		}
		return data;
	} catch (error) {
		console.log(error);
	}
}

const logoutUser = () => {
	localStorage.clear();
	window.location.href = '/login';
}

export const ApiCall = {
	service,
	getService,
	fileSendService
};

import React from "react";
import ProfilePictureUpload from "../../../../Dashboard/EmployerRegistration/ProfilePictureUpload";
import { LabelWithInputField } from "../../../../common/molecules/LabelWithInputField";
import { t } from "../../../../CentralDataMangement/translation/Translation";
import LabelField from "../../../../common/atoms/LabelField";
import Calender from "../../../../common/molecules/Calender";
import SelectWithSearch from "../../../../common/atoms/SelectWithSearch";
import { DropdownOptions } from "../../../../Dashboard/services/CompanyInterface";

interface PersonalInformationProps {
  formState: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateFormat: (dateValue: Date | null, field: string) => void;
  handleSelectChange: (selectedOption: any, fieldPath: string, isMulti?: boolean) => void;
  clearFieldError: (fieldPath: string) => void;
  optionsData: any[];
  setFormState: any;
}

const PersonalInformationOrganism: React.FC<PersonalInformationProps> = ({
  formState,
  handleChange,
  handleDateFormat,
  handleSelectChange,
  clearFieldError,
  optionsData,
  setFormState
}) => {
  const inputFieldProps = {
    labelWrapperClassName: "col-4 align-self-center pe-0",
    colClassName: "col-8",
    className: "inputFieldColor",
  };

  return (
    <div className="contentBg mb-3 p-0">
      <ProfilePictureUpload
        t={(key: string) => key}
        user={formState.user}
        title={t("Manage my account")}
        formState={formState}
        setFormState={setFormState}
        fileType="1"
      />

      {/* Function Input */}
      <div className="row mb-3" onClick={() => clearFieldError("function")}>
        <LabelWithInputField
          label={t("Function") + ":"}
          type="text"
          value={formState?.formValues?.function}
          name="function"
          handleChange={handleChange}
          id="function"
          error={formState?.errors?.function}
          {...inputFieldProps}
        />
      </div>

      {/* First Name Input */}
      <div className="row mb-3" onClick={() => clearFieldError("first_name")}>
        <LabelWithInputField
          label={t("First name") + ":"}
          type="text"
          value={formState?.formValues?.first_name}
          name="first_name"
          handleChange={handleChange}
          id="first_name"
          error={formState?.errors?.first_name}
          isMandatory
          {...inputFieldProps}
        />
      </div>

      {/* Last Name Input */}
      <div className="row mb-3" onClick={() => clearFieldError("last_name")}>
        <LabelWithInputField
          label={t("Last name") + ":"}
          type="text"
          value={formState?.formValues?.last_name}
          name="last_name"
          handleChange={handleChange}
          id="last_name"
          error={formState?.errors?.last_name}
          isMandatory
          {...inputFieldProps}
        />
      </div>

      {/* Email Input */}
      <div className="row mb-3" onClick={() => clearFieldError("email")}>
        <LabelWithInputField
          label={t("Email") + ":"}
          type="email"
          value={formState?.formValues?.email}
          name="email"
          handleChange={handleChange}
          id="email"
          error={formState?.errors?.email}
          isMandatory
          {...inputFieldProps}
        />
      </div>

      {/* Date of Birth */}
      <div className="row mb-3" onClick={() => clearFieldError("date_of_birth")}>
        <div className="col-4 leftlabelClass">
          <LabelField title={t("Date of birth") + ":"} isMandatory={true} />
        </div>
        <div className="col-8">
          <div className="position-relative calendarSectionWrapper">
            <Calender
              onChange={(date) =>
                date && handleDateFormat(date, "date_of_birth")
              }
              selectedDate={
                formState?.formValues?.date_of_birth
                  ? new Date(formState?.formValues?.date_of_birth)
                  : null
              }
              maxDate={new Date()}
              error={formState?.errors?.date_of_birth}
              name="date_of_birth"
            />
          </div>
        </div>
      </div>

      {/* Language Selection */}
      <div className="row mb-3 multiSelectWrapper" onClick={() => clearFieldError("language_id")}>
        <SelectWithSearch
          fieldLabel={t("Language") + ":"}
          title={t("Language")}
          name="language_id"
          placeHolder={t("Select")}
          isMandatory={true}
          search={true}
          options={optionsData}
          value={
            optionsData?.find(
              (option: any) => option?.value === formState?.formValues?.language_id
            ) || null
          }
          onChange={(selectedOption: any) =>
            handleSelectChange(selectedOption, "language_id")
          }
          isMulti={false}
          className="select-field"
          error={formState?.errors?.language_id}
          isTranslate={true}
          labelWrapperClassName="col-4 leftlabelClass"
          colClassName="col-8"
        />
      </div>

      {/* Address Inputs */}
      <div className="row mb-3" onClick={() => clearFieldError("address.residence.street")}>
        <LabelWithInputField
          label={t("Street") + ":"}
          type="text"
          value={formState?.formValues?.address?.residence?.street}
          name="address.residence.street"
          handleChange={handleChange}
          id="street"
          isMandatory
          error={formState?.errors?.["address_residence_street"]}
          {...inputFieldProps}
        />
      </div>
      <div className="row mb-3" onClick={() => clearFieldError("address.residence.box")}>
        <LabelWithInputField
          label={t("Box") + ":"}
          type="text"
          value={formState?.formValues?.address?.residence?.box}
          name="address.residence.box"
          handleChange={handleChange}
          id="box"
          error={formState?.errors?.[`address_residence_box`]}
          {...inputFieldProps}
        />
      </div>
      <div className="row mb-3" onClick={() => clearFieldError("address.residence.number")}>
        <LabelWithInputField
          label={t("House no") + ":"}
          type="text"
          value={formState?.formValues?.address?.residence?.number}
          name="address.residence.number"
          handleChange={handleChange}
          id="number"
          isMandatory
          error={formState?.errors?.[`address_residence_number`]}
          {...inputFieldProps}
        />
      </div>
      <div className="row mb-3" onClick={() => clearFieldError("address.residence.postal_code")}>
        <LabelWithInputField
          label={t("Postal code") + ":"}
          type="text"
          value={formState?.formValues?.address?.residence?.postal_code}
          name="address.residence.postal_code"
          handleChange={handleChange}
          id="postalcode"
          isMandatory
          error={formState?.errors?.[`address_residence_postal_code`]}
          {...inputFieldProps}
        />
      </div>
      <div className="row mb-3 multiSelectWrapper" onClick={() => clearFieldError("address.residence.country")}>
        <SelectWithSearch
          fieldLabel={`${t("Country")}: `}
          title={`${t("Country")}:`}
          name="address.residence.country"
          placeHolder={t("Select")}
          search
          options={formState.countries || []}
          value={formState?.countries?.find(
            (option: DropdownOptions) => option.value === formState?.formValues?.address?.residence?.country
          ) || null}
          onChange={(selectedOptions) =>
            handleSelectChange(selectedOptions, "address.residence.country", false)
          }
          isMulti={false}
          className="select-field"
          isTranslate
          isMandatory
          error={formState?.errors?.[`address_residence_country`]}
          labelWrapperClassName="col-4 leftlabelClass"
          colClassName="col-8"
        />
      </div>
      <div className="row mb-3" onClick={() => clearFieldError("phone_number")}>
        <LabelWithInputField
          label={t("Mobile no") + ":"}
          type="text"
          value={formState?.formValues?.phone_number}
          name="phone_number"
          handleChange={handleChange}
          id="mobileno"
          error={formState?.errors?.phone_number}
          isMandatory
          {...inputFieldProps}
        />
      </div>
    </div>
  );
};

export default PersonalInformationOrganism;

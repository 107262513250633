// import { useCallback, useRef, useState } from "react";
import React from "react";
import { CREATE_QR_CODE, QR_CODE } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

export const formattedDate = (date: string | number | Date | undefined): string => {
    if (!date) return "";

    const createdDate = new Date(date);

    // Get components of the date and time
    const day = String(createdDate.getDate()).padStart(2, "0");
    const month = String(createdDate.getMonth() + 1).padStart(2, "0");
    const year = createdDate.getFullYear();
    const hours = String(createdDate.getHours()).padStart(2, "0");
    const minutes = String(createdDate.getMinutes()).padStart(2, "0");
    const seconds = String(createdDate.getSeconds()).padStart(2, "0");

    // Construct the formatted date string
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
};

export const createQrCode = async (
    companyId: number | null,
    userId: number | null
) => {
    let url = `${CREATE_QR_CODE}`;
    let dataObj = {
        company_id: companyId,
        user_id: userId,
    };

    try {
        const response = await ApiCall?.service(
            url,
            "POST",
            dataObj,
            true,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response?.message === "Success") {
            return response?.data?.link;
        }
    } catch (error) {
        console.error("Error creating QR code:", error);
    }
    return "";
};

export const fetchData = async (
    companyId: number | null,
    userId: number | null,
    createQrCode: (companyId: number | null, userId: number | null) => Promise<string>
) => {
    try {
        const url = `${QR_CODE}?companyId=${companyId}&userId=${userId}`;
        const response = await ApiCall?.getService(
            url,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        );

        if (response?.message === "Success") {
            return {
                qrCodeUrl: response?.data?.qr_code_path ?? (await createQrCode(companyId, userId)),
                createdOn: response?.data?.created_at ? formattedDate(response?.data?.created_at) : "",
            };
        }
    } catch (error) {
        console.error("Error fetching QR Code data:", error);
    }
    return { qrCodeUrl: "", createdOn: "" };
};

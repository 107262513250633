import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";
import { formatDateTime } from "../common/utlis/dateHelper";
import LabelField from "../common/atoms/LabelField";
import NoRecords from "../common/atoms/NoRecords";
import { GET_COST_CENTER_BY_COMPANY, GET_PROJECTS_BY_COMPANY, SHIFTS } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import { selectCompanyId } from "../../features/planning/planningSlice";
import { Link } from "react-router-dom";
import { ADD_COSTCENTER, ADD_PROJECT } from "../../routes/RouteConstants";
import { TRANSPORT_ALLOWANCES, TRANSPORT_TYPES, TransportOption } from "./TransportTypeConstants";
import RadioField from "../common/atoms/RadioField";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { selectCurrentCompany } from "../../features/auth/AuthSlice";


interface OverlayTooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  selectedProject?: (projectId: number, type: number, field: string) => void;
  selectedCloneDates?: (dates: string[]) => void;
  placement?: "top" | "right" | "bottom" | "left";
  selectedProjectId?: number
  selectedCcId?: number;
  transportType?: number;
  transportAllowance?: number,
  cloneDates: string[],
  onTransportTypeChange?: (value: TransportOption) => void;
  onTransportAllowanceChange?: (value: TransportOption) => void;
  onShiftSelect?: (shift: any) => void;
}

const OverlayTooltip: React.FC<OverlayTooltipProps> = ({
  children,
  tooltipText,
  selectedProject,
  selectedCloneDates,
  placement = "right",
  selectedProjectId,
  transportType,
  transportAllowance,
  selectedCcId,
  cloneDates,
  onTransportTypeChange,
  onTransportAllowanceChange,
  onShiftSelect,
}) => {
  const companyId = useSelector(selectCurrentCompany)?.id;
  const [showTooltip, setShowTooltip] = useState(false);
  const [type, setType] = useState<string | null>(tooltipText);
  const [dropData, setDropData] = useState<any>([]);
  const target = useRef<HTMLSpanElement | null>(null);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [updatedDates, setUpdatedDates] = useState<string[]>([]); // New state
  const [selectedTransportType, setSelectedTransportType] = useState<TransportOption | null>(null);
  const [selectedTransportAllowance, setSelectedTransportAllowance] = useState<TransportOption | null>(null);
  // Fetch the corresponding option based on ID
  useEffect(() => {
    if (transportType != null) {
      const typeOption = TRANSPORT_TYPES.find((option) => option.value === transportType);
      setSelectedTransportType(typeOption || null);
    }
  }, [transportType]);

  useEffect(() => {
    if (transportAllowance != null) {
      const allowanceOption = TRANSPORT_ALLOWANCES.find((option) => option.value === transportAllowance);
      setSelectedTransportAllowance(allowanceOption || null);
    }
  }, [transportAllowance]);

  const handleTransportTypeChange = (selected: TransportOption) => {
    setSelectedTransportType(selected);
    onTransportTypeChange?.(selected);

    // Reset transport allowance when transport type changes
    if (selected.value === 0) {
      setSelectedTransportAllowance(null);
    }
  };

  const handleTransportAllowanceChange = (selected: TransportOption) => {
    setSelectedTransportAllowance(selected);
    onTransportAllowanceChange?.(selected);
  };

  const handleClickInside = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const renderTransportSection = () => (
    <div onClick={handleClickInside} className="container-fluid ps-0 transportSection">
      <div className="row">
        <div className="col-6">
          <LabelField title={t("Select transport type:")} />
          {TRANSPORT_TYPES.map((option) => (
            <div key={option.value} className="text-start">
              <RadioField
                id={`transport-type-${option.value}`}
                ischecked={selectedTransportType?.value === option.value}
                handleChange={() => handleTransportTypeChange(option)}
                name="transport-type"
                label={t(option.label)}
                containerClassName="companyLanguage d-inline-flex me-4"
              />
            </div>
          ))}
        </div>
        <div className="col-6">
          {selectedTransportType != null && selectedTransportType?.value !== 0 && (
            <>
              <LabelField title={t("Select travel allowance:")} className="text-nowrap" />
              {TRANSPORT_ALLOWANCES.map((option) => (
                <div key={option.value} className="text-start">
                  <RadioField
                    id={`transport-allowance-${option.value}`}
                    ischecked={selectedTransportAllowance?.value === option.value}
                    handleChange={() => handleTransportAllowanceChange(option)}
                    name="transport-allowance"
                    label={t(option.label)}
                    containerClassName="companyLanguage d-inline-flex me-4"
                  />
                </div>
              ))}
            </>
          )}
        </div>
      </div>


    </div>
  );

  const handleShiftSelect = (shift: any) => {
    if (onShiftSelect) {
      onShiftSelect(shift);
      setShowTooltip(false); // Close tooltip after selection
    }
  };

  const renderShiftContent = () => (
    <>
      {dropData?.shifts?.length > 0 && dropData?.shifts?.map((item: any) => (
        <div
          key={item.shift_name}
          className="bg-white rounded-3 p-1 ps-3 mb-2 cursor-pointer hover:bg-gray-100"
          onClick={() => handleShiftSelect(item)}
        >
          <div className="row">
            <div className="col-8 text-start text-break">
              <span className="locationName">{item.shift_name}</span>
            </div>
            <div className="col-4">
              <div className="d-inline-flex align-items-center">
                <button type="button" title={t("Only for selected day.")} className="searchBtn me-2" onClick={() => handleShiftSelect(item)} >{t('Update')}</button>
                {/* <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2, 'project_id')}>{t('All')}</button> */}
              </div>
            </div>
          </div>
        </div>
      ))}
      {(dropData?.shifts?.length === 0 || dropData?.length == 0) && (
        <NoRecords isDiv={true} className={'text-danger'} message={t("No shifts available")} />
      )}
    </>
  );
  useEffect(() => {
    if (showTooltip == false) {
      setUpdatedDates([]);
    }

    type && fetchdataByType(type);
    setShowTooltip(showTooltip)
  }, [showTooltip, type]);

  const handleOnClick = (project: any, type: number, field: string) => {
    if (selectedProject != undefined) {
      selectedProject(project.id, type, field); // Pass project ID and type to the parent
    }
  };
  const handleDateSelect = (date: string) => {
    setSelectedDates((prevDates) => {
      const newDates = prevDates.includes(date)
        ? prevDates.filter(d => d !== date)
        : [...prevDates, date];

      if (selectedCloneDates) {
        selectedCloneDates(newDates);
      }
      return newDates;
    });
    // Update updatedDates state
    setUpdatedDates((prevUpdatedDates) => {
      return prevUpdatedDates?.includes(date)
        ? prevUpdatedDates?.filter(d => d !== date)
        : [...prevUpdatedDates, date];
    });
  };

  const handleSelectAll = () => {
    const isAllSelected = updatedDates?.length === cloneDates.length;
    // Update all dates as updated when "Update all" is clicked
    setUpdatedDates(isAllSelected ? [] : cloneDates);
    const newSelectedDates = cloneDates;
    setSelectedDates(newSelectedDates);
    if (selectedCloneDates && updatedDates) {
      selectedCloneDates(newSelectedDates);
    }
  };
  const fetchdataByType = async (type: string) => {
    try {
      const { endPoint, method } = getUrlEndPointByType(type);
      if (endPoint !== "" && method != "") {
        let response;
        if (method == "GET") {
          response = await ApiCall.getService(endPoint, 'GET', CENTRAL_DATA_MANAGEMENT_MICROSERVICE)
        }
        else {
          response = await ApiCall.service(endPoint, method, { company_id: companyId }, false);
        }
        if (response?.status === 'success') {
          setDropData(response.data);
        } else {
          setDropData([]);
          console.error("Failed to fetch data:", response?.message);
        }
      }
    } catch (error) {
      console.error("Error fetching data by type:", error);
    }
  };

  const getUrlEndPointByType = (type: string) => {
    const endpoints: Record<string, { endPoint: string; method: string }> = {
      Projects: { endPoint: GET_PROJECTS_BY_COMPANY, method: 'POST' },
      "Cost center": { endPoint: GET_COST_CENTER_BY_COMPANY, method: 'POST' },
      Shifts: { endPoint: `${SHIFTS}/${companyId}`, method: 'GET' },
    };
    const endpoint = endpoints[type];
    if (!endpoint) {
      console.error(`Unsupported type: ${type}`);
      return { endPoint: '', method: 'POST' };
    }
    return endpoint;
  };


  const renderTooltipContent = () => {
    switch (type) {
      case "Projects":
        return (
          <>
            <>
              {dropData?.length > 0 && dropData?.map((project: any) => (
                <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedProjectId === project?.id ? "employee-selected" : ""}`}>
                  <div className="row">
                    <div className="col-8 text-start text-break">
                      <span className="locationName">{project.name}</span>
                    </div>
                    <div className="col-4">
                      <div className="d-inline-flex align-items-center">
                        <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(project, 1, "project_id")} >1</button>
                        <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2, 'project_id')}>{t('All')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <Link
                    to={ADD_PROJECT}
                    target="_blank"
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add project")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      case "Cost center":
        return (
          <>
            {dropData?.length > 0 && dropData?.map((item: any) => (
              <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedCcId === item?.id ? "employee-selected" : ""}`}>
                <div className="row">
                  <div className="col-8 text-start text-break">
                    <span className="locationName">{t(item?.name)}</span>
                  </div>
                  <div className="col-4">
                    <div className="d-inline-flex align-items-center">
                      <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(item, 1, 'cost_center_id')} >1</button>
                      <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(item, 2, 'cost_center_id')}>{t('All')}</button>
                    </div>
                  </div>
                </div>
              </div>

            ))}
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <Link
                    to={ADD_COSTCENTER}
                    target="_blank"
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add Cost center")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </Link>
                </div>
                {/* <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">{t('All')}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        );
      case "Clone":
        return (
          renderCloneContent()
        );
      case "Shifts":
        return renderShiftContent();
      case "Relocation":
        // return (
        //   <div className="mt-2 tooltip-content" onClick={handleClickInside}>
        //     <div className="row">
        //       <div className="col-12 mb-2">
        //         <div className="select-container">
        //           <SelectWithSearch
        //             search={true}
        //             options={TRANSPORT_TYPES}
        //             value={selectedTransportType}
        //             onChange={handleTransportTypeChange}
        //             placeHolder={t("Select transport type")}
        //             name="transport_type"
        //             selectWrapperClassName="multiSelectBgWhite"
        //             isMandatory={true}
        //           />
        //         </div>
        //       </div>
        //       {selectedTransportType?.value !== 0 && (
        //         <div className="col-12">
        //           <div className="select-container">
        //             <SelectWithSearch
        //               search={true}
        //               options={TRANSPORT_ALLOWANCES}
        //               value={selectedTransportAllowance}
        //               onChange={handleTransportAllowanceChange}
        //               placeHolder={t("Select travel allowance")}
        //               name="transport_allowance"
        //               selectWrapperClassName="multiSelectBgWhite"
        //               isMandatory={true}
        //             />
        //           </div>
        //         </div>
        //       )}
        //     </div>
        //   </div>
        // );
        return renderTransportSection();
      default:
        return <NoRecords isDiv={true} className={'text-danger'} message={t("No data available")} />;
    }
  };
  const renderCloneContent = () => (
    <>
      <div className="d-flex justify-content-between mb-2 align-items-center">
        <LabelField title={cloneDates?.length > 0 ? t("Select dates to clone:") : t("Not found any dates without timings.")} />
        {cloneDates.length > 0 &&
          <button
            className="searchBtn ms-2 px-1"
            onClick={handleSelectAll}
          >
            {t("Update all")}
          </button>
        }
      </div>
      {cloneDates
        .slice()
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((date) => (
          <div key={date} className="bg-white rounded-3 p-2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <span className="locationName mobileFont">{formatDateTime(new Date(date))}</span>
              <button
                type="button"
                className={`${!updatedDates?.includes(date) ? "resetBtn px-3" : "searchBtn"}`}
                onClick={() => handleDateSelect(date)}
              >
                {updatedDates.includes(date) ? t("Updated") : t("Update")}
              </button>
            </div>
          </div>
        ))}
    </>
  );

  return (
    <>
      {showTooltip && (
        <div
          className="overlay-backdrop"
          onClick={() => setShowTooltip(false)}
        />
      )}
      <span
        onClick={() => setShowTooltip(!showTooltip)}
        ref={target}
        className="me-2 cursor-pointer"
      >
        {children}
      </span>

      <Overlay target={target.current} show={showTooltip} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-tooltip" {...props}>
            {!['Clone', 'Shifts'].includes(tooltipText) && (
              <div>
                {tooltipText == "Projects" && (
                  <>
                    <button
                      onClick={() => setType("Projects")}
                      className={`searchBtn me-2 ${type === "Projects" ? "active" : ""
                        }`}
                    >
                      {t("Projects")}
                    </button>
                    <button
                      onClick={() => setType("Cost center")}
                      className={`searchBtn me-2 ${type === "Cost center" ? "active" : ""
                        }`}
                    >
                      {t("Cost center")}
                    </button>
                    <span
                      className="secondaryColorHoverEffect me-2"
                      title={t("Edit")}
                      onClick={() => { }}
                    >
                      <Icon isIcon={true} name="EditIcon" width="1vw" height="1vw" />
                    </span>
                  </>
                )}
                <>
                  {/* <span
                    className="secondaryColorHoverEffect me-2"
                    title={t("Add")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} name="add" width="1vw" height="1vw" />
                  </span> */}
                  {tooltipText != 'Relocation' &&
                    <span
                      className="secondaryColorHoverEffect me-2"
                      title={t("Settings")}
                      onClick={() => { }}
                    >
                      <Icon isIcon={true} name="Settings" width="1vw" height="1vw" />
                    </span>
                  }
                </>
              </div>
            )}
            <OverFlowWrapper className="employeeSchedulingTooltip">
              <div>{renderTooltipContent()}</div>
            </OverFlowWrapper>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default translate(OverlayTooltip);

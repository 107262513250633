import React, { useEffect, useState } from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import AccordionCustom from "../common/atoms/AccordionCustom";
import CheckBoxField from "../common/atoms/CheckBoxField";
import InputTextfield from "../common/atoms/InputTextField";
import LabelField from "../common/atoms/LabelField";
import Navigation from "./Navigation";
import { SalaryBenefitsProps } from "./Interfaces";
import { ApiCall } from "../../services/ApiServices";
import { convertCompanyPcAndEmployeeTypesToRequest } from "./Service";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { GET_SALARY_BENEFITS } from "../../routes/ApiEndpoints";

const SalaryBenefits: React.FC<SalaryBenefitsProps> = ({ formState, handleNext, handlePrevious }) => {
  const [isLastTab, setIsLastTab] = useState(false);
  const [data, setData] = useState<any>(null);

  const fetchSalaryBenefits = async () => {
    const requestObj = convertCompanyPcAndEmployeeTypesToRequest?.(
      formState?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types ?? []
    );

    if (requestObj && Object?.keys?.(requestObj)?.length > 0) {
      try {
        const result = await ApiCall?.service?.(
          GET_SALARY_BENEFITS,
          "POST",
          requestObj,
          false,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        setData(result?.data ?? []);
      } catch (error) {
        console.error("Error fetching salary benefits data:", error);
      }
    }
  };

  useEffect(() => {
    fetchSalaryBenefits();
  }, [formState?.formData?.MainDetail?.FirstStep?.company_pc_and_employee_types]);

  return (
    <div style={{ minHeight: "calc(100vh - 22vw)" }}>
      {data?.length > 0 &&
        data?.map((pc: any) => (
          <AccordionCustom
            key={pc?.pc_id}
            accordionTitle={pc?.pc_name}
            accordionTitleClassName="w-100 poppins-medium dashboardAccordionTitle"
            accordionButton="dashboardAccordionHeader shadow-none"
          >
            {pc?.employee_types?.length > 0 &&
              pc?.employee_types?.map((employee: any) => (
                <div className="row" key={employee?.employee_type_id}>
                  <div className="col-12 mb-2">
                    <LabelField title={employee?.employee_type_name} />
                  </div>
                  <div className="col-12">
                    <table className="table mb-0 table-bordered">
                      <tbody>
                        {Object?.entries?.(employee?.salary_benefits ?? {})?.map(([code, benefits]: any) =>
                          benefits?.map?.((benefit: any) => (
                            <tr key={benefit?.salary_benefit_value_id}>
                              <td style={{ width: "50%" }} className="align-middle">
                                <CheckBoxField
                                  name={`salaryBenefit-${benefit?.salary_benefit_value_id}`}
                                  label={benefit?.bbright_perfcode_name}
                                  className="my-checkbox"
                                  isChecked={false}
                                  onChangeHandler={() => { }}
                                  id={`salaryBenefit-${benefit?.salary_benefit_value_id}`}
                                  lineHeight="1vw"
                                />
                              </td>
                              <td>
                                <InputTextfield
                                  value={benefit?.wagecode_value ?? ""}
                                  handleChange={() => { }}
                                  isDisabled
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
          </AccordionCustom>
        ))}
      <Navigation isLastTab={isLastTab} onPrevious={handlePrevious} onNext={handleNext} />
    </div>
  );
};

export default SalaryBenefits;
import React, { useEffect, useState } from "react";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Header from "../common/layout/Header";
import TableHeaderWithIcon from "../common/molecules/TableHeaderWithIcon";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import ShiftTiming from "../Planning/ShiftTiming";
import "./css/timeRegistration.css";
import userImage from "../../static/images/User.svg";
import OverFlowWrapper from "../common/OverFlowWrapper";
import DateAndTime from "../common/atoms/DateWithTime";
import { useNavigate, useParams } from "react-router-dom";
import Icon from "../../Icon";
import PlanningEmployeeDetailsCard from "../common/molecules/PlanningEmployeeDetailsCard";
import { useSelector } from "react-redux";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import { ENCODAGE_CREATE, ENCODAGE_OVERVIEW, ENCODAGE_PRINT } from "../../routes/ApiEndpoints";
import Button from "../common/atoms/Button";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import LabelField from "../common/atoms/LabelField";
import InputTextfield from "../CentralDataMangement/configurations/manageCoefficientDeviations/InputTexfield";
import CustomNotify from "../common/atoms/CustomNotify";
import TimeRegistrationOverviewHelper, { useTimeRegistrationStyles } from './TimeRegistrationOverviewHelper';

const TimeRegistrationOverview = () => {

  const { widthStylesColOne, widthStylesColTwo } = useTimeRegistrationStyles();
  const navigate = useNavigate();
  const params = useParams();
  const [activeTab, setActiveTab] = useState<any>("");

  const [openEmployees, setOpenEmployees] = useState<Employee[]>([]);
  const [closedEmployees, setClosedEmployees] = useState<Employee[]>([]);

  const [selectedShift, setSelectedShift] = useState<{
    [key: string]: string[]
  }>({});

  const [employeeErrors, setEmployeeErrors] = useState<{
    [key: string]: string | null;
  }>({});

  const [searchVisible, setSearchVisible] = useState<boolean>(false); // State for toggling search input visibility
  const [searchQuery, setSearchQuery] = useState<string>(""); // State for search query

  const companyId = useSelector((state: any) => state.auth?.currentCompany?.id);
  const locations = useSelector(selectCurrentLocations) ?? [];

  // for locations selection
  const initialCurrentIndex = 0;
  const [currentIndex, setCurrentIndex] = useState(initialCurrentIndex);

  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");


  interface EncodageItem {
    enId: number;
    start_time: string;
    end_time: string;
    total_count: string;
    total_break: string;
    sequence: string;
    encoded_by: number;
  }

  interface ScheduleItem {
    planned: Array<{
      shift_id: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: number;
    }>;
    timeRegistration: Array<{
      time_registraion_id: number;
      start_time: string;
      end_time: string;
      total_count: string;
      total_break: string;
      sequence: string;
    }>;
    encodage: EncodageItem[];
  }

  interface Employee {
    employee_id: number;
    employee_name: string;
    employee_type_id: number;
    employee_type: string;
    pc: string;
    pc_id: number;
    location_id: number;
    location_name: string;
    function_id: number;
    function: string;
    salary: string;
    planning_id: number;
    planning_employee_detail_id: number;
    schedule: Record<string, ScheduleItem>;
    start_date: string;
    end_date: string;
  }

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex < locations.length - 1 ? prevIndex + 1 : prevIndex;
    });
  };
  const handlePreviousClick = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex > 0 ? prevIndex - 1 : prevIndex;
    });
  };


  const handleDateChange = (newDate: Date) => {
    setDate(newDate);
    const { startOfWeek, endOfWeek } = TimeRegistrationOverviewHelper.getStartAndEndDate(newDate);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD


  };

  const handleEncodageChange = (
    employeeId: number,
    date: string,
    updatedEncodage: EncodageItem[],
    parentIndex: number
  ) => {
    setOpenEmployees((prevEmployees) => {
      const updatedEmployees = [...prevEmployees]; // Create a new array
      if (updatedEmployees[parentIndex]) {  // Check if index exists
        updatedEmployees[parentIndex] = {
          ...updatedEmployees[parentIndex],
          schedule: {
            ...updatedEmployees[parentIndex].schedule,
            [date]: {
              ...updatedEmployees[parentIndex].schedule[date],
              encodage: updatedEncodage,
            },
          },
        };
      }
      return updatedEmployees;
    });
  };

  const toggleShiftSelection = (employeeId: number, shiftDate: string, parentIndex: number): void => {
    const compositeKey = `${employeeId}_${parentIndex}`;

    setSelectedShift((prev) => {
      const employeeShifts = prev[compositeKey] || [];
      const isSelected = employeeShifts.includes(shiftDate);

      // Clear error when a checkbox is selected
      if (!isSelected) {
        setEmployeeErrors((prevErrors) => ({
          ...prevErrors,
          [compositeKey]: null // Clear error when selecting a checkbox
        }));
      }

      return {
        ...prev,
        [compositeKey]: isSelected
          ? employeeShifts.filter((date) => date !== shiftDate)
          : [...employeeShifts, shiftDate],
      };
    });
  };

  const fetchEmployeeData = async (validated: number) => {
    // console.log(validated, "here");
    try {
      let dataObj = {
        company_id: companyId,
        location_id: locations[currentIndex]?.id,
        start_date: startDate,
        end_date: endDate,
        validated: validated,
      };

      console.log(dataObj, "dataobj");

      if (
        dataObj["start_date"] !== "" &&
        dataObj["end_date"] !== "" &&
        dataObj["company_id"] != null &&
        dataObj["location_id"] != null
      ) {
        let result = await ApiCall.service(
          ENCODAGE_OVERVIEW,
          "POST",
          dataObj,
          true
        );
        if (result.status === "success") {
          const employeesData = Array.isArray(result?.data) ? result.data : []; // Explicitly typing employeesData
          const formattedEmployees = employeesData?.map((employee: any) => ({
            employee_id: employee.employee_id,
            employee_name: employee.employee_name,
            employee_type_id: employee.employee_type_id,
            employee_type: employee.employee_type,
            pc: employee.pc,
            pc_id: employee.pc_id,
            location_id: employee.location_id,
            location_name: employee.location_name,
            function_id: employee.function_id,
            function: employee.function,
            salary: employee.salary,
            image: userImage,
            planning_id: employee.planning_id,
            planning_employee_detail_id: employee.planning_employee_detail_id,
            schedule: employee.schedule,
            start_date: employee.start_date,
            end_date: employee.end_date,
          }));
          validated == 0
            ? setOpenEmployees(formattedEmployees)
            : setClosedEmployees(formattedEmployees);

        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Fetch open data
  const fetchOpenData = async () => {
    await fetchEmployeeData(0);
  };

  // Fetch closed data
  const fetchClosedData = async () => {
    await fetchEmployeeData(1);
  };

  const postData = async (data: Employee) => {
    // Implement your API call here
    console.log("Posting data:", data);

    try {
      let result = await ApiCall.service(
        ENCODAGE_CREATE,
        "POST",
        { data: [data] },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (result.status === 'success') {
        fetchOpenData();
        fetchClosedData();
        // window.location.reload()
      }

      if (result.status === 'validation-error') {
        const errorMessages = Object.values(result.data).join(', ') || t("Something went wrong");
        CustomNotify({ type: "error", message: t(errorMessages) });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const printData = async () => {
    try {
      const sendingData = activeTab === 'home' ? [openEmployees] : [closedEmployees];

      const result = await ApiCall.service(
        ENCODAGE_PRINT,
        "POST",
        { data: sendingData[0] },
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (result.status === 'success' && result.data?.download_link) {
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = result.data.download_link;
        link.setAttribute('download', result.data.file_name || 'export.csv');

        document.body.appendChild(link);
        link.click();

        // Cleanup
        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      } else {
        console.error('Invalid response format:', result);
      }
    } catch (error) {
      console.error("Error downloading CSV:", error);
    }
  };


  const handleSend = (employee: Employee, parentIndex: number) => {
    const compositeKey = `${employee.employee_id}_${parentIndex}`;
    const employeeSelectedShifts = selectedShift[compositeKey] || [];

    if (employeeSelectedShifts.length === 0) {
      setEmployeeErrors((prevErrors) => ({
        ...prevErrors,
        [compositeKey]: t("Please select at least one shift."),
      }));
      return;
    }

    // Clear error for the employee if they have selected shifts
    setEmployeeErrors((prevErrors) => ({
      ...prevErrors,
      [compositeKey]: null,
    }));

    const filteredSchedule: Record<string, ScheduleItem> = Object.keys(
      employee.schedule
    )
      .filter((date) => employeeSelectedShifts.includes(date))
      .reduce((acc, date) => {
        acc[date] = employee.schedule[date];
        return acc;
      }, {} as Record<string, ScheduleItem>);

    if (Object.keys(filteredSchedule).length > 0) {
      const filteredData: Employee = {
        ...employee,
        schedule: filteredSchedule,
      };

      postData(filteredData);

      // Reset selected shifts for the specific employee
      setSelectedShift((prevSelectedShift) => ({
        ...prevSelectedShift,
        [compositeKey]: [], // Clear selected shifts for this employee
      }));
    }
  };

  useEffect(() => {
    setActiveTab(params?.tab);
  }, [params?.tab]);

  useEffect(() => {
    const { startOfWeek, endOfWeek } = TimeRegistrationOverviewHelper.getStartAndEndDate(date);
    setStartDate(startOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD
    setEndDate(endOfWeek.toLocaleDateString("en-CA")); // YYYY-MM-DD

  }, [date]);

  useEffect(() => {
    fetchOpenData();
    fetchClosedData();
  }, [companyId, currentIndex, startDate, endDate, activeTab]);


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchQuery(event.target.value); // Update search query
  };

  // Toggle the visibility of the search input
  const toggleSearchVisibility = (): void => {
    setSearchVisible((prev) => {
      if (prev == true) {
        setSearchQuery("");
      }
      return !prev;
    });
  };

  const filteredOpenEmployees: Employee[] = TimeRegistrationOverviewHelper.filterEmployees(openEmployees, searchQuery);
  const filteredClosedEmployees: Employee[] = TimeRegistrationOverviewHelper.filterEmployees(closedEmployees, searchQuery);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    navigate(`/time-registration/${tab}`)
  }

  return (
    <>
      <Header
        headerName={t("Time registration per employee")}
        headerClassName="myAccountCardTitle"
      />
      <TableHeaderWithIcon
        onNextClick={handleNextClick}
        onPreviousClick={handlePreviousClick}
        title={locations[currentIndex]?.name || t("No Location Name")}
        TableHeaderWithIconClassName="plannedLocationHeader px-3"
        TableHeaderTitle="poppins-semibold text-white"
      />
      <div className="contentBg rounded-top-0 p-3">
        <div className="row mb-3">
          <div className="col-lg-4 col-md-4 align-self-center">
            <ul className="nav nav-pills timeRegistrationTabs" role="tablist">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "home" ? "active" : ""}`}
                  data-bs-toggle="pill"
                  href="#home"
                  onClick={() => handleTabChange("home")}
                >
                  {t("Open")}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeTab === "menu1" ? "active" : ""
                    }`}
                  data-bs-toggle="pill"
                  href="#menu1"
                  onClick={() => handleTabChange("menu1")}
                >
                  {t("Closed")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-8 align-self-center text-center d-flex justify-content-end justify-content-lg-center">
            <DateAndTime
              isWeek={true}
              isIcon={true}
              monthFormat="long"
              dataTimeWrapperClassName="d-inline-flex align-items-center justify-content-center timeRegistrationDateWrapper"
              dataTimeClassName="mx-3 planningPlannedDate"
              dayClassName="me-1"
              isWeekDate={true}
              iconFillColor="#8571b9"
              onDateChange={handleDateChange}
            />
          </div>
          <div className="col-lg-4 col-12 align-self-center text-end">
            <span
              onClick={() => { printData() }}
              title={t("Print")}
              className="secondaryColorHoverEffect float-end"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="printIcon" />
            </span>
            <div className="d-flex align-items-center justify-content-end">
              {activeTab === "menu1" && (
                <div className="me-lg-3 totalValidatedHours">
                  {/* {t("Total validated hours") + ": " + getTotalValidatedHours(closedEmployees)} */}
                  {t("Total validated hours") + ": " + TimeRegistrationOverviewHelper.getTotalValidatedHours(closedEmployees)}


                </div>
              )}
              {searchVisible && (
                <InputTextfield
                  placeholder={t("Search by employee name")}
                  value={searchQuery}
                  handleChange={handleSearchChange}
                />
              )}
              <span
                onClick={toggleSearchVisibility}
                title={t("Search")}
                className="secondaryColorHoverEffect ms-3 float-end"
              >
                <Icon isIcon={true} width="1vw" height="1vw" name="searchIcon" />
              </span>
            </div>
          </div>
        </div>
        {/* Tab panes */}
        <div className="tab-content">
          <div
            id="home"
            className={`tab-pane ${activeTab === "home" ? "active" : ""}`}
          >

            {openEmployees.length > 0 ? TimeRegistrationOverviewHelper.headings() : ""}
            <OverFlowWrapper className="timeRegistrationPerEmployee container-fluid px-0">
              {filteredOpenEmployees && filteredOpenEmployees.length > 0 ? (
                filteredOpenEmployees.map((employee, employeeIndex) => (
                  <div className="d-lg-flex mb-3" key={employee.employee_id} style={{ paddingLeft: "0.2vw" }}>
                    <div className="align-self-center mx-auto mb-3 mb-lg-0" style={{ width: widthStylesColOne }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={TimeRegistrationOverviewHelper.formatEmployeeName(employee.employee_name)}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: widthStylesColTwo }}
                    >

                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => {

                          return (
                            <ShiftTiming
                              parentIndex={employeeIndex}
                              key={index}
                              showLastAction={true}
                              isTimeRegistration={true}
                              category="open"
                              schedule={scheduleData}
                              date={date}
                              shiftIndex={index}
                              selectedDates={selectedShift[`${employee.employee_id}_${employeeIndex}`] || []}
                              onEncodageChange={(
                                updatedEncodage: EncodageItem[]
                              ) =>
                                handleEncodageChange(
                                  employee.employee_id,
                                  date,
                                  updatedEncodage,
                                  employeeIndex
                                )
                              }
                              onCheckboxChange={() => toggleShiftSelection(employee.employee_id, date, employeeIndex)}
                              activeEmployee={employee} // Pass the current employee as activeEmployee
                            />
                          );
                        }
                      )}

                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 text-end timeRegistrationValidation">
                            <LabelField title={`${selectedShift[`${employee.employee_id}_${employeeIndex}`]?.length || 0} / ${Object.keys(employee.schedule).length} ` + t("validated")} className="me-2" />
                            <CustomActionIcon handleClick={() => handleSend(employee, employeeIndex)} title={t("Send")} iconName="sendIcon" className="timeRegistrationValidationSend secondaryColorHoverEffect" />
                          </div>
                        </div>
                      </div>
                      {employeeErrors[`${employee.employee_id}_${employeeIndex}`] && (
                        <div className="error-message">
                          {employeeErrors[`${employee.employee_id}_${employeeIndex}`]}
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                < NoRecords message={t("No open encodage for this week")} isDiv={true} className="pt-5" />
              )}
            </OverFlowWrapper>
          </div>
          <div
            id="menu1"
            className={`tab-pane ${activeTab === "menu1" ? "active" : ""}`}
          >

            {closedEmployees.length > 0 ? TimeRegistrationOverviewHelper.headings() : ""}

            <OverFlowWrapper className="timeRegistrationPerEmployee">
              {filteredClosedEmployees && filteredClosedEmployees.length > 0 ? (
                filteredClosedEmployees.map((employee) => (
                  <div className="d-lg-flex mb-3" key={employee.employee_id}>
                    <div className="align-self-center mx-auto mb-3 mb-lg-0" style={{ width: widthStylesColOne }}>
                      <PlanningEmployeeDetailsCard
                        cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center flex-column text-center"
                        cardMainClassName="timeRegistrationWrapper position-relative"
                        employeeInformationClass="planningEmployeeDetails timeRegistrationEmployeeCard w-100"
                        employeeName={TimeRegistrationOverviewHelper.formatEmployeeName(employee.employee_name)}
                        employeeEmpType={employee.employee_type}
                        employeeLocation={employee.location_name}
                        employeeFunction={employee.function}
                        employeePC={employee.pc}
                        employeeSalary={employee.salary}
                      // employeeImage={employee.image}
                      />
                    </div>
                    <div
                      className="align-self-center ms-auto"
                      style={{ width: widthStylesColTwo }}
                    >
                      {Object.entries(employee.schedule).map(
                        ([date, scheduleData], index) => (
                          <>
                            <ShiftTiming
                              key={index}
                              showLastAction={true}
                              isTimeRegistration={true}
                              category="closed"
                              schedule={scheduleData}
                              date={date}
                              shiftIndex={index}
                            />

                          </>
                        )
                      )}

                      {activeTab === "menu1" && (
                        <div className="container-fluid">
                          <div className="row">
                            <div className="col-12 text-end">
                              <span className="color-primary totalText">{t("Total") + " : "}</span>
                              <span className="timeRegistrationClosed ms-2 text-white poppins-medium">{TimeRegistrationOverviewHelper.getTotalValidatedHoursPerEmployee(employee)}</span>

                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <NoRecords isDiv={true} message={t("No closed encodage for this week")} className="pt-5" />
              )}
            </OverFlowWrapper>
          </div>
        </div>
      </div>
    </>
  );
};

export default translate(TimeRegistrationOverview);

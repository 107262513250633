import React, { useState } from "react";
import LabelField from "../../../../common/atoms/LabelField";
import { t } from "../../../../CentralDataMangement/translation/Translation";
import Image from "../../../../common/atoms/Image";
import ModalPopup from "../../../../common/atoms/ModalPopup";
import SignatureField from "../../../../common/atoms/SignatureField";

interface SignatureOrganismProps {
    formState: any;
    handleSaveSignature: (dataUrl: string) => void;
}

const SignatureOrganism: React.FC<SignatureOrganismProps> = ({
    formState,
    handleSaveSignature
}) => {
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => {
        handleSaveSignature(formState?.formValues?.signature);
        setShowModal(true)
    };
    const handleCloseModal = () => setShowModal(false);

    const cardTitleProps = {
        className: "myAccountCardTitle colorPrimary mb-3",
    };

    return (
        <div className="contentBg">
            <LabelField title={t("Manage signature")} {...cardTitleProps} />
            <div className="row">
                <div className="col-12 text-center my-3">
                    {formState?.formValues?.signature && (
                        <Image
                            src={formState?.formValues?.signature}
                            imageWidth="18vw"
                            imageHeight="4vw"
                        />
                    )}
                </div>
                <div className="text-end" onClick={handleOpenModal}>
                    <LabelField
                        title={t("Edit")}
                        className="cursor-pointer colorPrimary poppins-semibold"
                    />
                </div>
            </div>
            <ModalPopup show={showModal} handleClose={handleCloseModal}>
                <div className="text-center">
                    <SignatureField
                        onSave={(dataUrl) => {
                            handleSaveSignature(dataUrl);
                            handleCloseModal();
                        }}
                        dataUrl={formState?.formValues?.signature}
                        width="100%"
                        height="300px"
                        saveBtnClass="btn rounded-pill p-0"
                        clearBtnClass="btn rounded-pill p-0"
                    />
                </div>
            </ModalPopup>
        </div>
    );
};

export default SignatureOrganism;